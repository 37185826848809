import React from "react";
import { Grid, Typography, useMediaQuery } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { MD_DEVICE_WIDTH_BREAKPOINT } from "@icarius-utils/constants";


const ExtraData = (props) => {

  const {
    shouldHide,
    locale,
    haberesConAportes,
    haberesSinAportes,
    descuentosLegales,
    descuentosVarios,
    totalResult
  } = props;
  
  const Container = ({ value1, value2, locale }) => {
    let valor2Formatted = new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value2);
  
    return (
      <Grid container item xs={12} sm={6} md={4}>
        <Grid container item xs={7}>
          <Typography className="whiteText" variant="body1" style={{ visibility: shouldHide ? 'hidden' : 'visible' }}>
            {value1}:
          </Typography>
        </Grid>
        <Grid container item justify="flex-end" xs={5} style={{ paddingRight: 30, visibility: shouldHide ? 'hidden' : 'visible' }}>
          <Typography className="whiteText" variant="body1">
            {valor2Formatted}
          </Typography>
        </Grid>
      </Grid>
    )
  }

  const extraSpaceIsNotHidden = useMediaQuery(`(min-width:${MD_DEVICE_WIDTH_BREAKPOINT}px)`);

  return (
    <>
      <Container value1={getLocalizedString("employeesProcessHaberesAportes")} value2={haberesConAportes} locale={locale} />
      <Container value1={getLocalizedString("employeesProcessHaberesSinAporte")} value2={haberesSinAportes} locale={locale} />
      {
        extraSpaceIsNotHidden &&
        <Grid container item xs={12} sm={6} md={4} />
      }
      <Container value1={getLocalizedString("employeesProcessDescuentosLegales")} value2={descuentosLegales} locale={locale} />
      <Container value1={getLocalizedString("employeesProcessDescuentosVarios")} value2={descuentosVarios} locale={locale} />
      <Container value1={getLocalizedString("employeesProcessTotal")} value2={totalResult} locale={locale} />
    </>
  )
}

export default ExtraData;
