import * as actionTypes from "./actionTypes";

const initialState = {
  publications: null,
  types: null,
  workflows: null,
  codes: null,
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        publications: action.payload.publications,
        types: action.payload.types,
        workflows: action.payload.workflows,
        codes: action.payload.codes,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };
      
    case actionTypes.DELETE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FULFILLED:
      return { ...state, isLoading: false, publications: action.payload.publications };
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return { ...state, isLoading: false, publications: action.payload.publications };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.MODIFY:
      return { ...state, isLoading: true };
    case actionTypes.MODIFY_FULFILLED:
      return { ...state, isLoading: false, publications: action.payload.publications };
    case actionTypes.MODIFY_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CHECK_URL:
      return { ...state, isLoading: true };
    case actionTypes.CHECK_URL_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.CHECK_URL_REJECTED:
      return { ...state, isLoading: false };
    
    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
