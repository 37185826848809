import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  data: [],
  collaboratorsList: [],
  evaluatorsList: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        collaboratorsList: action.payload.collaboratorsList,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DOWNLOAD:
      return { ...state, isLoading: true };
    case actionTypes.DOWNLOAD_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.DOWNLOAD_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.UPLOAD:
      return { ...state, isLoading: true };
    case actionTypes.UPLOAD_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.UPLOAD_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_EVALUATORS:
      return { ...state, isLoading: true };
    case actionTypes.GET_EVALUATORS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        evaluatorsList: action.payload.evaluatorsList,
      };
    case actionTypes.GET_EVALUATORS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.SET_EVALUATOR:
      return { ...state, isLoading: true };
    case actionTypes.SET_EVALUATOR_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.SET_EVALUATOR_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
