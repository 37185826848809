import React, { useEffect, useState } from "react";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteEvaluationProcessDefinitionAssignmentAction,
  downloadEvaluationProcessDefinitionAssignmentAction,
  getEvaluationProcessDefinitionAssignmentAction,
  setEvaluatorForEvaluationProcessDefinitionAssignmentAction,
} from "../actions";
import {
  getData,
  getLoading,
  getCollaboratorsList,
  getEvaluatorsList,
} from "../selectors";
import { RESET_STATE } from "../actionTypes";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";

const useEvaluationProcessDefinitionAssignment = (processCode) => {

  const [createDialogIsOpen, setCreateDialogIsOpen] = useState(false);
  const [uploadFileDialogIsOpen, setUploadFileDialogIsOpen] = useState(false);
  const [evaluatorDialogIsOpen, setEvaluatorDialogIsOpen] = useState(false);
  const [replaceEvaluatorDialogIsOpen, setReplaceEvaluatorDialogIsOpen] = useState(false);
  const [evaluatorData, setEvaluatorData] = useState(null);
  const [massiveMenuAnchor, setMassiveMenuAnchor] = useState(null);

  const dispatch = useDispatch();
  const isLoading = useSelector(getLoading);
  const data = useSelector(getData);
  const colaboratorsList = useSelector(getCollaboratorsList);
  const evaluatorsList = useSelector(getEvaluatorsList);

  useEffect(() => {
    if (Boolean(processCode)) {
      dispatch(getEvaluationProcessDefinitionAssignmentAction(processCode));
    }
    return () => dispatch({ type: RESET_STATE });
  }, [dispatch, processCode])

  const handleOpenCreateDialog = () => {
    setCreateDialogIsOpen(true);
  }

  const handleDelete = (collaborators, type) => {
    dispatch(deleteEvaluationProcessDefinitionAssignmentAction({
      processCode,
      collaborators,
      type,
    }));
  }

  const handleOpenDeleteDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    const type = selectedRows[0].type;
    const collaborators = selectedRows.map((item) => item['Código de empleado']);

    if (selectedRows.some((item) => item.type !== type)) {
      dispatch(openSnackbarAction({ msg: 'Para eliminar registros de evaluados y evaluadores, los registros seleccionados deben ser del mismo tipo de evaluación', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: type === 'DES' ?
        "Si elimina registros con el tipo de evaluación descendente se eliminarán también todos los tipos de evaluaciones para el colaborador o colaboradores seleccionados y quedarán fuera del proceso, ¿desea continuar con la eliminación?"
        : "¿Está seguro que quiere eliminar los registros de evaluados y evaluadores seleccionados?",
      onConfirm: () => handleDelete(collaborators, type),
    }));
  }

  const handleSetMassiveMenuAnchor = (value) => setMassiveMenuAnchor(value);

  const handleCloseCreateDialog = () => {
    setCreateDialogIsOpen(false);
  }

  const handleDownloadStructure = () => {
    dispatch(downloadEvaluationProcessDefinitionAssignmentAction(processCode));
  }

  const handleOpenUploadFileDialogIsOpen = () => {
    setUploadFileDialogIsOpen(true);
  }

  const handleCloseUploadFileDialogIsOpen = () => {
    setUploadFileDialogIsOpen(false);
  }

  const openEvaluatorDialog = (selectedRows, isReplace) => {
    setMassiveMenuAnchor(null);

    const type = selectedRows[0].type;
    const typeString = selectedRows[0]['Tipo de formulario'];
    const collaborators = selectedRows.map((item) => item['Código de empleado']);

    setEvaluatorData({ type, typeString, collaborators });

    if (isReplace) {
      setReplaceEvaluatorDialogIsOpen(true);
    }

    setEvaluatorDialogIsOpen(true);
  };

  const handleOpenEvaluatorDialog = (gridRef, isReplace) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un registro', severity: "warning" }));
      return;
    }

    if (selectedRows.some((item) => item.type === 'AUT')) {
      dispatch(openSnackbarAction({ msg: 'No es posible seleccionar una autoevaluación para asignar evaluadores porque el evaluador será el propio colaborador', severity: "warning" }));
      return;
    }

    if (selectedRows.some((item) => item.type !== selectedRows[0].type)) {
      dispatch(openSnackbarAction({ msg: 'No es posible seleccionar distintos tipos de evaluación para asignar evaluadores', severity: "warning" }));
      return;
    }

    if (selectedRows.every((item) => item.type === 'ASC') && selectedRows.some((item) => item['Cargo del evaluado'] !== selectedRows[0]['Cargo del evaluado'])) {
      dispatch(openSnackbarAction({ msg: 'Para la elección del evaluador ascendente, todos los colaboradores seleccionados deben tener el mismo cargo', severity: "warning" }));
      return;
    }

    const rowWithCalification = selectedRows.find((item) => item.hasCalification);


    if (!isReplace && Boolean(rowWithCalification)) {
      dispatch(openDialogAction({
        title: 'Atención',
        msg: (
          <>
            {`El evaluado ${rowWithCalification['Apellido y nombres']} ya ha sido calificado por el evaluador ${rowWithCalification['Evaluador del tipo de formulario']} en el tipo de evaluación ${rowWithCalification['Tipo de formulario']}.`}
            <br />
            Si usted asigna a un nuevo evaluador, se eliminará por completo la evaluación que ya le han realizado, ¿desea continuar?
          </>
        ),
        onConfirm: () => openEvaluatorDialog(selectedRows, false),
      }));
      return;
    }

    if (isReplace && Boolean(rowWithCalification)) {
      dispatch(openDialogAction({
        title: 'Atención',
        msg: (
          <>
            {`El evaluado ${rowWithCalification['Apellido y nombres']} ya ha sido calificado por el evaluador ${rowWithCalification['Evaluador del tipo de formulario']} en el tipo de evaluación ${rowWithCalification['Tipo de formulario']}.`}
            <br />
            Si usted reemplaza al evaluador, debe considerar que se mantendrán la calificaciones ya realizadas por el evaluador actual, ¿desea continuar?
          </>
        ),
        onConfirm: () => openEvaluatorDialog(selectedRows, true),
      }));
      return;
    } else if (isReplace && !Boolean(rowWithCalification)) {
      dispatch(openSnackbarAction({ msg: 'No es posible reemplazar el evaluador ya que no hay calificaciones', severity: "warning" }));
      return;
    }

    setMassiveMenuAnchor(null);
    openEvaluatorDialog(selectedRows, false);
  }

  const handleCloseEvaluatorDialog = () => {
    setEvaluatorDialogIsOpen(false);
    setReplaceEvaluatorDialogIsOpen(false);
    setEvaluatorData(null);
  }

  const handleChangeEvaluator = (evaluatorArray, typeSelected) => {
    const dataToSend = {
      processCode,
      type: evaluatorData.type,
      collaborators: evaluatorData.collaborators,
      evaluator: evaluatorArray[0] || '',
      replace: replaceEvaluatorDialogIsOpen
    };

    if (typeSelected === 'DES') {
      dispatch(openDialogAction({
        title: getLocalizedString("atention"),
        msg: "A todos los colaboradores seleccionados se les asignará como evaluador a su manager directo, ¿desea continuar?",
        onConfirm: () => changeEvaluator(dataToSend),
      }));
      return;
    }

    changeEvaluator(dataToSend);
  }

  const changeEvaluator = (dataToSend) => {
    dispatch(setEvaluatorForEvaluationProcessDefinitionAssignmentAction(dataToSend));
  }

  const state = {
    createDialogIsOpen,
    uploadFileDialogIsOpen,
    evaluatorDialogIsOpen,
    evaluatorData,
    massiveMenuAnchor,
    replaceEvaluatorDialogIsOpen,
  }

  const handlers = {
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleCloseCreateDialog,
    handleDownloadStructure,
    handleOpenUploadFileDialogIsOpen,
    handleCloseUploadFileDialogIsOpen,
    handleOpenEvaluatorDialog,
    handleCloseEvaluatorDialog,
    handleChangeEvaluator,
    handleSetMassiveMenuAnchor,
  }

  return {
    isLoading,
    data,
    colaboratorsList,
    evaluatorsList,
    state,
    handlers,
  }
}

export default useEvaluationProcessDefinitionAssignment;