import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCodes, getTypes, getWorkflows } from "../../selectors";
import { Typography, IconButton, Grid, Card, Tooltip } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon, CollabIcon, ManagerIcon, EmployerIcon, FolderIcon } from "@icarius-icons";
import PublicationForm from "./publicationForm";
import { deleteDocumentPublicationAction } from "../../actions";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import { openDialogAction } from "@icarius-common/dialog/actions";

const Publication = (props) => {

  const { data, color, closeNewPublication, presetSignableValue, setEditIsOpen } = props;
  const dispatch = useDispatch();

  const isCreate = !Boolean(data);

  const types = useSelector(getTypes);
  const workflows = useSelector(getWorkflows);
  const codes = useSelector(getCodes);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto

  const handleDeletePublication = () => {
    if (isCreate) {
      closeNewPublication();
    } else {
      dispatch(deleteDocumentPublicationAction(data.internalCode, data.name, data.type))
        .then(() => setEditIsOpen(false));
    }
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openDialogAction({
      title: getLocalizedString("documentPublicationDeleteDialogTitle"),
      msg: getLocalizedString("confirmDeleteDocumentPublication"),
      onConfirm: handleDeletePublication,
    }));
  }

  const getRoleIcons = () => {
    const icons = [];
    if (!data.colaborator && !data.manager && !data.employer) return icons;


    if (data.colaborator) {
      icons.push(
        <Tooltip key={"C"} title={getLocalizedString("collaborator")}>
          <IconButton size={"small"} disableRipple>
            <CollabIcon />
          </IconButton>
        </Tooltip>
      )
    }

    if (data.manager) {
      icons.push(
        <Tooltip key={"M"} title={getLocalizedString("manager")}>
          <IconButton size={"small"} disableRipple>
            <ManagerIcon />
          </IconButton>
        </Tooltip>
      )
    }

    if (data.employer) {
      icons.push(
        <Tooltip key={"E"} title={getLocalizedString("employer")}>
          <IconButton size={"small"} disableRipple>
            <EmployerIcon />
          </IconButton>
        </Tooltip>
      )
    }

    return icons;
  }

  const handleClosePublication = () => {
    if (!isCreate) {
      setIsOpen(prev => !prev);
      setEditIsOpen(prev => !prev);
    }
  }

  const getChecksText = () => {
    let stringArr = [];

    if (data?.docToReq) stringArr.push("A pedido");
    if (data?.signable && data?.multipleApprovation) stringArr.push("Con firma múltiple");
    if (data?.signable && !data?.multipleApprovation) stringArr.push("Con firma");
    if (data?.requiresReception) stringArr.push("Con recepción");

    return (
      <Tooltip title={stringArr.join("/ ")}>
        <Typography noWrap variant="subtitle1" className={isOpen ? '' : "whiteText"} style={{ fontWeight: 400, color: isOpen ? 'white' : '' }}>
          {stringArr.join("/ ")}
        </Typography>
      </Tooltip>
    );
  }

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={11} style={{ cursor: Boolean(data) ? "pointer" : "" }} onClick={handleClosePublication}>
            {
              isCreate
                ? <DataContainer lg={12} sm={12} md={12} text={getLocalizedString("documentPublicationCreation")} />
                : <>
                  <DataContainer sm={12} lg={4} md={4} text={data.name} />
                  <DataContainer lg={1} md={2} text={getLocalizedString(data.active ? "active" : "inactive")} />
                  <DataContainer sm={8} md={1} lg={1} text={data.code} />
                  <DataContainer md={2}>{getRoleIcons()}</DataContainer>
                  <DataContainer sm={6} lg={4}>{getChecksText()}</DataContainer>
                </>
            }
          </Grid>
          <Grid container direction="row" item xs={1} justify={(!isCreate && data.inFolder) ? "space-between" : "flex-end"}>
            {
              !isCreate && data.inFolder &&
              <Tooltip title={getLocalizedString("documentIncludedFolder")}>
                <IconButton size={"small"} disableRipple>
                  <FolderIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                </IconButton>
              </Tooltip>
            }
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton size={"small"} disableRipple onClick={handleOpenDeleteDialog}>
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      {
        isOpen &&
        <PublicationForm
          data={data}
          types={types}
          workflows={workflows}
          codes={codes}
          presetSignableValue={presetSignableValue}
          handleClose={isCreate ? closeNewPublication : handleClosePublication}
        />
      }
    </>
  );
}

export default Publication;
