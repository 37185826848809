import React, { memo } from "react";
import DashboardChart from "../widgets";
import { Grid } from "@material-ui/core";

const ThirdPartyPayment = ({ data, companyColor, setGridDataAndOpenDialog, gridTheme }) => {
    return (
        <Grid container justify="space-between" item xs={12} >
            <Grid item xs={12}>
                <DashboardChart
                    path="currentProcessesDashboardThirdPaymentParty"
                    setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                    gridTheme={gridTheme}
                    type={"GC"}
                    data={data}
                />
            </Grid>
        </Grid>
    )
}

export default memo(ThirdPartyPayment);
