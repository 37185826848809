import React from "react";
import { useDispatch } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Typography,
  InputLabel,
  Checkbox,
  Stepper,
  Step,
  StepButton,
  FormHelperText,
  Radio,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createDocumentPublicationAction, modifyDocumentPublicationAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandlePublicationForm from "./useHandlePublicationForm";
import useHandleStepper from "@icarius-utils/hooks/useHandleStepper";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";

const gridStyle = { height: 75, padding: "0px 10px" };
const flexStyle = { height: 50, padding: "0px 10px", display: "flex", alignItems: "center", cursor: "pointer" };

const PublicationForm = (props) => {

  const { data, codes, types, workflows, presetSignableValue, handleClose } = props;
  const dispatch = useDispatch();

  const createPublication = (dataToSend) => {
    dispatch(createDocumentPublicationAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modifyPublication = (dataToSend) => {
    dispatch(modifyDocumentPublicationAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const openRoleError = () => {
    dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos un rol", severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
    stepOneIsValid,
    stepTwoIsValid,
  } = useHandlePublicationForm(data, createPublication, modifyPublication, openRoleError, presetSignableValue);

  const getRequiresReceptionArray = () => {
    if (formData.signable) return [
      { key: false, value: 'Ninguna' },
      { key: true, value: 'Con firma digital' },
    ];

    return [
      { key: false, value: 'Ninguna' },
      { key: true, value: 'Simple' },
    ]
  }

  const getRolesAreDisabled = () => {
    if (!formData.signable && formData.type === 'REC') return false;
    return ['REC', 'ASI'].includes(formData.type) || ['ANT', 'VAC', 'PER', 'SUE', 'DEV'].includes(formData.workflow);
  }

  const requiresReceptionIsDisabled = (formData.signable && formData.type === 'EXT') || (!formData.signable && ['WFD', 'AUT'].includes(formData.type));
  const codeIsDisabled = formData.signable && formData.type === 'EXT';

  const steps = [
    {
      name: 'Tipo de documentación',
      render: () => {
        return (
          <>
            <Typography className="whiteText" style={{ fontSize: 20, marginTop: 20, width: '100%', padding: '0px 10px' }}>
              {'¿Qué tipo de documento desea crear?'}
            </Typography>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* tipo */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel id={`label-type-${data ? data.internalCode : "new"}`}>{getLocalizedString("documentPublicationType")}</InputLabel>
                <Select
                  disabled={!isCreate}
                  value={formData.type}
                  labelId={`label-type-${data ? data.internalCode : "new"}`}
                  id={`select-type-${data ? data.internalCode : "new"}`}
                  onChange={(e) => setFormValue(e.target.value, "type")}
                  margin={"none"}
                >
                  {
                    types && types[formData.signable ? 'signed' : 'unsigned'].map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* nombre */}
              <TextField
                style={{ marginTop: 14 }}
                fullWidth
                margin={"none"}
                label={'Nombre del documento'}
                value={formData.name}
                onChange={(e) => setFormValue(e.target.value, "name")}
                inputProps={{ maxLength: 60 }}
                helperText={'Nombre con el cual será publicado'}
              />
            </Grid>
            <Typography className="whiteText" style={{ fontSize: 20, marginTop: 30, width: '100%', padding: '0px 10px' }}>
              {'Opciones de digitalización'}
            </Typography>
            <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
              <Grid container item xs={12} sm={4} md={6}>
                <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block", marginRight: 20 }}>
                  {'¿Incluye firma digital del empleador?'}
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={8} md={6}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }} onClick={() => isCreate && setFormValue(true, "signable")}>
                  <Radio disabled={!isCreate} color='primary' checked={formData.signable} />
                  <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {'Si'}
                  </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => isCreate && setFormValue(false, "signable")}>
                  <Radio disabled={!isCreate} color='primary' checked={!formData.signable} />
                  <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {'No'}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container item alignItems="center" xs={12} md={6} style={{ ...gridStyle, height: '' }}>
              <FormControl style={{ width: "100%", marginTop: 14 }}>
                <InputLabel id={`label-code-Tipo de aceptación del colaborador:`}>{'Tipo de aceptación del colaborador:'}</InputLabel>
                <Select
                  disabled={!isCreate || requiresReceptionIsDisabled}
                  value={formData.requiresReception}
                  labelId={`label-code-Tipo de aceptación del colaborador:`}
                  id={`select-code-Tipo de aceptación del colaborador:`}
                  onChange={(e) => setFormValue(e.target.value, "requiresReception")}
                  margin={"none"}
                >
                  {
                    getRequiresReceptionArray().map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
                <FormHelperText>{'Indicar el tipo de aceptación que el documento requiere por parte del colaborador.'}</FormHelperText>
                {
                  formData.type === 'EXT' &&
                  <FormHelperText>{'Para los documentos externos, podrá decidir si el tipo de aceptación es necesario al momento de firmarlo..'}</FormHelperText>
                }
              </FormControl>
            </Grid>
            {
              formData.signable && formData.requiresReception &&
              <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
                <Grid container item xs={12} sm={4} md={6}>
                  <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block", marginRight: 20 }}>
                    {'¿Utiliza multiples aprobadores o firmantes?'}
                  </Typography>
                </Grid>
                <Grid container item xs={12} sm={8} md={6}>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }} onClick={() => setFormValue(true, "multipleApprovation")}>
                    <Radio color='primary' checked={formData.multipleApprovation} />
                    <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                      {'Si'}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => setFormValue(false, "multipleApprovation")}>
                    <Radio color='primary' checked={!formData.multipleApprovation} />
                    <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                      {'No'}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            }
            {
              formData.type === 'AUT' &&
              <Grid container item alignItems="center" xs={12} md={6} style={gridStyle}>
                <Grid container item xs={12} sm={4} md={6}>
                  <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block", marginRight: 20 }}>
                    {'¿Incluir en carpeta digital?'}
                  </Typography>
                </Grid>
                <Grid container item xs={12} sm={8} md={6}>
                  <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }}>
                    <Radio disabled color='primary' checked={formData.inFolder} />
                    <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                      {'Si'}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Radio disabled color='primary' checked={!formData.inFolder} />
                    <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                      {'No'}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            }
          </>
        )
      }
    },
    {
      name: 'Elementos relacionados al documento',
      render: () => {
        return (
          <>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* code */}
              <FormControl style={{ width: "100%" }}>
                <InputLabel id={`label-code-${data ? data.internalCode : "new"}`}>{'Seleccione que plantilla se relaciona con el documento'}</InputLabel>
                <Select
                  disabled={codeIsDisabled}
                  value={formData.code}
                  labelId={`label-code-${data ? data.internalCode : "new"}`}
                  id={`select-code-${data ? data.internalCode : "new"}`}
                  onChange={(e) => setFormValue(e.target.value, "code")}
                  margin={"none"}
                >
                  {
                    ((codes && formData.type && codes[formData.type]) || []).map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))
                  }
                </Select>
                {
                  formData.type === "EXT" && formData.signable &&
                  <FormHelperText>{'Este tipo de documento no requiere este campo'}</FormHelperText>
                }
              </FormControl>
            </Grid>
            {
              formData.type === "WFD" &&
              <Grid container item xs={12}>
                <Grid container item alignItems="center" xs={12} sm={6} md={6} style={gridStyle}> {/* workflow */}
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id={`label-wf-${data ? data.internalCode : "new"}`}>{'¿Con qué tipo de solicitud se relaciona?'}</InputLabel>
                    <Select
                      disabled={formData.type !== "WFD"}
                      value={formData.workflow}
                      labelId={`label-wf-${data ? data.internalCode : "new"}`}
                      id={`select-wf-${data ? data.internalCode : "new"}`}
                      onChange={(e) => setFormValue(e.target.value, "workflow")}
                      margin={"none"}
                    >
                      {
                        workflows && workflows.map(item => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.key}
                            value={item.key}>
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
                <Grid container item alignItems="center" xs={12} sm={6} md={6} style={gridStyle}> {/* copia a */}
                  <TextField
                    disabled={formData.type !== "WFD"}
                    fullWidth
                    margin={"none"}
                    label={"Enviar comprobante de solicitud aprobado con copia a"}
                    value={formData.cc}
                    onChange={(e) => setFormValue(e.target.value, "cc")}
                    inputProps={{ maxLength: 254 }}
                    helperText={"Para ingresar más de un correo sepárelos con ;"}
                    style={{ marginTop: 14 }}
                  />
                </Grid>
              </Grid>
            }
          </>
        )
      }
    },
    {
      name: !formData.type ? 'Rol' : ['WFD', 'PED'].includes(formData.type) ? 'Rol solicitante' : 'Rol receptor',
      render: () => {
        return (
          <>
            <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, height: '' }}>
              <Grid container item xs={12} sm={5} md={3}>
                <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block", marginRight: 20 }}>
                  {'Colaborador'}
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={7} md={9}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }} onClick={() => !getRolesAreDisabled() && setFormValue(true, "colaborator")}>
                  <Radio disabled={getRolesAreDisabled()} color='primary' checked={formData.colaborator} />
                  <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {'Si'}
                  </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => !getRolesAreDisabled() && setFormValue(false, "colaborator")}>
                  <Radio disabled={getRolesAreDisabled()} color='primary' checked={!formData.colaborator} />
                  <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {'No'}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, height: '' }}>
              <Grid container item xs={12} sm={5} md={3}>
                <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block", marginRight: 20 }}>
                  {'Manager'}
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={7} md={9}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }} onClick={() => !getRolesAreDisabled() && setFormValue(true, "manager")}>
                  <Radio disabled={getRolesAreDisabled()} color='primary' checked={formData.manager} />
                  <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {'Si'}
                  </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => !getRolesAreDisabled() && setFormValue(false, "manager")}>
                  <Radio disabled={getRolesAreDisabled()} color='primary' checked={!formData.manager} />
                  <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {'No'}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container item alignItems="center" xs={12} style={{ ...gridStyle, height: '' }}>
              <Grid container item xs={12} sm={5} md={3}>
                <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block", marginRight: 20 }}>
                  {'Empleador'}
                </Typography>
              </Grid>
              <Grid container item xs={12} sm={7} md={9}>
                <div style={{ display: 'flex', alignItems: 'center', marginRight: 20 }} onClick={() => !getRolesAreDisabled() && setFormValue(true, "employer")}>
                  <Radio disabled={getRolesAreDisabled()} color='primary' checked={formData.employer} />
                  <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {'Si'}
                  </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => !getRolesAreDisabled() && setFormValue(false, "employer")}>
                  <Radio disabled={getRolesAreDisabled()} color='primary' checked={!formData.employer} />
                  <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                    {'No'}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <div style={{ margin: 10, width: '100%' }}>
              <DialogTitleDivider />
            </div>
            <Typography className="whiteText" style={{ fontSize: 20, marginTop: 20, width: '100%', padding: '0px 10px' }}>
              {'Activación'}
            </Typography>
            <div style={flexStyle} onClick={() => setFormValue(!Boolean(formData.active), "active")}> {/* activo */}
              <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                {'¿Documento activo?'}
              </Typography>
              <Checkbox checked={formData.active} />
            </div>
          </>
        )
      }
    }
  ];

  const {
    currentStep,
    setCurrentStep,
    setNextStep,
    setPreviousStep,
    renderStep,
  } = useHandleStepper(steps);

  const handleSetNextStep = () => {
    let isValid = [stepOneIsValid, stepTwoIsValid][currentStep]();

    if (!isValid) {
      openValidationError();
      return;
    }

    setNextStep();
  }

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12} justify="center">
        <Stepper activeStep={currentStep} alternativeLabel style={{ width: '100%' }}>
          {
            steps.map((step, index) => {
              return (
                <Step key={index}>
                  <StepButton onClick={() => index < currentStep && setCurrentStep(index)}>{step.name}</StepButton>
                </Step>
              );
            })
          }
        </Stepper>
      </Grid>
      <Grid container item xs={12}>
        {renderStep()}
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20, paddingTop: 20 }}>
        {
          (currentStep !== 0) &&
          <div style={{ marginRight: 10 }}>
            <ButtonDialogAction isAccept onClick={setPreviousStep} text={'Anterior'} />
          </div>
        }
        {
          (currentStep === steps.length - 1)
            ? <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
            : <ButtonDialogAction isAccept onClick={handleSetNextStep} text={'Siguiente'} />
        }
      </Grid>
    </Grid >
  );
}



export default PublicationForm;
