import * as actionTypes from "./actionTypes";
import {
  getDocumentTemplatesAPI,
  deleteDocumentTemplateAPI,
  createDocumentTemplateAPI,
  updateDocumentTemplateAPI,
  uploadDocumentTemplateAPI,
  downloadDocumentTemplateApi
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { downloadBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = 'No puede existir más de una plantilla que comparta el mismo código o nombre';
      break;
    case "DUPLICATED_CODE":
      errorString = 'No puede existir más de una plantilla que comparta el mismo código o nombre';
      break;
    case "IN_USE":
      errorString = 'No es posible eliminar la plantilla porque está en uso';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getDocumentTemplatesAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getDocumentTemplatesAPI();

    let data = response.data && response.data.data;
    let functions = response.data && response.data.functions;
    let replaceCheck = response.data && response.data.replace_check;
    let templateListsVars = response.data && response.data.templateListsVars;
    let typeList = response.data && response.data.typeList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data, functions, replaceCheck, templateListsVars, typeList },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteDocumentTemplateAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteDocumentTemplateAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Plantilla eliminada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createDocumentTemplateAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createDocumentTemplateAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Plantilla creada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateDocumentTemplateAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateDocumentTemplateAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Plantilla actualizada con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const uploadDocumentTemplateAction = (file, code) => async (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD_DOCUMENT });
  try {
    let formData = new FormData();
    formData.append('FILE', file);
    formData.append('code', code);

    let response = await uploadDocumentTemplateAPI(formData);
    let data = response.data && response.data.data;
    let status = response?.data?.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.UPLOAD_DOCUMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPLOAD_DOCUMENT_FULFILLED,
      payload: { data }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPLOAD_DOCUMENT_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const downloadDocumentTemplateAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD_DOCUMENT });
  try {
    let response = await downloadDocumentTemplateApi(code);
    let title = response.headers["x-content-namefile"];

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_DOCUMENT_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_DOCUMENT_FULFILLED,
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_DOCUMENT_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};
