import React from "react";
import DashboardChart from "../widgets";
import { Grid, useMediaQuery } from "@material-ui/core";

const ConceptsValues = ({ data, companyColor, setGridDataAndOpenDialog }) => {
    const matchesLessThan960 = useMediaQuery(`(max-width:960px)`);

    return (
        <Grid container justify="space-between" item xs={12} >
            <Grid item md={6} xs={12} style={matchesLessThan960 ? { paddingTop: 20 } : { padding: "0px 30px 0px 0px" }}>
                <DashboardChart
                    path="currentProcessesDashboardValuesPerConcept"
                    setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                    type={"BXCV"}
                    data={{ ...data.data[0], title: data.title }}
                    companyColor={companyColor}
                />
            </Grid>
            <Grid item md={6} xs={12} style={matchesLessThan960 ? { paddingTop: 20 } : { padding: "0px 0px 0px 30px" }}>
                <DashboardChart
                    path="currentProcessesDashboardValuesPerConcept"
                    setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                    type={"BXCV"}
                    data={{ ...data.data[1], title: data.title }}
                    companyColor={companyColor}
                />
            </Grid>
        </Grid>
    )
}

export default ConceptsValues;
