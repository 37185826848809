import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { IconButton, Grid, Card, Tooltip } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon, PublishIcon, GetAppIcon } from "@icarius-icons";
import Form from "./form";
import { deleteDocumentTemplateAction, downloadDocumentTemplateAction } from "../../actions";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import UploadFilesDialog from "../uploadFilesDialog/uploadFilesDialog";
import { openDialogAction } from "@icarius-common/dialog/actions";

const DataItem = (props) => {

  const { data, color, closeNew } = props;
  const dispatch = useDispatch();

  const isCreate = !Boolean(data);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto
  const [uploadDialogIsOpen, setUploadDialogIsOpen] = useState(false);

  const handleDeletePublication = () => {
    if (isCreate) {
      closeNew();
    } else {
      dispatch(deleteDocumentTemplateAction(data));
    }
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openDialogAction({
      title: 'Eliminar plantilla',
      msg: '¿Desea eliminar la plantilla?',
      onConfirm: handleDeletePublication,
    }));
  }

  const handleClosePublication = () => {
    Boolean(data) && setIsOpen(prev => !prev);
  }

  const handleDownloadDocument = () => {
    dispatch(downloadDocumentTemplateAction({ code: data.code }));
  }

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={11} style={{ cursor: Boolean(data) ? "pointer" : "" }} onClick={handleClosePublication}>
            {
              isCreate ?
                <DataContainer lg={12} sm={12} md={12} text={'Creación de plantilla'} />
                :
                <>
                  <DataContainer sm={12} lg={2} md={2} text={data.code} />
                  <DataContainer sm={12} lg={5} md={5} text={data.typeValue} />
                  <DataContainer sm={12} lg={5} md={5} text={data.name} />
                </>
            }
          </Grid>
          <Grid container direction="row" item xs={1} justify="space-between">
            <div style={{ visibility: Boolean(data?.canDownload) ? 'visible' : 'hidden' }}>
              <Tooltip title={'Descargar documento'}>
                <IconButton size={"small"} disableRipple onClick={handleDownloadDocument}>
                  <GetAppIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
            <div style={{ visibility: Boolean(!isCreate) ? 'visible' : 'hidden' }}>
              <Tooltip title={'Subir documento'}>
                <IconButton size={"small"} disableRipple onClick={() => setUploadDialogIsOpen(true)}>
                  <PublishIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
            <div style={{ visibility: Boolean(data?.canDelete || isCreate) ? 'visible' : 'hidden' }}>
              <Tooltip title={getLocalizedString("delete")}>
                <IconButton size={"small"} disableRipple onClick={handleOpenDeleteDialog}>
                  <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
        </Grid>
      </Card>
      {
        isOpen &&
        <Form
          data={data}
          handleClose={isCreate ? closeNew : handleClosePublication}
        />
      }
      {
        uploadDialogIsOpen &&
        <UploadFilesDialog
          open={uploadDialogIsOpen}
          handleClose={() => setUploadDialogIsOpen(false)}
          code={data.code}
        />
      }
    </>
  );
}

export default DataItem;
