import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: ['currentProcessesDashboardThirdPaymentParty'],
        config: {
            headerName: 'Código de tercero',
            field: 'Código de tercero',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ['currentProcessesDashboardThirdPaymentParty'],
        config: {
            headerName: 'Nombre del tercero',
            field: 'Nombre del tercero',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['currentProcessesDashboardThirdPaymentParty'],
        config: {
            headerName: 'Clasificación',
            field: 'Clasificación',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['currentProcessesDashboardThirdPaymentParty'],
        config: {
            headerName: 'Código de empleado',
            field: 'Código de empleado',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['currentProcessesDashboardThirdPaymentParty'],
        config: {
            headerName: 'Apellidos y nombres',
            field: 'Apellidos y nombres',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['currentProcessesDashboardThirdPaymentParty'],
        config: {
            headerName: 'Forma de pago',
            field: 'Forma de pago',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['currentProcessesDashboardThirdPaymentParty'],
        config: {
            headerName: 'Banco',
            field: 'Banco',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['currentProcessesDashboardThirdPaymentParty'],
        config: {
            headerName: 'Cuenta bancaria',
            field: 'Cuenta bancaria',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ['currentProcessesDashboardThirdPaymentParty'],
        config: {
            headerName: 'Tipo de pago',
            field: 'Tipo de pago',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['currentProcessesDashboardThirdPaymentParty'],
        config: {
            headerName: 'Valor a pagar',
            field: 'Valor a pagar',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ['currentProcessesDashboardThirdPaymentParty'],
        config: {
            headerName: 'Comentarios',
            field: 'Comentarios',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantity"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["currentProcessesDashboardQuantity"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantity"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantity"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantity"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantity"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantity"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantity"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["currentProcessesDashboardQuantity"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantity"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["currentProcessesDashboardQuantityGHD"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantityGHD"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["currentProcessesDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantityGHD"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardQuantityGHD"],
        config: {
            headerName: "Cantidad de días trabajados",
            field: "Días trabajados",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["currentProcessesDashboardUserKPIs"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardUserKPIs"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardUserKPIs"],
        config: {
            headerName: "Fecha de contratación",
            field: "Fecha de contratación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: ["currentProcessesDashboardUserKPIs"],
        config: {
            headerName: "Fecha de egreso",
            field: "Fecha de egreso",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: ["currentProcessesDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["currentProcessesDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardUserKPIs"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["currentProcessesDashboardValuesPerConcept"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardValuesPerConcept"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["currentProcessesDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardValuesPerConcept"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["currentProcessesDashboardCompanyCost"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCompanyCost"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ['currentProcessesDashboardCompanyCost'],
        config: {
            headerName: 'Obra o Faena',
            field: "Obra o Faena",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["currentProcessesDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCompanyCost"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCompanyCost"],
        config: {
            headerName: "Centro de costo",
            field: "Centro de costo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCompanyCost"],
        config: {
            headerName: "Total de Haberes",
            field: "Total de Haberes",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardCompanyCost"],
        config: {
            headerName: "Total aportes del empleador",
            field: "Total aportes del empleador",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardCompanyCost"],
        config: {
            headerName: "Total costo empresa",
            field: "Total costo empresa",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["currentProcessesDashboardTopBottom"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardTopBottom"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["currentProcessesDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardTopBottom"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: "Total de Haberes (Brutos)",
            field: "Total de Haberes (Brutos)",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: "Total de haberes Imponibles",
            field: "Total de haberes Imponibles",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: "Total de leyes sociales",
            field: "Total de leyes sociales",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: "Total de haberes tributables",
            field: "Total de haberes tributables",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: "Total Impuesto",
            field: "Total Impuesto",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: "Total de haberes exentos",
            field: "Total de haberes exentos",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: "Total de descuentos",
            field: "Total de descuentos",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: "Total de descuentos legales",
            field: "Total de descuentos legales",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: "Total de descuentos varios",
            field: "Total de descuentos varios",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: "Total Líquidos/netos",
            field: "Total Líquidos/netos",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: "Total aportes del empleador (Aportes patronales)",
            field: "Total aportes del empleador (Aportes patronales)",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: "Total de beneficios corporativos (Beneficios empresariales)",
            field: "Total de beneficios corporativos (Beneficios empresariales)",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: "Total costo empresa estándar",
            field: "Total costo empresa estándar",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardGlobalValues"],
        config: {
            headerName: "Total costo empresa con beneficios corporativos",
            field: "Total costo empresa con beneficios corporativos",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["currentProcessesDashboardAnalytics"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardAnalytics"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["currentProcessesDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardAnalytics"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardAnalytics"],
        config: {
            headerName: "Haberes con aportes",
            field: "Haberes con aportes",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardAnalytics"],
        config: {
            headerName: "Descuentos legales",
            field: "Descuentos legales",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardAnalytics"],
        config: {
            headerName: "Haberes sin aporte",
            field: "Haberes sin aporte",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardAnalytics"],
        config: {
            headerName: "Descuentos varios",
            field: "Descuentos varios",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardAnalytics"],
        config: {
            headerName: "Líquido/Neto",
            field: "Líquido/Neto",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["currentProcessesDashboardBankDistribution"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardBankDistribution"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["currentProcessesDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardBankDistribution"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardBankDistribution"],
        config: {
            headerName: "Líquido/Neto",
            field: "Liquido/Neto",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardCloud"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["currentProcessesDashboardCloud"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCloud"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCloud"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCloud"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCloud"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCloud"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCloud"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["currentProcessesDashboardCloud"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCloud"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCloud"],
        config: {
            headerName: "Referencia",
            field: "Referencia",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardCloud"],
        config: {
            headerName: "Valores/Ocurrencias",
            field: "Valores/Ocurrencias",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: ["currentProcessesDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["currentProcessesDashboardUserGraphs"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardUserGraphs"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["currentProcessesDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardUserGraphs"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config:
        {
            headerName: "Proceso de cálculo",
            field: "Proceso de cálculo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config: {
            headerName: "Fecha de contratación",
            field: "Fecha de contratación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config: {
            headerName: "Fecha de egreso",
            field: "Fecha de egreso",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
            cellClass: "dateFormat",
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("position"),
            field: "Cargo",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("function"),
            field: "Función",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("management"),
            field: "Gerencia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("department"),
            field: "Departamento",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config: {
            headerName: "Sección",
            field: "Sección",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("laborUnion"),
            field: "Sindicato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "Lugar de trabajo",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "Tipo de funcionario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config: {
            headerName: getLocalizedString("contractType"),
            field: "Tipo de contrato",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config: {
            headerName: "Institución de Previsión",
            field: "Institución de Previsión",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["currentProcessesDashboardConceptGroups"],
        config: {
            headerName: "Institución de Salud",
            field: "Institución de Salud",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["conceptsGrid"],
        config: {
            headerName: "Código de empleado",
            field: "Código de empleado",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["conceptsGrid"],
        config: {
            headerName: "Apellido y nombres",
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcesses],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.employeesProcesses],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "Apellidos y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcesses],
        config: {
            headerName: getLocalizedString("internalNumber"),
            field: "Número interno",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.employeesProcesses],
        config: {
            headerName: getLocalizedString("liquidoNeto"),
            field: "Liquido/Neto",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
]