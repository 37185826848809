import paths from "@icarius-localization/paths";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";
import {
	numberComparatorMax2,
	numberFormatterMin2,
	numberFormatterMin0Max0,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Código del proceso de evaluación',
            field: 'processCode',
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Nombre del proceso de evaluación',
            field: 'processName',
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Tipo de evaluación',
            field: 'evaluationTypeString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Estado para calibración',
            field: 'calibrationStatusString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Estado para clasificación',
            field: 'classificationStatusString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Estado de la evaluación',
            field: 'evaluationStatusString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Fecha de creación',
            field: 'creationDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Fecha desde de evaluación',
            field: 'evaluationStartDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Fecha hasta de evaluación',
            field: 'evaluationEndDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Año de evaluación',
            field: 'evaluationYear',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Período de evaluación',
            field: 'evaluationPeriod',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Meses de antigüedad requeridos',
            field: 'months',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            cellClass: "currencyValueNoDecimal",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Tipo nota final desempeño',
            field: 'performanceScoreTypeString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Tipo nota final potencial',
            field: 'potentialScoreTypeString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Período para objetivos',
            field: 'objectivePeriodString',
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Ponderado descendente',
            field: 'descendantWeight',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Ponderado autoevaluación',
            field: 'selfEvaluationWeight',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Ponderado ascendente',
            field: 'ascendantWeight',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Ponderado de pares',
            field: 'pairWeight',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Pts. Desempeño desde para 9-Boxes 1',
            field: 'performanceFrom1',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Pts. Desempeño hasta para 9-Boxes 1',
            field: 'performanceTo1',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Pts. Desempeño desde para 9-Boxes 2',
            field: 'performanceFrom2',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Pts. Desempeño hasta para 9-Boxes 2',
            field: 'performanceTo2',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Pts. Desempeño desde para 9-Boxes 3',
            field: 'performanceFrom3',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Pts. Desempeño hasta para 9-Boxes 3',
            field: 'performanceTo3',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Pts. Potencial desde para 9-Boxes 1',
            field: 'potentialFrom1',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Pts. Potencial hasta para 9-Boxes 1',
            field: 'potentialTo1',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Pts. Potencial desde para 9-Boxes 2',
            field: 'potentialFrom2',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Pts. Potencial hasta para 9-Boxes 2',
            field: 'potentialTo2',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Pts. Potencial desde para 9-Boxes 3',
            field: 'potentialFrom3',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Pts. Potencial hasta para 9-Boxes 3',
            field: 'potentialTo3',
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            valueFormatter: numberFormatterMin2,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Fecha de inicio en curso',
            field: 'courseStartDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Fecha final de calificación',
            field: 'calificationEndDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Fecha inicio de calibración',
            field: 'calibrationStartDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Fecha inicio de feedback',
            field: 'feedbackStartDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Fecha inicio clasificación',
            field: 'classificationStartDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.evaluationProcessDefinition],
        config: {
            headerName: 'Fecha de cierre',
            field: 'courseEndDate',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: ['evaluationProcessDefinitionCheckCollaborators'],
        config: {
            headerName: 'Código de empleado',
            field: 'Código de empleado',
            filter: 'agSetColumnFilter',
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
        }
    },
    {
        pages: ['evaluationProcessDefinitionCheckCollaborators'],
        config: {
            headerName: 'Apellido y nombres',
            field: 'Apellido y nombres',
            filter: 'agTextColumnFilter',
        }
    },
    {
        pages: ['evaluationProcessDefinitionCheckCollaborators'],
        config:
        {
            headerName: 'Estado',
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ['evaluationProcessDefinitionCheckCollaborators'],
        config: {
            headerName: 'Fecha de contratación',
            field: 'Fecha de contratación',
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: ['evaluationProcessDefinitionCheckCollaborators'],
        config: {
            headerName: 'Cargo',
            field: 'Cargo',
            filter: 'agSetColumnFilter',
        }
    },
    {
        pages: ['evaluationProcessDefinitionCheckCollaborators'],
        config: {
            headerName: 'Función',
            field: 'Función',
            filter: 'agSetColumnFilter',
        }
    },
    {
        pages: ['evaluationProcessDefinitionCheckCollaborators'],
        config: {
            headerName: 'Tipo de funcionario',
            field: 'Tipo de funcionario',
            filter: 'agSetColumnFilter',
        }
    },
    {
        pages: ['evaluationProcessDefinitionCheckCollaborators'],
        config: {
            headerName: 'Lugar de trabajo',
            field: 'Lugar de trabajo',
            filter: 'agSetColumnFilter',
        }
    },
    {
        pages: ['evaluationProcessDefinitionCheckCollaborators'],
        config: {
            headerName: 'Gerencia',
            field: 'Gerencia',
            filter: 'agSetColumnFilter',
        }
    },
    {
        pages: ['evaluationProcessDefinitionCheckCollaborators'],
        config: {
            headerName: 'Departamento',
            field: 'Departamento',
            filter: 'agSetColumnFilter',
        }
    },
    {
        pages: ['evaluationProcessDefinitionCheckCollaborators'],
        config: {
            headerName: 'Centro de costo de gestión',
            field: 'Centro de costo de gestión',
            filter: "agSetColumnFilter",
        }
    },
]