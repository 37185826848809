import React, { memo } from 'react';
import GAGraph from "./GAGraph";
import GLGraph from "./GLGraph";
import GPGraph from "./GPGraph";
import GVGraph from "./GVGraph";
import GHGraph from "./GHGraph";
import GCGraph from "./GCGraph";
import GDGraph from "./GDGraph";
import BXPGraph from "./BXPGraph";
import GHDGraph from "./GHDGraph";
import GNGraph from "./GNGraph";
import CloudGraph from "./CloudGraph";
import BXGraph from "./BXGraph";
import { googleChartColors } from "@icarius-utils/colors"

const DashboardChart = ({ type, data, path, gridTheme, companyColor, customChartColor, setGridDataAndOpenDialog, gnDataGrid }) => {
    switch (type) {
        case "GA":
            return <GAGraph path={path} data={data} customChartColor={customChartColor} setGridDataAndOpenDialog={setGridDataAndOpenDialog} />;
        case "GL":
            return <GLGraph path={path} data={data} customChartColor={customChartColor} setGridDataAndOpenDialog={setGridDataAndOpenDialog} gridTheme={gridTheme} />;
        case "GV":
            return <GVGraph path={path} data={data} customChartColor={customChartColor} setGridDataAndOpenDialog={setGridDataAndOpenDialog} gridTheme={gridTheme} />;
        case "GH":
            return <GHGraph path={path} data={data} customChartColor={customChartColor} setGridDataAndOpenDialog={setGridDataAndOpenDialog} gridTheme={gridTheme} />;
        case "GC":
            return <GCGraph path={path} data={data} customChartColor={customChartColor} setGridDataAndOpenDialog={setGridDataAndOpenDialog} gridTheme={gridTheme} />;
        case "GD":
            return <GDGraph path={path} chartColors={googleChartColors} data={data} customChartColor={customChartColor} setGridDataAndOpenDialog={setGridDataAndOpenDialog} />;
        case "GP":
            return <GPGraph path={path} chartColors={googleChartColors} data={data} customChartColor={customChartColor} setGridDataAndOpenDialog={setGridDataAndOpenDialog} />;
        case "BXP":
            return <BXPGraph path={path} data={data} gridTheme={gridTheme} customChartColor={customChartColor} setGridDataAndOpenDialog={setGridDataAndOpenDialog} />;
        case "BX":
            return <BXGraph path={path} data={data} companyColor={companyColor} gridTheme={gridTheme} setGridDataAndOpenDialog={setGridDataAndOpenDialog} />;
        case "BXCV":
            return <BXGraph isConceptValue path={path} data={data} companyColor={companyColor} gridTheme={gridTheme} setGridDataAndOpenDialog={setGridDataAndOpenDialog} />;
        case "GN":
            return <GNGraph path={path} gnDataGrid={gnDataGrid} data={data} gridTheme={gridTheme} customChartColor={customChartColor} setGridDataAndOpenDialog={setGridDataAndOpenDialog} />;
        case "GHD":
            return <GHDGraph path={path} chartColors={googleChartColors} data={data} gridTheme={gridTheme} setGridDataAndOpenDialog={setGridDataAndOpenDialog} />;
        case "CLOUD":
            return <CloudGraph path={path} data={data} gridTheme={gridTheme} customChartColor={customChartColor} setGridDataAndOpenDialog={setGridDataAndOpenDialog} />;
        default:
            return <div></div>
    }
}

export default memo(DashboardChart);
