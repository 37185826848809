const NAME = "DOCUMENT_TEMPLATES";

export const VARIABLE_SUBSTRINGS = [
    '#N#',
    '#T#',
    '#C#',
    '#OC#',
];

export const FUNCTION_SUBSTRINGS = [
    '#N#',
    '#T#',
    '#CD#',
];

export default NAME;
