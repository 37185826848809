
import React, { memo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, Typography } from "@material-ui/core";
import { formatNumberOrReturnUndefined } from '@icarius-utils/format';

function GLGraph({ data, setGridDataAndOpenDialog, path, gridTheme }) {
    const myData = {
        series: [
            {
                data: Object.values(data.data)
            }
        ],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }, toolbar: {
                    show: false
                }
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            grid: {
                show: true
            },
            markers: { size: 5 },
            xaxis: {
                categories: Object.keys(data.data).map(item => item.split(" ")),
                labels: {
                    rotate: 0,
                    style: {
                        fontSize: '12px',
                        colors: gridTheme === "dark" ? 'white' : 'black'
                    },
                    minHeight: 70,
                },
                tooltip: {
                    enabled: false
                }
            },
            yaxis: {
                labels: {
                    rotate: 0,
                    style: {
                        fontSize: '12px',
                        colors: gridTheme === "dark" ? 'white' : 'black'
                    }
                }
            },
            tooltip: {
                marker: {
                    show: false,
                },
                followCursor: true,
                theme: 'dark',
                y: {
                    formatter: function (val) {
                        return formatNumberOrReturnUndefined(val, 0, 2)
                    },
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
            }
        }
    };

    return (
        <Card className="w-full rounded-20 shadow" >
            <div style={{ padding: "15px 0px 5px 15px", display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ fontWeight: 700, fontSize: 20 }}>{data.title}</Typography>
                <Typography style={{ fontWeight: 700, fontSize: 20 }}>{data.subtitle}</Typography>
            </div>
            <div className="h-96 w-100-p" style={{ cursor: "pointer" }} onClick={() => data.dataGrid && setGridDataAndOpenDialog(data.dataGrid, path)}>
                <ReactApexChart
                    options={myData.options}
                    series={myData.series}
                    type={myData.options.chart.type}
                    height={myData.options.chart.height}
                />
            </div>
        </Card>
    );
}

function preventRender() {
    return true;
}

export default memo(GLGraph, preventRender);