import * as actionTypes from "./actionTypes";
import {
  getDocumentPublicationAPI,
  deleteDocumentPublicationAPI,
  createDocumentPublicationAPI,
  modifyDocumentPublicationAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  let isLongSnackbar = false;
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "SIGN_TEMPLATE_DUPLICATED":
      isLongSnackbar = true;
      errorString = 'Ya existe un documento publicado con firma digital que utiliza la plantilla seleccionada, seleccione otro o genere un nuevo código de plantilla para este documento';
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error", duration: isLongSnackbar ? null : 5000 }));
}

export const getDocumentPublicationAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getDocumentPublicationAPI();

    let publications = response.data && response.data.data;
    let types = response.data && response.data.types;
    let workflows = response.data && response.data.workflows;
    let codes = response.data && response.data.codes;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { publications, types, workflows, codes },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteDocumentPublicationAction = (internalCode, name, type) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteDocumentPublicationAPI({ internalCode, name, type });

    let publications = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { publications },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("publicationDeletedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createDocumentPublicationAction = (publication) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createDocumentPublicationAPI(publication);

    let publications = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { publications },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("publicationCreatedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const modifyDocumentPublicationAction = (publication) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await modifyDocumentPublicationAPI(publication);

    let publications = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { publications },
    });

    dispatch(openSnackbarAction({ msg: getLocalizedString("publicationModifiedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};