import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import {
    dateClassRule,
    dateFormatter,
    dateValueGetter,
    dateFilterParams,
    dateComparator,
} from "@icarius-table/date";

export default [
    {
        pages: [paths.evaluationProcessDefinitionAssignment],
        config:
        {
            headerName: "Código del evaluado",
            field: "Código de empleado",
            filter: "agTextColumnFilter",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: [paths.evaluationProcessDefinitionAssignment],
        config:
        {
            headerName: "Apellido y nombres del evaluado",
            field: "Apellido y nombres",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinitionAssignment],
        config:
        {
            headerName: "Estado",
            field: "Estado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinitionAssignment],
        config:
        {
            headerName: "Fecha de contratación",
            field: "Fecha de contratación",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: [paths.evaluationProcessDefinitionAssignment],
        config:
        {
            headerName: "Tipo de evaluación",
            field: "Tipo de formulario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinitionAssignment],
        config:
        {
            headerName: "Evaluador",
            field: "Evaluador del tipo de formulario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinitionAssignment],
        config:
        {
            headerName: "Cargo del evaluador",
            field: "Cargo del evaluador",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinitionAssignment],
        config:
        {
            headerName: "Grupo de cargo del evaluado",
            field: "Grupo de cargo del evaluado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinitionAssignment],
        config:
        {
            headerName: "Cargo del evaluado",
            field: "Cargo del evaluado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinitionAssignment],
        config:
        {
            headerName: "Gerencia del evaluado",
            field: "Gerencia del evaluado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinitionAssignment],
        config:
        {
            headerName: "Función del evaluado",
            field: "Función del evaluado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinitionAssignment],
        config:
        {
            headerName: "Centro de costo de gestión del evaluado",
            field: "Centro de costo de gestión del evaluado",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.evaluationProcessDefinitionAssignment],
        config:
        {
            headerName: "Diseño del formulario",
            field: "Diseño del formulario",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("employeeCode"),
            field: "CODIGO DE EMPLEADO",
            filter: "agTextColumnFilter",
            chartDataType: "series",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
            enableRowGroup: true,
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config:
        {
            headerName: getLocalizedString("nameAndSurname"),
            field: "APELLIDO Y NOMBRE",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config:
        {
            headerName: getLocalizedString("state"),
            field: "ESTADO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config:
        {
            headerName: "Acceso a ICARIUS",
            field: "HABILITAR EL ACCESO A ICARIUS",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("incomeDate"),
            field: "FECHA DE INGRESO",
            filter: "agTextColumnFilter",
            comparator: dateComparator,
            filterParams: dateFilterParams,
            cellClassRules: dateClassRule,
            valueFormatter: dateFormatter,
            valueGetter: dateValueGetter,
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("position"),
            field: "CARGO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config: {
            headerName: 'Grupo del cargo para evaluación',
            field: "GRUPO EVALUACIÓN DEL CARGO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config: {
            headerName: 'Grupo competencias específicas cargo',
            field: "GRUPO COMPETENCIAS ESPECÍFICAS CARGO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config: {
            headerName: 'Función',
            field: "FUNCIÓN",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("fType"),
            field: "TIPO DE FUNCIONARIO",
            filter: "agSetColumnFilter",
            chartDataType: "series",
            enableRowGroup: true,
            enableValue: true,
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("jobLocation"),
            field: "LUGAR DE TRABAJO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config: {
            headerName: 'Obra o Faena',
            field: "OBRA O FAENA",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config: {
            headerName: 'Centro de costo de gestión',
            field: "CENTRO DE COSTO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("plantPersonnel"),
            field: "PERSONAL DE PLANTA",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("management"),
            field: "GERENCIA",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config: {
            headerName: getLocalizedString("department"),
            field: "DEPARTAMENTO",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config: {
            headerName: 'Sección',
            field: "SECCION",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: ["evaluationProcessDefinitionAssignmentUserSelection"],
        config: {
            headerName: 'Tipo de jornada',
            field: "TIPO DE JORNADA",
            filter: "agSetColumnFilter",
        }
    },
]