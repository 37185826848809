import React, { useState } from "react";
import { useSelector } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { getAnalyticsFilters } from "../../selectors";
import DialogTransition from "@icarius-common/dialogTransition";

const gridStyle = { height: 75, padding: "10px 0px" };

const EMPLOYEE_MASTER_FILTERS = [
  "Departamento",
  "Cargo",
  "Gerencia",
  "Lugar de trabajo",
  "Obra o Faena",
];

const CONCEPTS_FILTERS = [
  "Acumuladores",
  "Grupos para conceptos",
  "Conjunto de conceptos",
  "Estructura de ingreso",
];

const FiltersDialog = (props) => {

  const { open, data, isDisabled, handleClose, setFilterData } = props;
  const [formData, setFormData] = useState(data);
  const filters = useSelector(getAnalyticsFilters);

  const validateAndCreate = () => {
    setFilterData(formData);
    handleClose();
  }

  const getLabel = (name) => getLocalizedString(`filter${name}`);

  const handleChange = (fieldName, newValue, isConceptFilter) => {
    const emptyFormDataEmployee = EMPLOYEE_MASTER_FILTERS.reduce((initialData, fieldName) => ({ ...initialData, [fieldName]: '' }), {});
    const emptyFormDataConcepts = CONCEPTS_FILTERS.reduce((initialData, fieldName) => ({ ...initialData, [fieldName]: '' }), {});

    if (isConceptFilter) {
      // limpio todo y seteo el valor nuevo del concepto
      setFormData({
        ...emptyFormDataEmployee,
        ...emptyFormDataConcepts,
        [fieldName]: newValue,
      });
    } else {
      // limpio solo conceptos y seteo el valor nuevo del empleado (que puede tener mas de un filtro activo)
      setFormData(prev => ({
        ...prev,
        ...emptyFormDataConcepts,
        [fieldName]: newValue,
      }));
    }
  }

  if (!filters || (filters && Object.keys(filters).length === 0)) return null;

  return (
    <Dialog open={open} TransitionComponent={DialogTransition} PaperComponent={PaperDraggable} fullWidth maxWidth={"sm"} >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {"Filtros"}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Grid container direction="column" style={{ maxHeight: 500, flexWrap: "nowrap" }}>
          <Typography className={"whiteText"} style={{ textAlign: 'center', fontSize: '1.2rem', textDecoration: 'underline' }}>
            {"Del Maestro de Empleados"}
          </Typography>
          {
            filters && Object.values(filters).slice(0, 5).map((filter, index) => {
              const name = Object.keys(filters)[index];
              return (
                <Grid key={index} container direction="row" justify="center" item style={gridStyle}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id={`label-${name}`}>{getLabel(name)}</InputLabel>
                    <Select
                      disabled={isDisabled}
                      value={formData[name]}
                      labelId={`label-${name}`}
                      id={`select-${name}`}
                      onChange={(e) => handleChange(name, String(e.target.value))}
                      margin={"none"}
                    >
                      <MenuItem className={"whiteText"} value={''}>
                        {'Sin filtro'}
                      </MenuItem>
                      {
                        filter?.map(item => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.key}
                            value={item.key}>
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              )
            })
          }
          <Typography className={"whiteText"} style={{ marginTop: 20, textAlign: 'center', fontSize: '1.2rem', textDecoration: 'underline' }}>
            {"De los Conceptos"}
          </Typography>
          {
            filters && Object.values(filters).slice(5, filters.length).map((filter, index) => {
              const name = Object.keys(filters)[index + 5];
              return (
                <Grid key={index + 5} container direction="row" justify="center" item style={gridStyle}>
                  <FormControl style={{ width: "100%" }}>
                    <InputLabel id={`label-${name}`}>{getLabel(name)}</InputLabel>
                    <Select
                      disabled={isDisabled}
                      value={formData[name]}
                      labelId={`label-${name}`}
                      id={`select-${name}`}
                      onChange={(e) => handleChange(name, String(e.target.value), true)}
                      margin={"none"}
                    >
                      <MenuItem className={"whiteText"} value={''}>
                        {'Sin filtro'}
                      </MenuItem>
                      {
                        filter?.map(item => (
                          <MenuItem
                            className={"whiteText"}
                            key={item.key}
                            value={item.key}>
                            {item.value}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              )
            })
          }
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString(isDisabled ? "close" : "disagree")} />
        {
          !isDisabled &&
          <ButtonDialogAction onClick={validateAndCreate} isAccept text={getLocalizedString("agree")} />
        }
      </DialogActions>
    </Dialog>
  )
}

export default FiltersDialog;
