import React from "react";
import {
  Grid,
  Typography,
} from "@material-ui/core";

const StepContainer = ({ title, children }) => {
  return (
    <Grid container item xs={12} justify="center">
      <Grid container item sm={12} md={10} style={{ padding: '0px 20px' }}>
        <Typography className="whiteText" style={{ fontSize: 20, width: '100%', padding: '0px 10px' }}>
          {title}
        </Typography>
        <Grid container item alignItems="center" justify="space-between" xs={12} style={{ paddingTop: 10 }}>
          {children}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StepContainer;