import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (country, type, data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = type === "E" ? ["code", "name", "description", "bookCode"] : ["code", "name"];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {

    const codeRegex = /^[A-Z0-9]+[A-Z0-9-]*[A-Z0-9]$/;

    const basicSchema = yup.object().shape({
      code: yup.string().matches(codeRegex).required(),
      name: yup.string().required(),
    });

    const entryExtraFieldsSchema = yup.object().shape({
      description: yup.string().required().max(600),
      bookCode: yup.string().max(2)
        .when([], {
          is: () => country === "CL",
          then: yup.string().required()
        }),
    });

    const schema = type === "E" ? basicSchema.concat(entryExtraFieldsSchema) : basicSchema;

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      const formDataToSend = formData;

      if (country === "CL" && type === "E" && formDataToSend.bookCode === "") {
        formDataToSend.bookCode = 0;
      }

      isCreate ? createCallback(formDataToSend) : modifyCallback(formDataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "code") {
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
