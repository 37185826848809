import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import { EntryIcon, ExitIcon, ContractTypesIcon } from "@icarius-icons/";
import { getLocalizedString } from "@icarius-localization/strings";

const Index = () => {

  const data = [
    {
      name: "Motivos de ingreso",
      icon: <EntryIcon />,
      path: paths.entryTypes,
    },
    {
      name: "Motivos de egreso",
      icon: <ExitIcon />,
      path: paths.exitTypes,
    },
    {
      name: "Tipos de contratos",
      icon: <ContractTypesIcon />,
      path: paths.contractTypesSettings,
    }
  ];

  return (
    <ScreenSelector
      title={getLocalizedString("entryExitTypes")}
      data={data}
    />
  );
}

export default Index;
