import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, invalidDataCallback) => {
  const createInitialData = () => {

    const getInitialFieldData = () => {
      if (data.evaluationStatus === 'K')
        return (
          `Informamos a todos los evaluadores que pondremos en feedback el proceso ${data.processName}, para que inicien con las entrevistas del desempeño.\n\nAcceda a Gestión del desempeño / Resumen y Resultados y utilice los siguientes botones: “Resumen final” (Para la visualización completa de la evaluación del desempeño de cada evaluado), y posteriormente el botón “Dar feedback” (Para dejar por escrito el feedback entregado al evaluado).\n\n¡Muchas gracias por su participación!`
        );

      return (
        `Informamos a todos los evaluadores que pondremos en curso el proceso ${data.processName}, para que inicien con las calificaciones del desempeño.\n\nAcceda a Gestión del desempeño / Evaluaciones del desempeño y complete los formularios de calificación para cada uno de sus evaluados.\n\n¡Muchas gracias por su participación!`
      )
    }

    const fieldNames = [
      "message",
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'message': yup.string().required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      createCallback({
        processCode: data.processCode,
        message: formData.message,
      });

      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    formData,
    setFormValue,
    submit,
  };
}

export default useHandleForm;
