import React, { memo } from "react";
import DashboardChart from "../widgets";
import { Grid, useMediaQuery } from "@material-ui/core";

const TopBottom = ({ data, setGridDataAndOpenDialog }) => {
    const matchesLessThan960 = useMediaQuery(`(max-width:960px)`);

    return (
        <Grid container justify="space-between" item xs={12}>
            <Grid item md={6} xs={12} style={matchesLessThan960 ? { paddingTop: 20 } : { padding: "0px 30px 0px 0px" }}>
                <DashboardChart
                    path="currentProcessesDashboardTopBottom"
                    setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                    type={"GA"}
                    data={data[0]}
                    customChartColor="#00993D80"
                />
            </Grid>
            <Grid item md={6} xs={12} style={matchesLessThan960 ? { paddingTop: 20 } : { padding: "0px 0px 0px 30px" }}>
                <DashboardChart
                    path="currentProcessesDashboardTopBottom"
                    setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                    type={"GA"}
                    data={data[1]}
                    customChartColor="#FF592C80"
                />
            </Grid>
        </Grid>
    )
}

export default memo(TopBottom);
