import React from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import CommonPage from "@icarius-common/commonPage";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import {
  Grid,
  Stepper,
  Step,
  StepButton,
} from "@material-ui/core";
import useHandleStepper from "@icarius-utils/hooks/useHandleStepper";
import {
  createEvaluationProcessDefinitionAction,
  updateEvaluationProcessDefinitionAction,
} from "../../actions";
import StepOne from "./steps/stepOne";
import StepTwo from "./steps/stepTwo";
import StepThree from "./steps/stepThree";
import StepFour from "./steps/stepFour";
import useHandleForm from "./useHandleForm";

const CreateEditForm = (props) => {

  const {
    isLoading,
    data,
    evaluationTypeList,
    formList,
    scoreTypeList,
    objectivePeriodList,
    statusList,
    handleClose,
  } = props;

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleClose}
      type={"goBack"}
    />

  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createEvaluationProcessDefinitionAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateEvaluationProcessDefinitionAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = (errorMessage) => {
    dispatch(openSnackbarAction({ msg: errorMessage || getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
    stepOneIsValid,
    stepTwoIsValid,
    stepThreeIsValid,
    selectedForm,
    selectedPerformanceScore,
    selectedPotentialScore,
    reviewsPotential,
  } = useHandleForm(data, create, modify, openValidationError, formList, scoreTypeList);

  const steps = [
    {
      name: 'Identificación del proceso',
      render: () => (
        <StepOne
          evaluationTypeList={evaluationTypeList}
          isCreate={isCreate}
          formData={formData}
          setFormValue={setFormValue}
        />
      )
    },
    {
      name: 'Formulario y ponderados',
      render: () => {
        return (
          <StepTwo
            formList={formList}
            scoreTypeList={scoreTypeList}
            objectivePeriodList={objectivePeriodList}
            selectedForm={selectedForm}
            formData={formData}
            setFormValue={setFormValue}
          />
        )
      },
    },
    {
      name: 'Rangos para 9-Boxes',
      render: () => {
        return (
          <StepThree
            selectedPerformanceScore={selectedPerformanceScore}
            selectedPotentialScore={selectedPotentialScore}
            reviewsPotential={reviewsPotential}
            formData={formData}
            setFormValue={setFormValue}
          />
        )
      },
    },
    {
      name: 'Revisión de pasos y etapas',
      render: () => {
        return (
          <StepFour
            statusList={statusList}
            data={data}
            isCreate={isCreate}
            formData={formData}
            setFormValue={setFormValue}
          />
        )
      },
    },
  ];

  const {
    currentStep,
    setCurrentStep,
    setNextStep,
    setPreviousStep,
    renderStep,
  } = useHandleStepper(steps);

  const handleSetNextStep = async () => {
    let stepValidationData = await [stepOneIsValid, stepTwoIsValid, stepThreeIsValid][currentStep]();

    if (!stepValidationData.isValid) {
      openValidationError(stepValidationData.errorMessage);
      return;
    }

    setNextStep();
  }

  const title = (() => {
    if (currentStep === 0) return `${isCreate ? 'Crear' : 'Editar'} proceso de evaluación`;
    if (isCreate) return `Crear proceso de evaluación: ${formData.processName}`;
    return `Editar proceso de evaluación: ${formData.processName}`;
  })();

  return (
    <CommonPage
      title={title}
      menuItems={[goBackItem]}
      isLoading={isLoading}
      isNotGridPage
    >
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12} justify="center">
          <Stepper activeStep={currentStep} alternativeLabel style={{ width: '100%' }}>
            {
              steps.map((step, index) => {
                return (
                  <Step key={index}>
                    <StepButton onClick={() => index < currentStep && setCurrentStep(index)}>{step.name}</StepButton>
                  </Step>
                );
              })
            }
          </Stepper>
        </Grid>
        <Grid container item xs={12}>
          {renderStep()}
        </Grid>
      </Grid>
      <Grid container item sm={12} justify="center">
        <Grid container item sm={12} md={10} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20, paddingTop: 10, paddingRight: 10 }}>
          {
            (currentStep !== 0) &&
            <div style={{ marginRight: 10 }}>
              <ButtonDialogAction isAccept onClick={setPreviousStep} text={'Anterior'} />
            </div>
          }
          {
            (currentStep === steps.length - 1)
              ? <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
              : <ButtonDialogAction isAccept onClick={handleSetNextStep} text={'Siguiente'} />
          }
        </Grid>
      </Grid>
    </CommonPage>
  )
}

export default CreateEditForm;