import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "requiresReceiverSelection") return isCreate ? "N" : data[fieldName];
      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = ["code", "name", "type", "requiresReceiverSelection"];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(() => createInitialData());

  const dataIsValid = async () => {

    const codeRegex = /^[A-Z]+[A-Z0-9]*/;

    let schema = yup.object().shape({
      code: yup.string().matches(codeRegex).required(),
      name: yup.string().required(),
      type: yup.string().required(),
      requiresReceiverSelection: yup.string(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      isCreate ? createCallback(formData) : modifyCallback(formData);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const formatValue = (value, fieldName) => {
    if (fieldName === "code") {
      return value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "";
    }

    return value;
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === "type") {
      setFormData({
        ...formData,
        [fieldName]: formatValue(value, fieldName),
        requiresReceiverSelection: "N",
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: formatValue(value, fieldName)
    })
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleForm;
