import { useState, useEffect } from "react";
import { VARIABLE_SUBSTRINGS, FUNCTION_SUBSTRINGS } from "@icarius-pages/documentTemplates/constants";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useDispatch } from "react-redux";

const useHandleVariableForm = (typeSelected) => {

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "Extra variable field") {
        return [];
      }

      if (fieldName === "Extra funcion") {
        return [];
      }

      return "";
    }

    const fieldNames = [
      "Variables precargadas",
      "Valores generales",
      "Maestro de empleados",
      "Variable segun type", // el select calculable segun el tipo seleccionado
      "Funcion",
      // las dos que siguen son para los campos calculados
      "Extra variable field", // objeto con los valores de los campos dinamicos segun el contenido de las variables
      "Extra funcion" // objeto con los valores de los campos dinamicos segun el contenido de la funcion
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());
  const [resultado, setResultado] = useState('');
  const dispatch = useDispatch();

  // cada vez que cambie el tipo, limpiar campos dependientes
  useEffect(() => {
    setFormData(prevFormData => ({
      ...prevFormData,
      'Variable segun type': '',
      'Extra variable field': [],
    }))
  }, [typeSelected])


  // calcular resultado
  useEffect(() => {
    const getResultado = () => {
      // ver que variable tiene valor
      let variableToUse = formData["Variables precargadas"] || formData["Valores generales"] || formData["Maestro de empleados"] || formData["Variable segun type"];
      let funcionToUse = formData['Funcion'];

      // si hay algun Extra variable field, reemplazar en variableToUse por esos
      if (Object.keys(formData['Extra variable field']).length) {
        // hacer replace del key por el value SOLO si la key no es ""
        formData['Extra variable field'].forEach(extraVariable => {
          if (extraVariable.value) {
            variableToUse = variableToUse.replace(extraVariable.key, extraVariable.value);
          }
        })
      }

      if (funcionToUse) {
        // si hay algun Extra funcion, reemplazar en funcionToUse por esos
        if (Object.keys(formData['Extra funcion']).length) {
          // hacer replace del key por el value SOLO si la key no es ""
          formData['Extra funcion'].forEach(extraVariable => {
            if (extraVariable.value) {
              funcionToUse = funcionToUse.replace(extraVariable.key, extraVariable.value);
            }
          })
        }

        if (variableToUse) {
          return funcionToUse.replace('#FIELD#', variableToUse);
        }

        return funcionToUse;
      }

      return variableToUse;
    }

    setResultado(`<${getResultado()}>`);
  }, [formData])

  const submit = () => {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(resultado);
      dispatch(openSnackbarAction({ msg: 'Copiado al portapapeles', severity: "success" }));
      return true;
    }
    return false;
  }

  const resetExtraValue = (substringArray, variableValue) => {
    let arrayAux = [];

    substringArray.forEach(item => {
      const regex = new RegExp(item, 'g');
      const amountOccurrances = (variableValue.match(regex) || []).length;
      for (let i = 0; i < amountOccurrances; i++) {
        arrayAux.push({
          key: item,
          value: '',
        })
      }
    })

    return arrayAux;
  }

  const setExtraValue = (value, name, index) => {
    let extraVariableFieldAux = [...formData[name]];

    extraVariableFieldAux[index].value = value;

    setFormData({
      ...formData,
      [name]: extraVariableFieldAux,
    })
  }

  const setFormValue = (value, fieldName) => {
    // handle de si se hace click en el divisor (que hace que se envie como valor undefined)
    if (fieldName === "Variable segun type" && value === undefined) {
      return;
    }

    let variablesPrecargadasAux = formData["Variables precargadas"];
    let valoresGeneralesAux = formData["Valores generales"];
    let maestroDeEmpleadosAux = formData["Maestro de empleados"];
    let variableSegunTypeAux = formData["Variable segun type"];
    let funcionAux = formData["Funcion"];
    let extraVariableFieldAux = formData["Extra variable field"];
    let extraFuncionAux = formData["Extra funcion"];

    if (fieldName === "Funcion") {
      funcionAux = value;
      extraFuncionAux = resetExtraValue(FUNCTION_SUBSTRINGS, value);
    }

    // setear variable y limpiar las otras
    if (fieldName === "Variables precargadas") {
      variablesPrecargadasAux = value;
      valoresGeneralesAux = '';
      maestroDeEmpleadosAux = '';
      variableSegunTypeAux = '';
      extraVariableFieldAux = resetExtraValue(VARIABLE_SUBSTRINGS, value);
    }

    // setear variable y limpiar las otras
    if (fieldName === "Valores generales") {
      variablesPrecargadasAux = '';
      valoresGeneralesAux = value;
      maestroDeEmpleadosAux = '';
      variableSegunTypeAux = '';
      extraVariableFieldAux = resetExtraValue(VARIABLE_SUBSTRINGS, value);
    }

    // setear variable y limpiar las otras
    if (fieldName === "Maestro de empleados") {
      variablesPrecargadasAux = '';
      valoresGeneralesAux = '';
      maestroDeEmpleadosAux = value;
      variableSegunTypeAux = '';
      extraVariableFieldAux = resetExtraValue(VARIABLE_SUBSTRINGS, value);
    }

    // setear variable y limpiar las otras
    if (fieldName === "Variable segun type") {
      variablesPrecargadasAux = '';
      valoresGeneralesAux = '';
      maestroDeEmpleadosAux = '';
      variableSegunTypeAux = value;
      extraVariableFieldAux = resetExtraValue(VARIABLE_SUBSTRINGS, value);
    }

    setFormData({
      ...formData,
      "Variables precargadas": variablesPrecargadasAux,
      "Valores generales": valoresGeneralesAux,
      "Maestro de empleados": maestroDeEmpleadosAux,
      "Variable segun type": variableSegunTypeAux,
      "Funcion": funcionAux,
      // las dos que siguen son para los campos calculados
      "Extra variable field": extraVariableFieldAux, // objeto con los valores de los campos dinamicos segun el contenido de las variables
      "Extra funcion": extraFuncionAux // objeto con los valores de los campos dinamicos segun el contenido de la funcion
    });
  }

  return { formData, resultado, setFormValue, setExtraValue, submit };
}

export default useHandleVariableForm;
