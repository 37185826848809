import { useState } from "react";
import { createDateFromDDMMYYYY, createDateFromYYYYMM, formatDateYYYYMM, formatDateYYYYMMDD } from "@icarius-utils/date";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, formList, scoreTypeList) => {

  const isCreate = !Boolean(data);

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === 'evaluationStartDate') {
        return isCreate ? new Date() : createDateFromDDMMYYYY(data[fieldName]);
      }

      if (fieldName === 'evaluationStatus') {
        return isCreate ? 'C' : data[fieldName];
      }

      if ('evaluationEndDate' === fieldName) {
        return (isCreate || !data[fieldName]) ? null : createDateFromDDMMYYYY(data[fieldName]);
      }

      if (fieldName === 'evaluationYear') {
        if (isCreate) return null;

        const newEvaluationYear = new Date();
        newEvaluationYear.setFullYear(data[fieldName]);

        return newEvaluationYear;
      }

      if (fieldName === 'evaluationPeriod') {
        return isCreate ? null : createDateFromYYYYMM(data[fieldName]);
      }

      if (['descendantWeight', 'selfEvaluationWeight', 'ascendantWeight', 'pairWeight'].includes(fieldName)) {
        return isCreate ? 0 : data[fieldName];
      }

      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = [
      'processCode',
      'processName',
      'evaluationStartDate',
      'evaluationEndDate',
      'evaluationYear',
      'evaluationPeriod',
      'evaluationType',
      'months',
      'formCode',
      'performanceScoreType',
      'potentialScoreType',
      'objectivePeriod',
      'descendantWeight',
      'selfEvaluationWeight',
      'ascendantWeight',
      'pairWeight',
      'performanceFrom1',
      'performanceTo1',
      'performanceFrom2',
      'performanceTo2',
      'performanceFrom3',
      'performanceTo3',
      'potentialFrom1',
      'potentialTo1',
      'potentialFrom2',
      'potentialTo2',
      'potentialFrom3',
      'potentialTo3',
      'evaluationStatus',
    ];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const selectedForm = Object.values(formList).flat().find((item) => item.key === formData.formCode);
  const selectedPerformanceScore = scoreTypeList.find((item) => item.key === formData.performanceScoreType);
  const selectedPotentialScore = scoreTypeList.find((item) => item.key === formData.potentialScoreType);

  const reviewsPotential = formData.evaluationType === 'P';

  const stepOneIsValid = async () => {
    const codeRegex = /^[A-Z]+[A-Z0-9]*$/;

    const schema = yup.object().shape({
      'processCode': yup.string().matches(codeRegex),
      'processName': yup.string().required(),
      'evaluationStartDate': yup.date().required(),
      'evaluationEndDate': yup.date().required(),
      'evaluationYear': yup.date().required(),
      'evaluationPeriod': yup.date().required(),
      'evaluationType': yup.string().required(),
      'months': yup.string().required(),
    });

    const schemaIsValid = await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            console.log(error.message);
          });
          return false;
        }
      });

    return { isValid: schemaIsValid, errorMessage: "" };
  }

  const stepTwoIsValid = async () => {
    const schema = yup.object().shape({
      'formCode': yup.string().required(),
      'performanceScoreType': yup.string().required(),
      'potentialScoreType': yup.string()
        .when([], {
          is: () => reviewsPotential,
          then: yup.string().required()
        }),
      'objectivePeriod': yup.string()
        .when([], {
          is: () => selectedForm.reviewsObjectives,
          then: yup.string().required()
        }),
      'descendantWeight': yup.number(),
      'selfEvaluationWeight': yup.number(),
      'ascendantWeight': yup.number(),
      'pairWeight': yup.number(),
    });

    const schemaIsValid = await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            console.log(error.message);
          });
          return false;
        }
      });

    if (schemaIsValid) {
      // validar la suma de los ponderados
      const totalWeight = formData.descendantWeight + formData.selfEvaluationWeight + formData.ascendantWeight + formData.pairWeight;
      return { isValid: totalWeight === 100, errorMessage: "La sumatoria de todos los ponderados de evaluaciones debe ser 100%" };
    }

    return { isValid: false, errorMessage: "" };
  }

  const stepThreeIsValid = async () => {
    const schema = yup.object().shape({
      'performanceFrom1': yup.string().required(),
      'performanceTo1': yup.string().required(),
      'performanceFrom2': yup.string().required(),
      'performanceTo2': yup.string().required(),
      'performanceFrom3': yup.string().required(),
      'performanceTo3': yup.string().required(),
      'potentialFrom1': yup.string()
        .when([], {
          is: () => reviewsPotential,
          then: yup.string().min(1),
        }),
      'potentialTo1': yup.string()
        .when([], {
          is: () => reviewsPotential,
          then: yup.string().min(1),
        }),
      'potentialFrom2': yup.string()
        .when([], {
          is: () => reviewsPotential,
          then: yup.string().min(1),
        }),
      'potentialTo2': yup.string()
        .when([], {
          is: () => reviewsPotential,
          then: yup.string().min(1),
        }),
      'potentialFrom3': yup.string()
        .when([], {
          is: () => reviewsPotential,
          then: yup.string().min(1),
        }),
      'potentialTo3': yup.string()
        .when([], {
          is: () => reviewsPotential,
          then: yup.string().min(1),
        }),
    });

    const schemaIsValid = await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            console.log(error.message);
          });
          return false;
        }
      });

    if (schemaIsValid) {
      // validar los minimos y maximos
      const performanceFieldsArray = [
        formData.performanceFrom1,
        formData.performanceFrom2,
        formData.performanceFrom3,
        formData.performanceTo1,
        formData.performanceTo2,
        formData.performanceTo3,
      ];

      const potentialFieldsArray = [
        formData.potentialFrom1,
        formData.potentialFrom2,
        formData.potentialFrom3,
        formData.potentialTo1,
        formData.potentialTo2,
        formData.potentialTo3,
      ];

      if (performanceFieldsArray.some((item) => item < selectedPerformanceScore.min)) {
        return { isValid: false, errorMessage: `Ningun valor de desempeño puede ser menor al valor mínimo de la nota (${selectedPerformanceScore.min})` };
      }

      if (performanceFieldsArray.some((item) => item > selectedPerformanceScore.max)) {
        return { isValid: false, errorMessage: `Ningun valor de desempeño puede ser mayor al valor máximo de la nota (${selectedPerformanceScore.max})` };
      }

      if (reviewsPotential) {
        if (potentialFieldsArray.some((item) => item < selectedPotentialScore.min)) {
          return { isValid: false, errorMessage: `Ningun valor de potencial puede ser menor al valor mínimo de la nota (${selectedPotentialScore.min})` };
        }

        if (potentialFieldsArray.some((item) => item > selectedPotentialScore.max)) {
          return { isValid: false, errorMessage: `Ningun valor de potencial puede ser mayor al valor máximo de la nota (${selectedPotentialScore.max})` };
        }
      }
    }

    return { isValid: schemaIsValid, errorMessage: "" };
  }

  const dataIsValid = async () => {
    const schema = yup.object().shape({
      'evaluationStatus': yup.string().required(),
    });

    const schemaIsValid = await schema.validate(formData, { abortEarly: false })
      .then((castData) => castData)
      .catch(err => {
        if (err instanceof yup.ValidationError) {
          err.inner.forEach(error => {
            console.log(error.message);
          });
          return false;
        }
      });

    return { isValid: schemaIsValid, errorMessage: "" };
  }

  const submit = async () => {
    const validationData = await dataIsValid();

    if (!validationData.isValid) {
      invalidDataCallback(validationData.errorMessage);
      return;
    }

    const dataToSend = {
      ...formData,
      evaluationStartDate: formData.evaluationStartDate ? formatDateYYYYMMDD(formData.evaluationStartDate) : null,
      evaluationEndDate: formData.evaluationEndDate ? formatDateYYYYMMDD(formData.evaluationEndDate) : null,
      evaluationYear: formData.evaluationYear ? formData.evaluationYear.getFullYear() : null,
      evaluationPeriod: formData.evaluationPeriod ? formatDateYYYYMM(formData.evaluationPeriod) : null,
    }

    isCreate ? createCallback(dataToSend) : modifyCallback({ ...dataToSend, internalCode: data.internalCode });
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === "processCode") {
      setFormData({
        ...formData,
        [fieldName]: value.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "",
      });
      return;
    }

    if (fieldName === 'evaluationType') {
      setFormData({
        ...formData,
        [fieldName]: value,
        potentialScoreType: '',
        formCode: '',
        objectivePeriod: '',
        descendantWeight: 0,
        selfEvaluationWeight: 0,
        ascendantWeight: 0,
        pairWeight: 0,
        potentialFrom1: '',
        potentialTo1: '',
        potentialFrom2: '',
        potentialTo2: '',
        potentialFrom3: '',
        potentialTo3: '',
      });
      return;
    }

    if (fieldName === 'formCode') {
      setFormData({
        ...formData,
        [fieldName]: value,
        objectivePeriod: '',
        descendantWeight: 0,
        selfEvaluationWeight: 0,
        ascendantWeight: 0,
        pairWeight: 0,
        potentialFrom1: '',
        potentialTo1: '',
        potentialFrom2: '',
        potentialTo2: '',
        potentialFrom3: '',
        potentialTo3: '',
      });
      return;
    }

    if (fieldName === 'evaluationStartDate') {
      if (formData.evaluationEndDate) {
        const newStartDate = value.setHours(0, 0, 0, 0).valueOf();
        const newEndDate = formData.evaluationEndDate.setHours(0, 0, 0, 0).valueOf();

        if (newStartDate > newEndDate) {
          setFormData({
            ...formData,
            [fieldName]: value,
            evaluationEndDate: null,
            evaluationYear: null,
            evaluationPeriod: null,
          });
          return;
        }
      }

      setFormData({
        ...formData,
        [fieldName]: value,
      });
      return;
    }

    if (fieldName === 'evaluationEndDate') {
      setFormData({
        ...formData,
        [fieldName]: value,
        evaluationYear: value,
        evaluationPeriod: value,
      });
      return;
    }

    setFormData({
      ...formData,
      [fieldName]: value,
    })
  }

  return {
    isCreate,
    formData,
    setFormValue,
    submit,
    stepOneIsValid,
    stepTwoIsValid,
    stepThreeIsValid,
    selectedForm,
    selectedPerformanceScore,
    selectedPotentialScore,
    reviewsPotential,
  };
}

export default useHandleForm;