import React from "react";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import { getLocalizedString } from "@icarius-localization/strings";
import StepContainer from "./stepContainer";

const gridStyle = { height: 75, padding: "0px 10px" };

const StepOne = (props) => {

  const {
    evaluationTypeList,
    isCreate,
    formData,
    setFormValue,
  } = props;

  return (
    <StepContainer title={'Indique los parámetros del nuevo proceso de evaluación'}>
      <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
        <TextField
          disabled={!isCreate}
          required
          fullWidth
          margin={"none"}
          label={'Código'}
          value={formData.processCode}
          inputProps={{ maxLength: 20 }}
          onChange={(e) => setFormValue(e.target.value, "processCode")}
        />
      </Grid>
      <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
        <TextField
          required
          fullWidth
          margin={"none"}
          label={'Nombre'}
          value={formData.processName}
          inputProps={{ maxLength: 80 }}
          onChange={(e) => setFormValue(e.target.value, "processName")}
        />
      </Grid>
      <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <DatePicker
            fullWidth
            required
            label={'Fecha desde de evaluación'}
            cancelLabel={getLocalizedString("cancel")}
            okLabel={getLocalizedString("ok")}
            invalidDateMessage=''
            format="dd/MM/yyyy"
            margin="normal"
            value={formData.evaluationStartDate}
            onChange={(e) => setFormValue(e, "evaluationStartDate")}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <DatePicker
            fullWidth
            required
            label={'Fecha hasta de evaluación'}
            cancelLabel={getLocalizedString("cancel")}
            okLabel={getLocalizedString("ok")}
            invalidDateMessage=''
            minDate={formData.evaluationStartDate}
            minDateMessage=''
            format="dd/MM/yyyy"
            margin="normal"
            value={formData.evaluationEndDate}
            onChange={(e) => setFormValue(e, "evaluationEndDate")}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <DatePicker
            disabled
            fullWidth
            required
            label={'Año de evaluación'}
            cancelLabel={getLocalizedString("cancel")}
            okLabel={getLocalizedString("ok")}
            invalidDateMessage=''
            format="yyyy"
            margin="normal"
            value={formData.evaluationYear}
            onChange={(e) => setFormValue(e, "evaluationYear")}
            views={["year"]}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
          <DatePicker
            fullWidth
            required
            label={'Período de evaluación'}
            cancelLabel={getLocalizedString("cancel")}
            okLabel={getLocalizedString("ok")}
            invalidDateMessage=''
            minDate={formData.evaluationStartDate}
            maxDate={formData.evaluationEndDate}
            format="MM/yyyy"
            margin="normal"
            value={formData.evaluationPeriod}
            onChange={(e) => setFormValue(e, "evaluationPeriod")}
            views={["month", "year"]}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
        <FormControl style={{ width: "100%" }}>
          <InputLabel required id={'label-evaluationType'}>{'Tipo de evaluación'}</InputLabel>
          <Select
            value={formData.evaluationType}
            labelId={'label-evaluationType'}
            id={'select-evaluationType'}
            onChange={(e) => setFormValue(e.target.value, "evaluationType")}
            margin={"none"}
          >
            {
              evaluationTypeList?.map(item => (
                <MenuItem
                  className={"whiteText"}
                  key={item.key}
                  value={item.key}>
                  {item.value}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
        <FormControl style={{ width: "100%" }}>
          <InputLabel required id={'label-months'}>{'Antigüedad requerida para participar'}</InputLabel>
          <Select
            value={formData.months}
            labelId={'label-months'}
            id={'select-months'}
            onChange={(e) => setFormValue(e.target.value, "months")}
            margin={"none"}
          >
            {
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => (
                <MenuItem
                  className={"whiteText"}
                  key={item}
                  value={item}>
                  {`${item} ${item === 1 ? 'mes' : 'meses'}`}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
    </StepContainer>
  );
}

export default StepOne;