import React from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import ListAltIcon from '@material-ui/icons/ListAlt';

const TextContainer = ({ children }) => {
  return (
    <span style={{ fontWeight: 100, fontSize: 18 }}>
      {children}
    </span>
  )
}

const IconContainer = ({ children }) => {
  return (
    <span style={{ display: 'flex', justifyContent: 'center' }}>
      {children}
    </span>
  )
}

export const STATUS_TEXT = {
  E: (
    <TextContainer>
      Verifique que los ponderados de todas las <b>metas y objetivos</b> totalicen 100%, y lo mismo para los ponderados de las <b>competencias específicas del cargo</b> en caso de utilizarlas.
      <br />
      <br />
      Para verificar el 100% de los ponderados de <b>metas y objetivos</b> accede con el rol del empleador a: Gestión del desempeño / Metas y Objetivos y selecciona el período.
      <br />
      <br />
      Para verificar el 100% de los ponderados de las <b>competencias específicas del cargo</b> accede con el rol del empleador a: Gestión del desempeño / Definiciones de desempeño / Competencias específicas.
      <br />
      <br />
      También verifique que para todos los evaluados y para cada tipo de evaluación esté asignado el evaluador correspondiente. Considere que, desde los procesos de evaluación, puede eliminar a determinados evaluados en ciertos tipos de evaluaciones si así lo desea.
      <br />
      <br />
      Recuerde enviar el mensaje a todos los evaluadores indicando que la evaluación del desempeño <b>se pondrá en curso para que inicien con sus calificaciones</b>, para enviar el mensaje acceda con el rol de empleador a: Gestión del desempeño / Procesos de evaluación y utilice el siguiente botón para el proceso de evaluación seleccionado:
      <br />
      <br />
      <IconContainer>
        <CustomIconButton
          title={'Enviar mensaje'}
          type={'mail'}
        />
      </IconContainer>
      <br />
      Estado al cual puede regresar el proceso, si ya se encuentra en este estado: <b>Ninguno</b>.
      <br />
      Estado al cual puede avanzar el proceso, si ya se encuentra en este estado: <b>Calificada</b>.
    </TextContainer>
  ),
  F: (
    <TextContainer>
      Solo debe pasar el proceso a este estado si todas las calificaciones de desempeño se han completado. Acceda con el rol de empleador a Gestión del desempeño / Evaluaciones de desempeño y verifique <b>el progreso</b> de las calificaciones con este botón, el cual mostrará una grilla con los <b>estados</b> de las evaluaciones, los evaluados y sus evaluadores:
      <br />
      <br />
      <IconContainer>
        <CustomIconButton title={'Control del progreso'}>
          <ListAltIcon />
        </CustomIconButton>
      </IconContainer>
      <br />
      Una vez que visualice el estado de las evaluaciones, ordene las mismas haciendo clic en la columna <b>estado de la evaluación</b>, filtre todas las <b>pendientes</b>, que se destacan con color, seleccione a todos los registros con ese estado y envié el mensaje automático a los evaluadores el cual les solicitará que culminen con sus evaluaciones pendientes a la brevedad posible.
      <br />
      <br />
      Para enviar el mensaje automático desde la grilla utilice el siguiente botón:
      <br />
      <br />
      <IconContainer>
        <CustomIconButton
          title={'Enviar mensaje'}
          type={'mail'}
        />
      </IconContainer>
      <br />
      Solo en caso, que alguna evaluación esté pendiente y <b>no deba realizarse tal evaluación para el evaluado</b>, puede eliminarla desde Gestión del desempeño / Procesos de evaluación / Asignar colaboradores al proceso, utilizando el botón <b>eliminar</b>.
      <br />
      <br />
      Considere que si mantiene el proceso <b>en curso</b>, podrá de todos modos acceder al <b>resumen y resultado</b>, realizar la <b>calibración</b> y la entrega del <b>feedback</b> sin cambiar el estado del proceso. (Estos 2 últimos solo con nota final en las evaluaciones).
      <br />
      Por tal motivo, no es necesario pasar a <b>calificada</b> para poder acceder al resumen y resultado de las evaluaciones, calibrar o dar feedback.
      <br />
      <br />
      Estado al cual puede regresar el proceso, si ya se encuentra en este estado: <b>En curso</b>.
      <br />
      Estado al cual puede avanzar el proceso, si ya se encuentra en este estado: <b>En calibración</b>.
    </TextContainer>
  ),
  B: (
    <TextContainer>
      El estado de <b>calibración</b> es optativo.
      <br />
      <br />
      Sin embargo es altamente recomendable revisar determinadas evaluaciones (Conformando un comité entre Gestión de las Personas y algunos evaluadores) para verificar que las calificaciones realizadas a los evaluados hayan sido justas, equitativas y sin sesgos por parte de sus evaluadores, o bien debatir en el comité las evaluaciones que pudieron ser objetadas por los evaluados.
      <br />
      <br />
      Debe considerar que el comité de calibración tiene la capacidad de modificar las calificaciones de los evaluados si así lo determina.
      <br />
      <br />
      Estado al cual puede regresar el proceso, si ya se encuentra en este estado: <b>Calificada</b>.
      <br />
      Estado al cual puede avanzar el proceso, si ya se encuentra en este estado: <b>En feedback</b>.
    </TextContainer>
  ),
  K: (
    <TextContainer>
      Antes de pasar al este estado, recuerde crear <b>un nuevo período de metas y objetivos</b> para permitir que cuando el evaluador <b>entregue el feedback al evaluado</b>, pueda asignarle nuevas metas y objetivos para un nuevo período y de ese modo, dichas metas y objetivos, puedan ser gestionadas y posteriormente evaluadas en el próximo proceso de evaluación del desempeño.
      <br />
      <br />
      Antes de pasar a "En Feedback" verifique desde "Evaluaciones del desempeño" que todas las evaluaciones estén en estado "Realizadas".
      <br />
      <br />
      Considere que, si durante el <b>feedback</b> un evaluado objeta su evaluación, podrá recalibrar la evaluación cuestionada si fuera necesario.
      <br />
      <br />
      Recuerde enviar el mensaje a todos los evaluadores indicando que la evaluación del desempeño <b>ha pasado a la etapa de feedback y que deben realizar las entrevista de evaluación con cada uno de sus evaluados</b>. Para enviar el mensaje acceda con el rol de empleador a: Gestión del desempeño / Procesos de evaluación y utilice el siguiente botón para el proceso de evaluación seleccionado:
      <br />
      <br />
      <IconContainer>
        <CustomIconButton
          title={'Enviar mensaje'}
          type={'mail'}
        />
      </IconContainer>
      <br />
      Estado al cual puede regresar el proceso, si ya se encuentra en este estado: <b>En calibración</b>.
      <br />
      Estado al cual puede avanzar el proceso, si ya se encuentra en este estado: <b>En clasificación del talento</b>.
    </TextContainer>
  ),
  T: (
    <TextContainer>
      Avance a este estado para <b>clasificar el desempeño y potencial</b> en una matriz 9-Boxes.
      <br />
      <br />
      Estado al cual puede regresar el proceso, si ya se encuentra en este estado: <b>En feedback</b>.
      <br />
      Estado al cual puede avanzar el proceso, si ya se encuentra en este estado: <b>Cerrada</b>.
    </TextContainer>
  ),
  R: (
    <TextContainer>
      Cuando pase el proceso a este estado, la evaluación del desempeño quedará completamente finalizada y las calificaciones ya no podrán modificarse ni recalibrarse.
      <br />
      <br />
      Este estado será definitivo y todos los datos del proceso y sus calificaciones permanecerán para realizar gestión de información únicamente.
      <br />
      <br />
      Estado al cual puede regresar el proceso, si ya se encuentra en este estado: <b>En clasificación del talento</b>.
    </TextContainer>
  ),
}