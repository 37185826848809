import React, { useEffect, useState } from "react";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  deleteEvaluationProcessDefinitionAction,
  getEvaluationProcessDefinitionAction,
  getEvaluationProcessDefinitionCheckCollaboratorsAction,
} from "../actions";
import {
  getData,
  getIsLoading,
  getEvaluationTypeList,
  getFormList,
  getScoreTypeList,
  getObjectivePeriodList,
  getStatusList,
} from "../selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import paths from "@icarius-localization/paths";

const useEvaluationProcessDefinition = () => {

  const [formIsOpen, setFormIsOpen] = useState(false);
  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = useState(false);
  const [checkCollaboratorsDialogIsOpen, setCheckCollaboratorsDialogIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [mailDialogIsOpen, setMailDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector(getData);
  const isLoading = useSelector(getIsLoading);
  const evaluationTypeList = useSelector(getEvaluationTypeList);
  const formList = useSelector(getFormList);
  const scoreTypeList = useSelector(getScoreTypeList);
  const objectivePeriodList = useSelector(getObjectivePeriodList);
  const statusList = useSelector(getStatusList);

  useEffect(() => {
    dispatch(getEvaluationProcessDefinitionAction());
  }, [dispatch])

  const handleDelete = (document) => {
    dispatch(deleteEvaluationProcessDefinitionAction({ internalCode: document.internalCode }));
  }

  const handleOpenDeleteDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: '¿Esta seguro que desea eliminar el proceso?',
      onConfirm: () => dispatch(openDialogAction({
        title: getLocalizedString("atention"),
        msg: (
          <>
            Se eliminarán todos los datos relacionados con el proceso: red de evaluadores, calificaciones de las competencias, calificaciones de las metas y objetivos, el resultado final de las evaluaciones ya calificadas y la clasificación del potencial con 9-boxes
            <br />
            ¿Está seguro que desea continuar?
          </>
        ),
        onConfirm: () => handleDelete(selectedRows[0]),
      }))
    }));

  }

  const handleOpenCheckCollaboratorsDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    dispatch(getEvaluationProcessDefinitionCheckCollaboratorsAction(selectedRows[0].processCode))
      .then((resp) => {
        if (resp.status === 200) {
          setSelectedData(selectedRows[0]);
          setCheckCollaboratorsDialogIsOpen(true);
        }
      });
  }

  const handleCloseCheckCollaboratorsDialog = () => {
    setCheckCollaboratorsDialogIsOpen(false);
    setSelectedData(null);
  }


  const handleOpenCreateDialog = () => {
    setSelectedData(null);
    setFormIsOpen(true);
  }

  const handleOpenEditDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedData(selectedRows[0]);
    setFormIsOpen(true);
  }

  const handleCloseCreateEditDialog = () => {
    setFormIsOpen(false);
    setSelectedData(null);
  }

  const handleOpenDuplicateDialog = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    setSelectedData(selectedRows[0]);
    setDuplicateDialogIsOpen(true);
  }

  const handleCloseDuplicateDialog = () => {
    setDuplicateDialogIsOpen(false);
    setSelectedData(null);
  }

  const handleOpenAssign = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    history.push({
      pathname: paths.evaluationProcessDefinitionAssignment,
      search: `?processCode=${selectedRows[0].processCode}&processName=${selectedRows[0].processName}&processStatusString=${selectedRows[0].evaluationStatusString}&processStatus=${selectedRows[0].evaluationStatus}`,
    });
  }

  const handleOpenMailDialog = (gridRef, statusKey) => {
    let selectedRows = gridRef.api.getSelectedRows();
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar un registro', severity: "warning" }));
      return;
    }

    if (statusKey === 'E' && selectedRows[0].evaluationStatus !== 'E') {
      dispatch(openSnackbarAction({ msg: 'El registro seleccionado debe estar en estado "En curso"', severity: "warning" }));
      return;
    }

    if (statusKey === 'K' && selectedRows[0].evaluationStatus !== 'K') {
      dispatch(openSnackbarAction({ msg: 'El registro seleccionado debe estar en estado "En feedback"', severity: "warning" }));
      return;
    }

    setSelectedData(selectedRows[0]);
    setMailDialogIsOpen(true);
  }

  const handleCloseMailDialog = () => {
    setMailDialogIsOpen(false);
    setSelectedData(null);
  }

  const state = {
    formIsOpen,
    duplicateDialogIsOpen,
    selectedData,
    checkCollaboratorsDialogIsOpen,
    mailDialogIsOpen,
  }

  const handlers = {
    handleOpenDeleteDialog,
    handleOpenCreateDialog,
    handleOpenEditDialog,
    handleCloseCreateEditDialog,
    handleOpenDuplicateDialog,
    handleCloseDuplicateDialog,
    handleDelete,
    handleOpenCheckCollaboratorsDialog,
    handleCloseCheckCollaboratorsDialog,
    handleOpenAssign,
    handleOpenMailDialog,
    handleCloseMailDialog,
  }

  return {
    data,
    evaluationTypeList,
    formList,
    scoreTypeList,
    objectivePeriodList,
    statusList,
    isLoading,
    state,
    handlers,
  }
}

export default useEvaluationProcessDefinition;