import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentPublicationAction } from "../actions";
import { getLoading, getPublications } from "../selectors";
import { getAppColor } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { FormControlLabel, Grid, Switch, Typography } from "@material-ui/core";
import Publication from "./publication/publication";
import { RESET_STATE } from "../actionTypes";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import paths from "@icarius-localization/paths";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";

const typeNames = [
  "documentReceipts",
  "documentCertificates",
  "documentRequests",
  "documentExternal",
  "documentAssistance",
  "documentByRequest",
  "documentsGenerated",
]

const DocumentPublication = ({ history }) => {

  const [editIsOpen, setEditIsOpen] = useState(false);
  const [createIsOpen, setCreateIsOpen] = useState(false);
  const [filterBySignableIsOn, setFilterBySignableIsOn] = useState(true);

  const dispatch = useDispatch();

  const color = useSelector(getAppColor);
  const publications = useSelector(getPublications);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getDocumentPublicationAction());
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch])

  const getPublicationsIsEmpty = () => {
    if (!publications) return true;

    return publications.every((publicationArr) => {
      return (publicationArr.filter((publication) => filterBySignableIsOn ? publication.signable : !publication.signable)?.length === 0)
    });
  }

  const customItems = (
    <div style={{ display: 'flex' }}>
      <CustomIconButton
        title={getLocalizedString("goBack")}
        onClick={() => history.push(paths.documentsScreenSelector)}
        type={"goBack"}
      />
      <FormControlLabel
        control={
          <Switch
            disabled={createIsOpen || editIsOpen}
            checked={filterBySignableIsOn}
            value={filterBySignableIsOn}
            onChange={() => setFilterBySignableIsOn(prev => !prev)}
            color="primary"
          />
        }
        label={'Con firma digital'}
        labelPlacement="start"
      />
    </div>
  )

  return (
    <ABMLayout
      title={getLocalizedString("documentPublication")}
      isLoading={isLoading}
      showCreateButton={!createIsOpen}
      createButtonTitle={getLocalizedString("newDocumentPublication")}
      createButtonOnClick={() => setCreateIsOpen(true)}
      audioName={"Publicación de documentos"}
      audioTitle={getLocalizedString("documentPublication")}
      showNoData={getPublicationsIsEmpty()}
      noDataTitle={filterBySignableIsOn ? 'No existen documentos publicados con firma digital' : 'No existen documentos publicados sin firma digital'}
      noDataCreateTitle={getLocalizedString("newDocumentPublication")}
      noDataOnClick={() => setCreateIsOpen(true)}
      customItems={[customItems]}
      useCustomItemsEnd
    >
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <Publication
            data={null}
            color={color}
            closeNewPublication={() => setCreateIsOpen(false)}
            presetSignableValue={filterBySignableIsOn}
          />
        </Grid>
      }
      {
        publications && publications.map((publicationArr, index) => {
          const filteredArr = publicationArr.filter((publication) => filterBySignableIsOn ? publication.signable : !publication.signable);
          return (
            filteredArr.length > 0 ? (
              <div style={{ marginTop: 15, width: "100%" }} key={index}>
                <Typography style={{ marginBottom: "-10px", padding: "6px 16px", fontWeight: 400 }} className="whiteText">
                  {getLocalizedString(typeNames[index])}
                </Typography>
                <div style={{ padding: "0px 16px 6px 16px" }}>
                  <DialogTitleDivider />
                </div>
                {
                  filteredArr.map((publication) =>
                    <Grid container item xs={12} style={{ margin: "10px 0px" }} key={publication.internalCode}>
                      <Publication
                        data={publication}
                        color={color}
                        setEditIsOpen={setEditIsOpen}
                      />
                    </Grid>
                  )
                }
              </div>
            ) : null
          )
        })
      }
    </ABMLayout>
  )
}

export default DocumentPublication;