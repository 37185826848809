import React, { useState } from "react";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
    Grid,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    DialogContent,
    DialogActions,
    DialogTitle,
    Dialog,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";

const PickTemplateDialog = (props) => {

    const {
        open,
        templates,
        handleConfirm,
        handleClose,
    } = props;

    const [template, setTemplate] = useState(null);

    const onClickConfirm = () => {
        if (template) {
            handleConfirm(template);
            handleClose();
        }
    }

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            scroll={"paper"}
            fullWidth
            maxWidth={"sm"}>
            <DialogTitle>
                {"Atención"}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent style={{ paddingTop: 0 }}>
                <Grid container justify="flex-start" alignItems="center" item xs={12}>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel id="label" required={true}>{"Seleccione un documento"}</InputLabel>
                        <Select
                            labelId="label"
                            fullWidth
                            onChange={(e) => setTemplate(e.target.value)}
                            margin={"none"}
                            value={template}
                        >
                            {
                                templates.map(item => (
                                    <MenuItem
                                        className={"whiteText"}
                                        key={item.code}
                                        value={item.code}>
                                        {item.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
            </DialogContent>
            <DialogActions>
                <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
                <ButtonDialogAction onClick={onClickConfirm} isAccept text={getLocalizedString("accept")} />
            </DialogActions>
        </Dialog>
    );
}

export default PickTemplateDialog;