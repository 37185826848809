import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import {
  createDocumentTemplateAction,
  updateDocumentTemplateAction,
} from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { getTypeList } from "@icarius-pages/documentTemplates/selectors";
import VariableForm from "./variableForm";
import { yesNoArray } from "@icarius-utils/constants";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const { data, handleClose } = props;
  const dispatch = useDispatch();

  const typeList = useSelector(getTypeList);

  const create = (dataToSend) => {
    dispatch(createDocumentTemplateAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateDocumentTemplateAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* codigo */}
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={'Código'}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 8 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* clasificacion */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={`label-type`}>{'Tipo'}</InputLabel>
            <Select
              value={formData.type}
              labelId={`label-type`}
              id={`select-type`}
              onChange={(e) => setFormValue(e.target.value, "type")}
              margin={"none"}
            >
              {
                typeList && typeList.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={'Nombre'}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        {
          formData.type === "M" &&
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-requiresReceiverSelection`}>{'Requiere selección de destinatario'}</InputLabel>
              <Select
                value={formData.requiresReceiverSelection}
                labelId={`label-requiresReceiverSelection`}
                id={`select-type`}
                onChange={(e) => setFormValue(e.target.value, "requiresReceiverSelection")}
                margin={"none"}
              >
                {
                  yesNoArray.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        }
        <Grid container item alignItems="center" xs={12}> {/* variable form */}
          <VariableForm
            typeSelected={formData.type}
            typeString={typeList.find(type => type.key === formData.type)?.value}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default Form;
