import React, { useState, memo, useEffect, createRef } from "react";
import { useSelector } from "react-redux";
import {
    ConceptsValues,
    CompanyCost,
    Quantity,
    Analytics,
    BankDistribution,
    Description,
    TopBottom,
    Global,
    ConceptGroups,
    ThirdPartyPayment,
} from "./sections";
import {
    Grid,
    Divider,
    Button,
    Typography,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
} from "@material-ui/core";
import GridDialog from "./gridDialog";
import { getCurrencyLocalization } from "../../selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useScreenshot, createFileName } from "use-react-screenshot";
import CommonPage from "@icarius-common/commonPage";

const titleStyle = { marginTop: 5, fontWeight: 900, fontSize: 24, marginBottom: 5 };

const CustomExpansionPanelDetails = withStyles({
    root: {
        paddingTop: 0,
    },
})(ExpansionPanelDetails);

const CustomExpansionPanel = withStyles({
    root: {
        backgroundColor: "transparent",
        boxShadow: "none",
        width: '100%'
    },
})(ExpansionPanel);

const CustomExpansionPanelSummary = withStyles({
    root: {
        padding: 0
    },
})(ExpansionPanelSummary);

const DashboardPage = (props) => {

    const {
        title,
        desc,
        data,
        closeDashboard,
        companyColor,
        gridTheme,
    } = props;

    const [selectors, setSelectors] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [valuePerConceptSelectedCategory, setValuePerConceptSelectedCategory] = useState("");
    const [valuePerConceptSelectedSubcategory, setValuePerConceptSelectedSubcategory] = useState("");
    const [dialogIsOpen, setDialogIsOpen] = useState(false);
    const [gridData, setGridData] = useState(null);
    const [gridPath, setGridPath] = useState("");

    useEffect(() => {
        if (selectors.length === 0 && data?.companyCost?.selectors.length > 0) {
            setSelectors(data.companyCost.selectors);
            setSelectedCategory(data.companyCost.selectors[0]);
        }

        if (categories.length === 0 && Boolean(data?.conceptsValues?.categories)) {
            const keysCategories = Object.keys(data.conceptsValues.categories);
            setCategories(keysCategories);
            setValuePerConceptSelectedCategory(keysCategories[0]);
            setValuePerConceptSelectedSubcategory(data.conceptsValues.categories[keysCategories[0]][0])
        }
    }, [data, selectors, categories]);

    const currencyLocalization = useSelector(getCurrencyLocalization);

    const MySelect = ({ value, onChange, data }) => {
        return (
            <FormControl style={{ width: "100%" }}>
                <InputLabel id={`label-manager-new}`}>{""}</InputLabel>
                <Select
                    value={value}
                    labelId={`label-manager-new`}
                    id={`select-addressee-new`}
                    onChange={(e) => onChange(e.target.value)}
                    margin={"none"}
                >
                    {
                        data.map((item, index) =>
                            <MenuItem
                                className={"whiteText"}
                                key={index}
                                value={item}
                            >
                                {item}
                            </MenuItem>
                        )
                    }
                </Select>
            </FormControl>
        );
    }

    const setValuePerConceptCategory = (value) => {
        setValuePerConceptSelectedCategory(value);
        setValuePerConceptSelectedSubcategory(data.conceptsValues.categories[value][0]);
    }

    const setGridDataAndOpenDialog = (data, path) => {
        setGridData(data);
        setGridPath(path);
        setDialogIsOpen(true);
    }

    const NoIndicatorsForThisCategory = () => (
        <Grid item xs={12}>
            <Typography className="whiteText" style={{ fontSize: 20 }}>
                {"No existen indicadores en esta categoría"}
            </Typography>
        </Grid>
    )

    const LoadingContent = () => (
        <div className={"loader-center-container"} style={{ width: "10%", margin: "0 auto" }}>
            <CircularProgress style={{ color: companyColor || "#e52521" }} />
        </div>
    )

    const ref = createRef(null);

    const [, takeScreenShot] = useScreenshot({
        type: "image/png",
        quality: 1.0
    });

    const download = (image, { name = desc["Descripción del proceso"] || "proceso", extension = "png" } = {}) => {
        const a = document.createElement("a");
        a.href = image;
        a.download = createFileName(extension, name);
        a.click();
    };

    const goBackItem = () =>
        <CustomIconButton
            title={getLocalizedString("goBack")}
            onClick={closeDashboard}
            type={"goBack"}
        />

    const screenCaptureItem = () =>
        <CustomIconButton
            title={"Descargar captura"}
            onClick={() => takeScreenShot(ref.current).then(download)}
            type={"camera"}
        />

    return (
        <CommonPage
            title={title}
            menuItems={[goBackItem, screenCaptureItem]}
            isNotGridPage
        >
            <Grid container xs={12}>
                <Grid
                    ref={ref}
                    container
                    alignItems="center"
                    style={{
                        backgroundColor: "var(--mainBackgroundColor)",
                        padding: '0px 50px',
                        marginTop: 15,
                        marginBottom: 10,
                    }}
                >
                    <Grid container alignItems="center" item xs={12} style={{ marginBottom: 10 }}>
                        <Grid item xs={11}>
                            {
                                desc &&
                                <Description desc={desc} companyColor={companyColor} />
                            }
                        </Grid>
                    </Grid>
                    <CustomExpansionPanel>
                        <CustomExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className="whiteText" style={titleStyle}>
                                {"Cantidades"}
                            </Typography>
                        </CustomExpansionPanelSummary>
                        <CustomExpansionPanelDetails>
                            {
                                data?.quantity === undefined ?
                                    <LoadingContent />
                                    : (
                                        data?.quantity?.quantityBase?.length === 0 &&
                                            data?.quantity?.userKPIs?.length === 0 &&
                                            data?.quantity?.userGraphics?.length === 0 &&
                                            data?.quantity?.userClouds?.length === 0 ?
                                            <NoIndicatorsForThisCategory />
                                            :
                                            <Grid item xs={12}>
                                                <Quantity
                                                    setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                                                    data={data.quantity.quantityBase}
                                                    userClouds={data.quantity.userClouds}
                                                    userGraphics={data.quantity.userGraphics}
                                                    userKPIs={data.quantity.userKPIs}
                                                    gridTheme={gridTheme}
                                                />
                                            </Grid>
                                    )
                            }
                        </CustomExpansionPanelDetails>
                    </CustomExpansionPanel>
                    <div style={{ width: "100%" }}>
                        <Divider style={{ margin: "5px 0px", background: companyColor, height: 2 }} />
                    </div>

                    <CustomExpansionPanel>
                        <CustomExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className="whiteText" style={titleStyle}>
                                {"Analítico"}
                            </Typography>
                        </CustomExpansionPanelSummary>
                        <CustomExpansionPanelDetails>
                            {
                                data?.analytic === undefined ?
                                    <LoadingContent />
                                    : (
                                        data?.analytic?.length === 0 ?
                                            <NoIndicatorsForThisCategory />
                                            :
                                            <Grid item xs={12}>
                                                <Analytics
                                                    setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                                                    data={data.analytic}
                                                    companyColor={companyColor}
                                                    gridTheme={gridTheme}
                                                />
                                            </Grid>
                                    )
                            }
                        </CustomExpansionPanelDetails>
                    </CustomExpansionPanel>
                    <div style={{ width: "100%" }}>
                        <Divider style={{ margin: "5px 0px", background: companyColor, height: 2 }} />
                    </div>

                    <CustomExpansionPanel>
                        <CustomExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                            id="panel3a-header"
                        >
                            <Typography className="whiteText" style={titleStyle}>
                                {"Valores globales"}
                            </Typography>
                        </CustomExpansionPanelSummary>
                        <CustomExpansionPanelDetails>
                            {
                                data?.global === undefined ?
                                    <LoadingContent />
                                    : (
                                        data?.global?.length === 0 ?
                                            <NoIndicatorsForThisCategory />
                                            :
                                            <Grid item xs={12}>
                                                <Global
                                                    setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                                                    data={data.global}
                                                    companyColor={companyColor}
                                                    gridTheme={gridTheme}
                                                />
                                            </Grid>
                                    )
                            }
                        </CustomExpansionPanelDetails>
                    </CustomExpansionPanel>
                    <div style={{ width: "100%" }}>
                        <Divider style={{ margin: "5px 0px", background: companyColor, height: 2 }} />
                    </div>


                    <CustomExpansionPanel>
                        <CustomExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4a-content"
                            id="panel4a-header"
                        >
                            <Typography className="whiteText" style={titleStyle}>
                                {"Costo empresa"}
                            </Typography>
                        </CustomExpansionPanelSummary>
                        <CustomExpansionPanelDetails>
                            {
                                data?.companyCost === undefined ?
                                    <LoadingContent />
                                    : (
                                        data?.companyCost?.data?.length === 0 ?
                                            <NoIndicatorsForThisCategory />
                                            :
                                            <Grid item xs={12}>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item lg={2} xs={3} style={{ marginLeft: 10, marginBottom: 10 }}>
                                                        <MySelect
                                                            value={selectedCategory}
                                                            onChange={setSelectedCategory}
                                                            data={selectors}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <CompanyCost
                                                    setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                                                    data={data.companyCost}
                                                    companyColor={companyColor}
                                                    selectedCategory={selectedCategory}
                                                />
                                            </Grid>
                                    )
                            }
                        </CustomExpansionPanelDetails>
                    </CustomExpansionPanel>
                    <div style={{ width: "100%" }}>
                        <Divider style={{ margin: "5px 0px", background: companyColor, height: 2 }} />
                    </div>


                    <CustomExpansionPanel>
                        <CustomExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel5a-content"
                            id="panel5a-header"
                        >
                            <Typography className="whiteText" style={titleStyle}>
                                {"Valores por concepto"}
                            </Typography>
                        </CustomExpansionPanelSummary>
                        <CustomExpansionPanelDetails>
                            {
                                data?.conceptsValues === undefined ?
                                    <LoadingContent />
                                    : (
                                        data?.conceptsValues?.data?.length === 0 ?
                                            <NoIndicatorsForThisCategory />
                                            :
                                            <Grid item xs={12}>
                                                <Grid container direction="row" alignItems="center">
                                                    <Grid item lg={2} xs={3} style={{ marginLeft: 10, marginBottom: 10 }}>
                                                        <MySelect
                                                            value={valuePerConceptSelectedCategory}
                                                            onChange={setValuePerConceptCategory}
                                                            data={categories}
                                                        />
                                                    </Grid>
                                                    <Grid item lg={2} xs={3} style={{ marginLeft: 10, marginBottom: 10 }}>
                                                        {
                                                            valuePerConceptSelectedCategory && <MySelect
                                                                value={valuePerConceptSelectedSubcategory}
                                                                onChange={setValuePerConceptSelectedSubcategory}
                                                                data={data.conceptsValues.categories[valuePerConceptSelectedCategory]}
                                                            />
                                                        }
                                                    </Grid>
                                                </Grid>
                                                {
                                                    valuePerConceptSelectedCategory && valuePerConceptSelectedSubcategory &&
                                                    <ConceptsValues
                                                        setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                                                        data={{
                                                            data: data.conceptsValues.data[valuePerConceptSelectedCategory][valuePerConceptSelectedSubcategory],
                                                            title: valuePerConceptSelectedSubcategory
                                                        }}
                                                        companyColor={companyColor}
                                                    />
                                                }
                                            </Grid>
                                    )
                            }
                        </CustomExpansionPanelDetails>
                    </CustomExpansionPanel>
                    <div style={{ width: "100%" }}>
                        <Divider style={{ margin: "5px 0px", background: companyColor, height: 2 }} />
                    </div>


                    <CustomExpansionPanel>
                        <CustomExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel6a-content"
                            id="panel6a-header"
                        >
                            <Typography className="whiteText" style={titleStyle}>
                                {"Conjunto de conceptos"}
                            </Typography>
                        </CustomExpansionPanelSummary>
                        <CustomExpansionPanelDetails>
                            {
                                data?.conceptGroups === undefined ?
                                    <LoadingContent />
                                    : (
                                        data?.conceptGroups?.length === 0 ?
                                            <NoIndicatorsForThisCategory />
                                            :
                                            <ConceptGroups
                                                data={data.conceptGroups}
                                                processName={desc["Descripción del proceso"]}
                                                companyColor={companyColor}
                                            />
                                    )
                            }
                        </CustomExpansionPanelDetails>
                    </CustomExpansionPanel>
                    <div style={{ width: "100%" }}>
                        <Divider style={{ margin: "5px 0px", background: companyColor, height: 2 }} />
                    </div>



                    <CustomExpansionPanel>
                        <CustomExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel7a-content"
                            id="panel7a-header"
                        >
                            <Typography className="whiteText" style={titleStyle}>
                                {"Top & Bottom"}
                            </Typography>
                        </CustomExpansionPanelSummary>
                        <CustomExpansionPanelDetails>
                            {
                                data?.topBottom === undefined ?
                                    <LoadingContent />
                                    : (
                                        data?.topBottom?.length === 0 ?
                                            <NoIndicatorsForThisCategory />
                                            :
                                            <TopBottom
                                                setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                                                data={data.topBottom}
                                                companyColor={companyColor}
                                            />
                                    )
                            }
                        </CustomExpansionPanelDetails>
                    </CustomExpansionPanel>
                    <div style={{ width: "100%" }}>
                        <Divider style={{ margin: "5px 0px", background: companyColor, height: 2 }} />
                    </div>

                    <CustomExpansionPanel>
                        <CustomExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel8a-content"
                            id="panel8a-header"
                        >
                            <Typography className="whiteText" style={titleStyle}>
                                {"Distribución por banco"}
                            </Typography>
                        </CustomExpansionPanelSummary>
                        <CustomExpansionPanelDetails>
                            {
                                data?.bankDistribution === undefined ?
                                    <LoadingContent />
                                    : (
                                        data?.bankDistribution?.data?.length === 0 ?
                                            <NoIndicatorsForThisCategory />
                                            :
                                            <BankDistribution
                                                setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                                                data={data.bankDistribution}
                                                companyColor={companyColor}
                                                gridTheme={gridTheme}
                                            />
                                    )
                            }
                        </CustomExpansionPanelDetails>
                    </CustomExpansionPanel>
                    <div style={{ width: "100%" }}>
                        <Divider style={{ margin: "5px 0px", background: companyColor, height: 2 }} />
                    </div>

                    <CustomExpansionPanel>
                        <CustomExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel9a-content"
                            id="panel9a-header"
                        >
                            <Typography className="whiteText" style={titleStyle}>
                                {"Pago a terceros"}
                            </Typography>
                        </CustomExpansionPanelSummary>
                        <CustomExpansionPanelDetails>
                            {
                                data?.thirdPartyPayment === undefined ?
                                    <LoadingContent />
                                    : (
                                        data?.thirdPartyPayment?.data?.length === 0 ?
                                            <NoIndicatorsForThisCategory />
                                            :
                                            <ThirdPartyPayment
                                                setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                                                data={data.thirdPartyPayment}
                                                companyColor={companyColor}
                                                gridTheme={gridTheme}
                                            />
                                    )
                            }
                        </CustomExpansionPanelDetails>
                    </CustomExpansionPanel>
                    <div style={{ width: "100%" }}>
                        <Divider style={{ margin: "5px 0px", background: companyColor, height: 2 }} />
                    </div>

                    <CustomExpansionPanel>
                        <CustomExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel10a-content"
                            id="panel10a-header"
                        >
                            <Typography className="whiteText" style={titleStyle}>
                                {"Planilla de conceptos"}
                            </Typography>
                        </CustomExpansionPanelSummary>
                        <CustomExpansionPanelDetails>
                            {
                                data?.conceptsGrid === undefined ?
                                    <LoadingContent />
                                    : (
                                        data?.conceptsGrid?.length === 0 ?
                                            <NoIndicatorsForThisCategory />
                                            :
                                            <Grid container justify="center" alignItems="center" item xs={12}>
                                                <Button
                                                    style={{ width: "400px", borderRadius: "6px", height: "52px", backgroundColor: companyColor }}
                                                    disableRipple={true}
                                                    variant={"contained"}
                                                    className={"whiteText dashboardConcepts"}
                                                    onClick={() => setGridDataAndOpenDialog(data?.conceptsGrid, "conceptsGrid")}
                                                >
                                                    <Typography style={{ textTransform: "none", fontSize: "20px", fontWeight: 400 }} variant={"button"}>
                                                        {"Planilla de conceptos"}
                                                    </Typography>
                                                </Button>
                                            </Grid>
                                    )
                            }
                        </CustomExpansionPanelDetails>
                    </CustomExpansionPanel>
                    {
                        dialogIsOpen &&
                        <GridDialog
                            currencyLocalization={currencyLocalization}
                            calcProcessName={desc["Descripción del proceso"]}
                            gridPath={gridPath}
                            data={gridData}
                            handleClose={() => setDialogIsOpen(false)}
                            open={dialogIsOpen}
                        />
                    }
                </Grid>
            </Grid>
        </CommonPage>
    )
};

export default memo(DashboardPage);
