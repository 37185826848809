
import React from 'react';
import { Card, Grid, Typography } from "@material-ui/core";
import { IMAGES_ENDPOINT_NO_STATIC } from "@icarius-connection/endpoints";
import { formatNumberOrReturnUndefined } from '@icarius-utils/format';
const MILLON = 1000000;
const HUNDRED_MILLON = MILLON * 100;

const isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function BXGraph({ isConceptValue, data, companyColor, gridTheme, setGridDataAndOpenDialog, path }) {
    let value = data.value;
    let isAboveMillion = false;
    let isAboveMillionSubtitle = false;
    if (parseFloat(data.value) >= HUNDRED_MILLON) {
        value = parseFloat(data.value) / MILLON;
        isAboveMillion = true;
    }

    value = isConceptValue ? formatNumberOrReturnUndefined(value, 2, 2) : formatNumberOrReturnUndefined(value, 0, 2);

    let subtitle = data.subtitle;
    if (!data.image && !isConceptValue && isNumeric(data.subtitle)) {
        if (parseFloat(data.subtitle) >= HUNDRED_MILLON) {
            subtitle = parseFloat(data.subtitle) / MILLON;
            isAboveMillionSubtitle = true;
        }
        subtitle = formatNumberOrReturnUndefined(subtitle, 2, 2)
    }

    return (
        <Card
            className="w-full rounded-20 shadow"
            style={{ minHeight: 200, cursor: "pointer", height: "100%", display: "flex" }}
            onClick={() => data.dataGrid && setGridDataAndOpenDialog(data.dataGrid, path)}
        >
            <Grid container direction="column" alignItems="center" justify="center">
                <Typography style={{ fontWeight: 900, fontSize: 36, color: data?.color }}>
                    {`${value}${isAboveMillion ? " M" : ""}`}
                </Typography>
                {
                    data.image &&
                    <div className="icon-container-kpi">
                        <img
                            alt=""
                            src={`${IMAGES_ENDPOINT_NO_STATIC}images/KPI/${gridTheme}/${data.image}`}
                            style={{ maxWidth: "50px", maxHeight: "50px" }}
                        />
                    </div>
                }
                <Typography style={{ fontWeight: 500, fontSize: 24, textAlign: "center" }}>
                    {`${subtitle}${isAboveMillionSubtitle ? " M" : ""}`}
                </Typography>
                <Typography style={{ color: companyColor, fontWeight: 500, fontSize: 24, textAlign: "center" }}>
                    {data.title}
                </Typography>
            </Grid>
        </Card>
    )
}


export default BXGraph;