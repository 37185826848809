import React from "react";
import { DialogContent } from "@material-ui/core";
import {
  dateClassRule,
  dateFormatter,
  dateValueGetter,
  dateFilterParams,
  dateComparator,
} from "@icarius-table/date";
import {
  numberComparatorMax2,
  numberFormatterMin2,
  numberFilterParams,
} from "@icarius-table/number";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import CommonPage from "@icarius-common/commonPage";

const GridDialogContent = (props) => {

  const {
    data,
    gridPath,
    calcProcessName,
    currencyLocalization,
  } = props;

  const isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  const getColumnDef = () => {
    // Primero me fijo si ya tengo columnas asignadas
    let columnDefs = getColumnDefByPage(gridPath);
    if (data.length > 0) {
      Object.keys(data[0]).forEach(key => {
        let item = {
          headerName: key,
          field: key,
          filter: "agTextColumnFilter",
        };

        if (Object.keys(data[0]).length > columnDefs.length && !columnDefs.some(col => col.field === key)) {
          if ([
            "Tipo de funcionario",
            "Tipo de contrato",
            "Gerencia",
            "Departamento",
            "Sección",
            "Referencia",
            "Banco",
            "Obra o Faena",
            "Centro de costo",
          ].includes(key)) {
            item = {
              headerName: key,
              field: key,
              filter: "agSetColumnFilter",
            };
          } else if (key === "Fecha de contratación" || key === "Fecha de egreso") {
            item = {
              ...item,
              comparator: dateComparator,
              filterParams: dateFilterParams,
              cellClassRules: dateClassRule,
              valueFormatter: dateFormatter,
              valueGetter: dateValueGetter,
              cellClass: "dateFormat",
            }
          } else if (isNumeric(data[0][key])) {
            item = {
              ...item,
              comparator: numberComparatorMax2,
              filterParams: numberFilterParams,
              chartDataType: "series",
              cellClass: "currencyValue",
              valueFormatter: numberFormatterMin2,
              enableValue: true,
              allowedAggFuncs: ["sum", "min", "max", "avg"],
            }
          }
        }

        if (!columnDefs.some(col => col.field === key)) {
          columnDefs.push(item);
        }
      });
    }

    return columnDefs;
  }

  return (
    <DialogContent>
      <CommonPage
        gridTitle={gridPath === "conceptsGrid" ? `Planilla de conceptos: ${calcProcessName}` : `Proceso de cálculo: ${calcProcessName}`}
        ownColumnDef={getColumnDef()}
        rowData={data}
        locale={currencyLocalization}
        gridHeight={'60vh'}
        hasSelectAll
        hasExpand
        hasHelp
        ignoreProcessesbyPath
      />
    </DialogContent>
  );
}

export default GridDialogContent;