
import React, { memo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, Typography, useMediaQuery } from "@material-ui/core";
import { formatNumberOrReturnUndefined } from '@icarius-utils/format';

function GHGraph({ data, customChartColor, setGridDataAndOpenDialog, gridTheme, path }) {
    const [unit, setUnit] = useState("Unidades");

    const myData = {
        id: 'GVGraph',
        series: [
            {
                data: Object.values(data.data).map(item => item !== 0 ? (unit === "Millones" ? parseFloat(item / 1000000).toFixed(2) : (unit === "Miles" ? parseFloat(item / 1000).toFixed(2) : parseFloat(item).toFixed(2))) : 0)
            }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                    show: false
                }
            },
            grid: { show: false },
            colors: customChartColor ? [customChartColor] : ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'],
            plotOptions: {
                bar: {
                    columnWidth: '45%',
                    distributed: true,
                    horizontal: true,
                }
            },
            xaxis: {
                categories: Object.keys(data.data).map(item => item.split(" ")),
                labels: {
                    rotate: 0,
                    style: {
                        fontSize: '12px',
                        colors: gridTheme === "dark" ? 'white' : 'black'
                    },
                    minHeight: 70,
                }
            },
            yaxis: {
                labels: {
                    rotate: 0,
                    style: {
                        fontSize: '12px',
                        colors: gridTheme === "dark" ? 'white' : 'black'
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            states: {
                active: {
                    filter: {
                        type: 'none' /* none, lighten, darken */
                    }
                }
            },
            tooltip: {
                marker: {
                    show: false,
                },
                followCursor: true,
                theme: 'dark',
                y: {
                    formatter: function (val) {
                        return formatNumberOrReturnUndefined(val, 0, 2)
                    },
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
            }
        }
    };

    const basicUnitLabel = { fontWeight: 500, fontSize: 16 };
    const matchesLessThan960 = useMediaQuery(`(max-width:960px)`);

    return (
        <Card className="w-full rounded-20 shadow" >
            <div
                style={
                    matchesLessThan960 ?
                        { padding: "15px 0px 5px 15px", justifyContent: "space-between" }
                        : { padding: "15px 0px 5px 15px", display: "flex", justifyContent: "space-between" }
                }
            >
                <div style={{ float: "left" }}>
                    <Typography style={{ fontWeight: 700, fontSize: 20 }}>{data.title}</Typography>
                    <Typography style={{ fontWeight: 700, fontSize: 20 }}>{data.subtitle}</Typography>
                </div>
                <div style={{ float: "right", display: "flex", gap: 10, marginRight: 20 }}>
                    <Typography
                        onClick={() => setUnit("Unidades")}
                        style={unit === "Unidades" ? { ...basicUnitLabel, cursor: "pointer" } : { ...basicUnitLabel, cursor: "pointer", opacity: 0.5 }}
                    >
                        {"Unidades"}
                    </Typography>
                    <Typography
                        onClick={() => setUnit("Miles")}
                        style={unit === "Miles" ? { ...basicUnitLabel, cursor: "pointer" } : { ...basicUnitLabel, cursor: "pointer", opacity: 0.5 }}
                    >
                        {"Miles"}
                    </Typography>
                    <Typography
                        onClick={() => setUnit("Millones")}
                        style={unit === "Millones" ? { ...basicUnitLabel, cursor: "pointer" } : { ...basicUnitLabel, cursor: "pointer", opacity: 0.5 }}
                    >
                        {"Millones"}
                    </Typography>
                </div>
            </div>
            <div className="h-96 w-100-p" style={{ cursor: "pointer" }} onClick={() => data.dataGrid && setGridDataAndOpenDialog(data.dataGrid, path)}>
                <ReactApexChart
                    options={myData.options}
                    series={myData.series}
                    type={myData.options.chart.type}
                    height={myData.options.chart.height}
                />
            </div>
        </Card>
    );
}

function preventRender() {
    return true;
}

export default memo(GHGraph, preventRender);
