import React, { memo, useMemo } from 'react';
import { Card, Typography } from "@material-ui/core";
import { TagCloud } from 'react-tagcloud'

function CloudGraph({ data, setGridDataAndOpenDialog, path }) {
    const words = useMemo(() => {
        let formattedData = [];
        data.data.forEach(row => formattedData.push({ value: row.text, count: row.value }));
        return formattedData;
    }, [data]);

    return (
        <Card
            className="w-full rounded-20 shadow"
            style={{ cursor: "pointer" }}
            onClick={() => data.dataGrid && setGridDataAndOpenDialog(data.dataGrid, path)}
        >
            <div style={{ padding: "15px 0px 5px 15px" }}>
                <Typography style={{ fontWeight: 500, fontSize: 24 }}>{data.title}</Typography>
            </div>
            <div className="h-96 w-100-p" >
                <div
                    style={{ width: "100%", margin: '0 auto', padding: 20, cursor: "pointer" }}
                    onClick={() => data.dataGrid && setGridDataAndOpenDialog(data.dataGrid, path)}
                >
                    <TagCloud
                        disableRandomColor
                        tags={words}
                        style={{ textAlign: 'center' }}
                        minSize={24}
                        maxSize={56}
                    />
                </div>
            </div>
        </Card>
    );
}

function preventRender() {
    return true;
}

export default memo(CloudGraph, preventRender);
