import React, { useEffect } from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import useEvaluationProcessDefinitionAssignment from "./useEvaluationProcessDefinitionAssignment";
import CreateDialog from "./createDialog";
import UploadFileDialog from "./uploadFileDialog";
import EvaluatorDialog from "./evaluatorDialog";
import StyledHelpMenu from "@icarius-common/styledMenu";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";

const EDITABLE_STATUS_LIST = ['E', 'C'];

const EvaluationProcessDefinitionAssignment = ({ history, location }) => {

  const processCode = new URLSearchParams(location.search).get("processCode");
  const processName = new URLSearchParams(location.search).get("processName");
  const processStatusString = new URLSearchParams(location.search).get("processStatusString");
  const processStatus = new URLSearchParams(location.search).get("processStatus");

  useEffect(() => {
    if (!processCode) {
      history.push(paths.evaluationProcessDefinition);
    }
  }, [processCode, history])

  const {
    isLoading,
    data,
    colaboratorsList,
    evaluatorsList,
    state,
    handlers,
  } = useEvaluationProcessDefinitionAssignment(processCode);

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => history.push(paths.evaluationProcessDefinition)}
      type={"goBack"}
    />
  )

  const createButton = () => EDITABLE_STATUS_LIST.includes(processStatus) && (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const exportButton = () => (
    <CustomIconButton
      title={"Exportar plantilla para importación"}
      onClick={handlers.handleDownloadStructure}
      type={"download"}
    />
  )

  const importButton = () => EDITABLE_STATUS_LIST.includes(processStatus) && (
    <CustomIconButton
      title={"Importar"}
      onClick={handlers.handleOpenUploadFileDialogIsOpen}
      type={"upload"}
    />
  )

  const evaluatorButton = (gridRef) => EDITABLE_STATUS_LIST.includes(processStatus) && (
    <>
      <CustomIconButton
        title={'Asignar/Reemplazar evaluador'}
        onClick={(e) => handlers.handleSetMassiveMenuAnchor(e.currentTarget)}
      >
        <SupervisorAccountIcon />
      </CustomIconButton>
      <StyledHelpMenu
        anchorEl={state.massiveMenuAnchor}
        isOpen={Boolean(state.massiveMenuAnchor)}
        onClose={() => handlers.handleSetMassiveMenuAnchor(null)}
      >
        <MenuItemWithIcon
          text={'Asignar evaluador'}
          onClick={() => handlers.handleOpenEvaluatorDialog(gridRef, false)}
        />
        <MenuItemWithIcon
          text={'Reemplazar evaluador'}
          onClick={() => handlers.handleOpenEvaluatorDialog(gridRef, true)}
        />
      </StyledHelpMenu>
    </>
  )

  return (
    <CommonPage
      isLoading={isLoading}
      title={`Asignar colaboradores al proceso: ${processName} (${processStatusString})`}
      gridTitle={`Asignar colaboradores al proceso: ${processName} (${processStatusString})`}
      columnDefPage={paths.evaluationProcessDefinitionAssignment}
      rowData={data}
      menuItems={[goBackButton, createButton, deleteButton, evaluatorButton, exportButton, importButton]}
      hasExpand
      hasHelp
    >
      {
        state.createDialogIsOpen &&
        <CreateDialog
          open={state.createDialogIsOpen}
          colaborators={colaboratorsList}
          planCode={processCode}
          processCode={processCode}
          processName={processName}
          processStatusString={processStatusString}
          loading={isLoading}
          data={data}
          planName={processName}
          handleClose={handlers.handleCloseCreateDialog}
        />
      }
      {
        state.uploadFileDialogIsOpen &&
        <UploadFileDialog
          open={state.uploadFileDialogIsOpen}
          processCode={processCode}
          isLoading={isLoading}
          handleClose={handlers.handleCloseUploadFileDialogIsOpen}
        />
      }
      {
        state.evaluatorDialogIsOpen &&
        <EvaluatorDialog
          isReplace={state.replaceEvaluatorDialogIsOpen}
          open={state.evaluatorDialogIsOpen}
          data={state.evaluatorData}
          people={evaluatorsList}
          handleChange={handlers.handleChangeEvaluator}
          handleClose={handlers.handleCloseEvaluatorDialog}
        />
      }
    </CommonPage>
  )
}

export default EvaluationProcessDefinitionAssignment;