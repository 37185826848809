import * as actionTypes from "./actionTypes";
import {
  getEvaluationProcessDefinitionAssignmentAPI,
  createEvaluationProcessDefinitionAssignmentAPI,
  deleteEvaluationProcessDefinitionAssignmentAPI,
  downloadEvaluationProcessDefinitionAssignmentAPI,
  uploadEvaluationProcessDefinitionAssignmentAPI,
  getEvaluatorsForEvaluationProcessDefinitionAssignmentAPI,
  setEvaluatorForEvaluationProcessDefinitionAssignmentAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";
import { downloadBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "DUPLICATED_DATA":
      errorString = "Uno de los colaboradores ingresados ya está asociado al proceso";
      break;
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getEvaluationProcessDefinitionAssignmentAction = (processCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getEvaluationProcessDefinitionAssignmentAPI(`?processCode=${processCode}`);

    let data = response.data && response.data.data;
    let collaboratorsList = response.data && response.data.collaboratorsList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        collaboratorsList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createEvaluationProcessDefinitionAssignmentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createEvaluationProcessDefinitionAssignmentAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Registro creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteEvaluationProcessDefinitionAssignmentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteEvaluationProcessDefinitionAssignmentAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Registro eliminado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const downloadEvaluationProcessDefinitionAssignmentAction = (processCode) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD });
  try {
    let response = await downloadEvaluationProcessDefinitionAssignmentAPI({ processCode });
    let title = response.headers["x-content-namefile"];

    if (response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_FULFILLED,
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_REJECTED });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const uploadEvaluationProcessDefinitionAssignmentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.UPLOAD });
  try {
    let formData = new FormData();
    formData.append('file', dataToSend.file);
    formData.append('processCode', dataToSend.processCode);

    let response = await uploadEvaluationProcessDefinitionAssignmentAPI(formData);
    let title = response.headers["x-content-namefile"];
    let hasErrors = title && title.includes("Errores-");
    if (title) {
      title = title.replace("Errores-", "");
    }
    if (response.status !== 200) {
      dispatch({ type: actionTypes.UPLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.UPLOAD_FULFILLED,
    });

    if (title) {
      downloadBlob(response.data, getFileExtension(title), getFileName(title));

      dispatch(openSnackbarAction({
        msg: 'Importación finalizada, verifique el archivo para ver el resultado',
        severity: !hasErrors ? "success" : "warning",
        duration: null
      }));

      return response;
    }

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.UPLOAD_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getEvaluatorsForEvaluationProcessDefinitionAssignmentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_EVALUATORS });
  try {
    let response = await getEvaluatorsForEvaluationProcessDefinitionAssignmentAPI(dataToSend);

    let evaluatorsList = response.data && response.data.evaluatorsList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_EVALUATORS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_EVALUATORS_FULFILLED,
      payload: {
        evaluatorsList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_EVALUATORS_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const setEvaluatorForEvaluationProcessDefinitionAssignmentAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.SET_EVALUATOR });
  try {
    let response = await setEvaluatorForEvaluationProcessDefinitionAssignmentAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.SET_EVALUATOR_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.SET_EVALUATOR_FULFILLED,
      payload: {
        data,
      },
    });

    dispatch(openSnackbarAction({ msg: `Se ha(n) ${dataToSend.replace ? "reemplazado" : "asignado"} ${dataToSend.collaborators.length} evaluador(es)`, severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.SET_EVALUATOR_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};