
import React, { memo, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, Typography, useMediaQuery } from "@material-ui/core";
import { formatNumberOrReturnUndefined } from '@icarius-utils/format';

function GCGraph({ data, customChartColor, setGridDataAndOpenDialog, gridTheme, path }) {
    const [unit, setUnit] = useState("Unidades");
    const formattedData = data?.data?.series.map(serie => {
        serie = { ...serie, data: serie.data.map(item => item !== 0 ? (unit === "Millones" ? parseFloat(item / 1000000).toFixed(2) : (unit === "Miles" ? parseFloat(item / 1000).toFixed(2) : parseFloat(item).toFixed(2))) : 0) }
        return serie;
    });


    const myData = {
        id: 'GCGraph',
        series: formattedData || [],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                    show: false
                }
            },
            fill: {
                opacity: 1
            },
            dataLabels: {
                enabled: true,
                style: {
                    colors: gridTheme === "dark" ? ["#FFF"] : ["#000"]
                },
                formatter: function (val, opts) {
                    return formatNumberOrReturnUndefined(val, 2, 2)
                },
                offsetY: -20
            },
            grid: { show: false },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded',
                    dataLabels: {
                        position: 'top'
                    }
                },
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: data?.data?.categories.map(item => item.split(" ")) || [],
                labels: {
                    rotate: 0,
                    style: {
                        fontSize: '12px',
                        colors: gridTheme === "dark" ? 'white' : 'black'
                    },
                    minHeight: 70,
                }
            },
            yaxis: {
                labels: {
                    rotate: 0,
                    style: {
                        fontSize: '12px',
                        colors: gridTheme === "dark" ? 'white' : 'black'
                    }
                }
            },
            legend: {
                labels: {
                    colors: gridTheme === "dark" ? 'white' : 'black'
                }
            },
            tooltip: {
                marker: {
                    show: false,
                },
                followCursor: true,
                theme: 'dark',
                y: {
                    formatter: function (val) {
                        return formatNumberOrReturnUndefined(val, 2, 2)
                    },
                    title: {
                        formatter: function (seriesName) {
                            return `${seriesName}:`
                        }
                    }
                },
            }
        }
    };
    const basicUnitLabel = { fontWeight: 500, fontSize: 16 };
    const matchesLessThan960 = useMediaQuery(`(max-width:960px)`);

    return (
        <Card className="w-full rounded-20 shadow" >
            <div
                style={
                    matchesLessThan960 ?
                        { padding: "15px 0px 5px 15px", justifyContent: "space-between" }
                        : { padding: "15px 0px 5px 15px", display: "flex", justifyContent: "space-between" }
                }
            >
                <div style={{ float: "left" }}>
                    <Typography style={{ fontWeight: 700, fontSize: 20 }}>{data.title}</Typography>
                    <Typography style={{ fontWeight: 700, fontSize: 20 }}>{data.subtitle}</Typography>
                </div>
                <div style={{ float: "right", display: "flex", gap: 10, marginRight: 20 }}>
                    <Typography
                        onClick={() => setUnit("Unidades")}
                        style={unit === "Unidades" ? { ...basicUnitLabel, cursor: "pointer" } : { ...basicUnitLabel, cursor: "pointer", opacity: 0.5 }}
                    >
                        {"Unidades"}
                    </Typography>
                    <Typography
                        onClick={() => setUnit("Miles")}
                        style={unit === "Miles" ? { ...basicUnitLabel, cursor: "pointer" } : { ...basicUnitLabel, cursor: "pointer", opacity: 0.5 }}
                    >
                        {"Miles"}
                    </Typography>
                    <Typography
                        onClick={() => setUnit("Millones")}
                        style={unit === "Millones" ? { ...basicUnitLabel, cursor: "pointer" } : { ...basicUnitLabel, cursor: "pointer", opacity: 0.5 }}
                    >
                        {"Millones"}
                    </Typography>
                </div>
            </div>
            <div className="h-96 w-100-p" style={{ cursor: "pointer" }} onClick={() => data.dataGrid && setGridDataAndOpenDialog(data.dataGrid, path)}>
                <ReactApexChart
                    options={myData.options}
                    series={myData.series}
                    type={myData.options.chart.type}
                    height={myData.options.chart.height}
                />
            </div>
        </Card>
    );
}
function preventRender() {
    return true;
}

export default memo(GCGraph, preventRender);
