import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { TextField, Grid } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createEntryExitTypeAction, modifyEntryExitTypeAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";
import { TypeContext } from "../index";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const { country, data, handleClose } = props;
  const { type, getTypeName } = useContext(TypeContext);
  const dispatch = useDispatch();

  const create = (data) => {
    const dataToSend = { ...data, type };
    dispatch(createEntryExitTypeAction(dataToSend, type))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (data) => {
    const dataToSend = { ...data, type };
    dispatch(modifyEntryExitTypeAction(dataToSend, type))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleForm(country, type, data, create, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* codigo */}
          <TextField
            disabled={!Boolean(isCreate)}
            required
            fullWidth
            margin={"none"}
            label={`Código del tipo de ${getTypeName()}`}
            onChange={(e) => setFormValue(e.target.value, "code")}
            value={formData.code}
            inputProps={{ maxLength: 8 }}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* nombre */}
          <TextField
            required
            fullWidth
            margin={"none"}
            label={`Nombre del tipo de ${getTypeName()}`}
            value={formData.name}
            onChange={(e) => setFormValue(e.target.value, "name")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        {
          Boolean(type === "E") &&
          <>
            <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* descripcion */}
              <TextField
                required
                fullWidth
                margin={"none"}
                label={'Texto descriptivo para contratos y otros'}
                onChange={(e) => setFormValue(e.target.value, "description")}
                value={formData.description}
                inputProps={{ maxLength: 600 }}
              />
            </Grid>
            {
              country === "CL" &&
              <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}> {/* codigo libro */}
                <TextField
                  fullWidth
                  margin={"none"}
                  label={'Código para libro'}
                  type="number"
                  value={formData.bookCode}
                  onChange={(e) => setFormValue(e.target.value.substring(0, 2), "bookCode")}
                  inputProps={{ min: 0, max: 99, maxLength: 2 }}
                  onBlur={(e) => e.target.value = e.target.value.substring(0, 2)}
                />
              </Grid>
            }
          </>
        }
      </Grid>
      <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
        <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
      </Grid>
    </Grid>
  );
}

export default Form;
