
import React, { memo } from 'react';
import { Card, Grid, Typography, useMediaQuery } from "@material-ui/core";
import { Doughnut } from 'react-chartjs-2';
import PercentageList from "./percentageList";

function GDGraph({ data, setGridDataAndOpenDialog, chartColors, path }) {
    const dataValues = Object.values(data.data);
    const sum = dataValues.reduce((previousValue, currentValue) => parseFloat(previousValue) + parseFloat(currentValue));

    const matchesLessThan600 = useMediaQuery(`(max-width:600px)`);
    const matchesLessThan1000 = useMediaQuery(`(max-width:1000px)`);

    const chartData = {
        labels: Object.keys(data.data),
        datasets: [
            {
                data: dataValues,
                backgroundColor: chartColors,
                borderColor: chartColors,
                borderWidth: 1,
            },
        ],
    }

    const getPercentage = (index, countArray) => {
        return (parseFloat(countArray[index]) * 100 / sum).toFixed(2);
    }

    const chartOptions = {
        legend: {
            display: false,
        },
        tooltips: {
            callbacks: {
                title: function (tooltipItem, data) {
                    return data['labels'][tooltipItem[0]['index']];
                },
                label: function (tooltipItem, data) {
                    return `${getPercentage(tooltipItem['index'], dataValues)}%`;
                },
            },
        }
    }


    const width = matchesLessThan1000 ? matchesLessThan600 ? 70 : 140 : 250;

    return (
        <Card
            className="w-full rounded-20 shadow"
            style={{ cursor: "pointer", height: "100%" }}
            onClick={() => data.dataGrid && setGridDataAndOpenDialog(data.dataGrid, path)
            }>
            <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
                <div className="h-96 w-100-p" style={{ height: "100%", display: "flex", alignItems: "center" }}>
                    <div style={{ width: width, height: width, margin: 20 }}>
                        <Doughnut data={chartData} options={chartOptions} width={width} height={width} />
                    </div>
                    <div style={{ margin: 10 }}>
                        {
                            data.title &&
                            <Typography style={{ fontWeight: 700, fontSize: 24 }}>{data.title}</Typography>
                        }
                        <PercentageList
                            itemsArray={Object.keys(data.data)}
                            countArray={dataValues}
                            colorsToUse={chartColors}
                        />
                    </div>
                </div>
            </Grid>
        </Card >
    );
}

function preventRender() {
    return true;
}

export default memo(GDGraph, preventRender);
