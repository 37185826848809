import React from "react";
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import { formatDate } from "@icarius-utils/date";
import { useDispatch } from "react-redux";
import { openDialogAction } from "@icarius-common/dialog/actions";
import StepContainer from "./stepContainer";
import { STATUS_TEXT } from "./stepFourStatusText";

const gridStyle = { height: 75, padding: "0px 10px" };

const DATE_FIELD_NAMES = [
  'creationDate',
  'courseStartDate',
  'calificationEndDate',
  'calibrationStartDate',
  'feedbackStartDate',
  'classificationStartDate',
  'courseEndDate',
];

const StepFour = (props) => {

  const {
    isCreate,
    data,
    formData,
    setFormValue,
    statusList,
  } = props;

  const dispatch = useDispatch();

  const statusSelectedString = statusList?.find((item) => item.key === formData.evaluationStatus)?.value || '';

  const originalEvaluationStatus = data?.evaluationStatus || 'C';
  const originalEvaluationStatusIndex = statusList?.findIndex((item) => item.key === originalEvaluationStatus) || 0;

  const statusListToShow = (() => {
    if (!statusList?.length) return [];
    if (!data) return [statusList[0]]; // si es creación, mostrar solo el primer elemento

    const indexFrom = (() => {
      if (['C', 'E'].includes(originalEvaluationStatus)) return originalEvaluationStatusIndex; // en estos no puede volver al estado anterior
      return originalEvaluationStatusIndex - 1;
    })();

    return statusList.slice(indexFrom, originalEvaluationStatusIndex + 2);
  })();

  const getDateToShow = () => {
    if (!data || originalEvaluationStatus !== formData.evaluationStatus) return formatDate(new Date()); // si cambié el status o es creación, va la fecha de hoy
    return data[DATE_FIELD_NAMES[originalEvaluationStatusIndex]];
  }

  const handleOpenDialog = (statusKey) => {
    if (statusKey === 'C') return;
    const statusName = statusList.find((item) => item.key === statusKey).value;
    
    dispatch(openDialogAction({
      title: `Atención: para el estado ${statusName}`,
      msg: STATUS_TEXT[statusKey],
      acceptOnly: true,
      maxWidth: 'md',
      fullWidth: true,
    }));
  }

  return (
    <StepContainer title={'Pasos y etapas de la evaluación'}>
      <Grid
        container item alignItems="center" xs={12}
        style={{ marginTop: 10, marginBottom: 10, background: 'var(--secondaryBackgroundColor)', borderRadius: 4 }}
        className='myDesktopShadow'
      >
        <div style={{ padding: 15, display: 'flex', flexWrap: 'wrap', rowGap: 10, columnGap: 40 }}>
          <div>
            <Typography className="whiteText" variant="caption">{'Fecha desde'}</Typography>
            <Typography className="whiteText">{formatDate(formData.evaluationStartDate)}</Typography>
          </div>
          <div>
            <Typography className="whiteText" variant="caption">{'Fecha hasta'}</Typography>
            <Typography className="whiteText">{formatDate(formData.evaluationEndDate)}</Typography>
          </div>
          <div>
            <Typography className="whiteText" variant="caption">{'Creada'}</Typography>
            <Typography className="whiteText">{isCreate ? formatDate(new Date()) : data.creationDate}</Typography>
          </div>
        </div>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={'label-evaluationStatus'}>{'Seleccione la siguiente etapa'}</InputLabel>
            <Select
              disabled={!data}
              value={formData.evaluationStatus}
              labelId={'label-evaluationStatus'}
              id={'select-evaluationStatus'}
              onChange={(e) => {
                setFormValue(e.target.value, "evaluationStatus");
                handleOpenDialog(e.target.value);
              }}
              margin={"none"}
            >
              {
                statusListToShow?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
          <Typography className="whiteText">
            {`${statusSelectedString} - ${getDateToShow()}`}
          </Typography>
        </Grid>
      </Grid>
    </StepContainer>
  );
}

export default StepFour;