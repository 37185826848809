import React, { memo } from "react";
import DashboardChart from "../widgets";
import { Grid } from "@material-ui/core";

const BankDistribution = ({ data, companyColor, setGridDataAndOpenDialog, gridTheme }) => {
    return (
        <Grid container justify="space-between" item xs={12} >
            <Grid item xs={12}>
                <DashboardChart
                    path="currentProcessesDashboardBankDistribution"
                    setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                    gridTheme={gridTheme}
                    type={"GV"}
                    data={data}
                    customChartColor={'#F5A506'}
                />
            </Grid>
        </Grid>
    )
}

export default memo(BankDistribution);
