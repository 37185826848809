import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import {
  DocumentPublicationsIcon,
  DocumentTemplatesIcon,
} from "@icarius-icons/";

const Index = () => {

  const data = [
    {
      name: "Plantillas para documentos",
      icon: <DocumentTemplatesIcon />,
      path: paths.documentTemplates,
    },
    {
      name: "Publicaciones de documentos",
      icon: <DocumentPublicationsIcon />,
      path: paths.documentPublication,
    },
  ];

  return (
    <ScreenSelector
      title={'Documentos'}
      data={data}
    />
  );
}

export default Index;