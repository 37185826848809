
import React from 'react';
import { Card, Grid, Typography } from "@material-ui/core";
import { HorizontalBar } from 'react-chartjs-2';
import { formatNumberOrReturnUndefined } from '@icarius-utils/format';

const chartOptions = {
    legend: {
        display: false,
    },
    scales: {
        xAxes: [{
            display: false,
        }],
        yAxes: [{
            display: false,
        }]
    },
    tooltips: {
        enabled: false
    }
}


function BXPGraph({ data, gridTheme, customChartColor, setGridDataAndOpenDialog, path }) {
    const percentage = data.percent;

    const chartData = {
        datasets: [
            {
                label: "Finalizado",
                stack: "stack",
                data: [percentage],
                backgroundColor: customChartColor ? [customChartColor] : ["#1d7cf0"],
                borderWidth: 1,
            },
            {
                label: "Faltante",
                stack: "stack",
                data: [100 - percentage],
                backgroundColor: ["#747474"],
                borderWidth: 1,
            },
        ],
    }

    return (
        <Card className="w-full rounded-20 shadow" style={{ minHeight: 200, cursor: "pointer", display: "flex", alignItems: "center" }} onClick={() => data.dataGrid && setGridDataAndOpenDialog(data.dataGrid, path)}>
            <Grid container>
                <div style={{ padding: "0px 0px 5px 15px" }}>
                    <Typography style={{ fontWeight: 900, fontSize: 36 }}>{formatNumberOrReturnUndefined(data.cant, 0, 2)}</Typography>
                    <Typography style={{ fontWeight: 300, fontSize: 18 }}>{data.title}</Typography>
                </div>
                <Grid container direction="row" alignItems="center" justify="flex-start" item xs={12} style={{ marginLeft: 15 }}>
                    <Grid item xs="auto">
                        <Typography className='whiteText' style={{ fontSize: 16, fontWeight: 500, textShadow: gridTheme === "dark" ? '1px 1px 2px black' : '' }}>
                            {`${percentage || 0}%`}
                        </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12} style={{ marginLeft: 10 }}>
                        <div>
                            <HorizontalBar data={chartData} options={chartOptions} height={60} />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    )
}

export default BXPGraph;