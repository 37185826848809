import NAME from "./constants";

export const getLoading = store => store[NAME].isLoading || store[NAME].isLoadingDashboard || store[NAME].isLoadingAnalytics;
export const getRows = store => store[NAME].rows;
export const getDetails = store => store[NAME].details;
export const getOthers = store => store[NAME].others;
export const getReferences = store => store[NAME].references;
export const getResults = store => store[NAME].results;
export const getDecimalSeparator = store => store[NAME].decimalSeparator;
export const getDateFormat = store => store[NAME].dateFormat;
export const getLocale = store => store[NAME].currencyLocalization;
export const getTotalResult = store => store[NAME].totalResult;
export const getHaberesConAportes = store => store[NAME].haberesConAportes;
export const getDescuentosLegales = store => store[NAME].descuentosLegales;
export const getHaberesSinAportes = store => store[NAME].haberesSinAportes;
export const getdDescuentosVarios = store => store[NAME].descuentosVarios;
export const getDashboardData = store => store[NAME].dashboardData;
export const getDashboardDesc = store => store[NAME].dashboardDesc;
export const getCurrencyLocalization = store => store[NAME].currencyLocalization;
export const getLoadingGeneratedDocuments = store => store[NAME].isLoadingGeneratedDocuments;
export const getAnalyticsData = store => store[NAME].analyticsData;
export const getAnalyticsFilters = store => store[NAME].filters;