import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";

const PercentageList = (props) => {

    const {
        itemsArray,
        countArray,
        colorsToUse,
        getItemPercentage,
    } = props;

    return (
        <>
            {
                itemsArray.map((item, index) => {
                    return (
                        <Grid container direction='row' alignItems="center" item key={index} style={{ minHeight: 45 }}>
                            <div style={{ display: "flex", width: !getItemPercentage ? "100%" : "initial" }}>
                                <div style={{ height: 25, width: 25, backgroundColor: colorsToUse[index] }} />
                                <Typography className='whiteText' style={{ fontSize: 20, fontWeight: 600, paddingLeft: 20, paddingRight: 25 }}>
                                    {getItemPercentage ? `${getItemPercentage(index, countArray)}%` : item}
                                </Typography>
                            </div>
                            <Typography className='whiteText' style={{ fontSize: 20 }}>
                                {getItemPercentage ? item : formatNumberOrReturnUndefined(countArray[index], 0, 2)}
                            </Typography>
                        </Grid>
                    )
                })
            }
        </>
    );
}

export default PercentageList;
