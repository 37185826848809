import React from "react";
import DashboardChart from "../widgets";
import { Grid, useMediaQuery } from "@material-ui/core";

const CompanyCost = ({ data, gridTheme, selectedCategory, setGridDataAndOpenDialog }) => {
    const matchesLessThan1280 = useMediaQuery(`(max-width:1280px)`);
    const sortObject = o => Object.keys(o).sort().reduce((r, k) => Object.assign(r, { [k]: o[k] }), {});

    return (
        <Grid container justify="space-between" item xs={12} >
            <Grid container item xs={12}>
                {
                    data && data.data.length >= 1 && data.data[0].option?.hasOwnProperty(selectedCategory) &&
                    <Grid item lg={4} xs={12} style={matchesLessThan1280 ? { paddingTop: 20 } : { padding: "0px 15px 0px 0px" }}>
                        <DashboardChart
                            path="currentProcessesDashboardCompanyCost"
                            setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                            gnDataGrid={data.dataGrid}
                            type={"GN"}
                            data={{ ...data.data[0], data: sortObject(data.data[0].option[selectedCategory]) }}
                            gridTheme={gridTheme}
                            customChartColor={"#FF592C"}
                        />
                    </Grid>
                }
                {
                    data && data.data.length >= 2 && data.data[1].option?.hasOwnProperty(selectedCategory) &&
                    <Grid item lg={4} xs={12} style={matchesLessThan1280 ? { paddingTop: 20 } : { padding: "0px 15px 0px 0px" }}>
                        <DashboardChart
                            path="currentProcessesDashboardCompanyCost"
                            setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                            gnDataGrid={data.dataGrid}
                            type={"GN"}
                            data={{ ...data.data[1], data: sortObject(data.data[1].option[selectedCategory]) }}
                            gridTheme={gridTheme}
                            customChartColor={"#01697E"}
                        />
                    </Grid>
                }
                {
                    data && data.data.length >= 3 && data.data[2].option?.hasOwnProperty(selectedCategory) &&
                    <Grid item lg={4} xs={12} style={matchesLessThan1280 ? { paddingTop: 20 } : { padding: "0px 15px 0px 0px" }}>
                        <DashboardChart
                            path="currentProcessesDashboardCompanyCost"
                            setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                            gnDataGrid={data.dataGrid}
                            type={"GN"}
                            data={{ ...data.data[2], data: sortObject(data.data[2].option[selectedCategory]) }}
                            gridTheme={gridTheme}
                            customChartColor={"#F5A506"}
                        />
                    </Grid>
                }
            </Grid>
        </Grid>
    )
}

export default CompanyCost;
