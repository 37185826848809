
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Grid, Card, Typography } from "@material-ui/core";
import { formatNumberOrReturnUndefined } from '@icarius-utils/format';

function GNGraph({ data, customChartColor, setGridDataAndOpenDialog, gnDataGrid, path }) {
    const myData = {
        id: 'GNGraph',
        series: [
            {
                data: Object.values(data.data)
            }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 100,
                toolbar: {
                    show: false
                }
            },
            grid: { show: false },
            colors: customChartColor ? [customChartColor] : ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0'],
            plotOptions: {
                bar: {
                    columnWidth: '90%',
                    distributed: false,
                }
            },
            xaxis: {
                show: false,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                categories: Object.keys(data.data),
                labels: { show: false }
            },
            yaxis: {
                show: false,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
            },
            dataLabels: {
                enabled: false
            },
            legend: {
                show: false
            },
            states: {
                active: {
                    filter: {
                        type: 'none' /* none, lighten, darken */
                    }
                }
            },
            tooltip: {
                marker: {
                    show: false,
                },
                followCursor: true,
                theme: 'dark',
                y: {
                    formatter: function (val) {
                        return formatNumberOrReturnUndefined(val, 2, 2)
                    },
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
            }
        }
    };

    return (
        <Card className="w-full rounded-20 shadow" style={{ cursor: "pointer" }} onClick={() => gnDataGrid && setGridDataAndOpenDialog(gnDataGrid, path)}>
            <Grid container alignItems="center">
                <Grid item xs={12} style={{ padding: "15px 0px 0px 15px" }}>
                    <Typography style={{ fontWeight: 900, fontSize: 36 }}>{`$${formatNumberOrReturnUndefined(data.value, 0, 0)}`}</Typography>
                </Grid>
                <Grid item xs={12} style={{ padding: "0px 0px 5px 15px" }}>
                    <Typography style={{ fontWeight: 300, fontSize: 18 }}>{data.title}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <ReactApexChart
                        options={myData.options}
                        series={myData.series}
                        type={myData.options.chart.type}
                        height={myData.options.chart.height}
                    />
                </Grid>
            </Grid>
        </Card>
    );
}

export default GNGraph;