
import React, { memo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, Typography } from "@material-ui/core";
import { formatNumberOrReturnUndefined } from '@icarius-utils/format';

function GAGraph({ data, customChartColor, setGridDataAndOpenDialog, path }) {
    const myData = {
        id: 'GAGraph',
        series: [
            {
                data: Object.values(data.data)
            }
        ],
        options: {
            colors: customChartColor ? [customChartColor] : ['#1579c5'],
            chart: {
                type: 'area',
                height: '100%',
                sparkline: {
                    enabled: true
                }
            },
            fill: {
                type: 'solid',
                opacity: 0.7
            },
            xaxis: {
                categories: Object.keys(data.data).map(item => formatNumberOrReturnUndefined(item, 0, 2))
            },
            tooltip: {
                marker: {
                    show: false,
                },
                followCursor: true,
                theme: 'dark',
                y: {
                    formatter: function (val) {
                        return formatNumberOrReturnUndefined(val, 0, 2)
                    },
                    title: {
                        formatter: function (seriesName) {
                            return ''
                        }
                    }
                },
                x: { show: false },
            }
        }
    };

    return (
        <Card
            className="w-full rounded-20 shadow"
            style={{ cursor: "pointer" }}
            onClick={() => data.dataGrid && setGridDataAndOpenDialog(data.dataGrid, path)}
        >
            <div style={{ padding: "15px 0px 5px 15px" }}>
                <Typography style={{ fontWeight: 900, fontSize: 36, marginBottom: 5 }}>{formatNumberOrReturnUndefined(data.value, 0, 2)}</Typography>
                <Typography style={{ fontWeight: 500, fontSize: 24 }}>{data.title}</Typography>
                <Typography style={{ fontWeight: 300, fontSize: 18 }}>{data.subtitle}</Typography>
            </div>
            <div className="h-96 w-100-p">
                <ReactApexChart
                    options={myData.options}
                    series={myData.series}
                    type={myData.options.chart.type}
                    height={myData.options.chart.height}
                />
            </div>
        </Card>
    );
}

function preventRender() {
    return true;
}

export default memo(GAGraph, preventRender);
