import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ProfileImg from "@icarius-common/profileImg";
import Loader from "@icarius-common/loader";
import UserSelectionDialog from "@icarius-common/userSelectionDialog";
import { AddIcon, PersonIcon } from "@icarius-icons/index";
import { getAppColor } from "src/app/selectors";
import GridSelectionDialog from "./gridSelectionDialog";
import useHandleForm from "./useHandleForm";
import { createEvaluationProcessDefinitionAssignmentAction } from "../../actions";

const CreateDialog = (props) => {

  const {
    open,
    isLoading,
    colaborators,
    data,
    processCode,
    processName,
    processStatusString,
    handleClose,
  } = props;
  
  const [userSelectionDialogIsOpen, setUserSelectionDialogIsOpen] = useState(false);
  const [gridSelectionDialogIsOpen, setGridSelectionDialogIsOpen] = useState(false);

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);

  const employees = useMemo(() => {
    return colaborators.map(item => ({
      key: item['CODIGO DE EMPLEADO'],
      img: item['FOTOGRAFIA'] !== '-' ? item['FOTOGRAFIA'] : '',
      name: item['APELLIDO Y NOMBRE'],
    }))
  }, [colaborators]);

  const create = (collaborators) => {
    dispatch(createEvaluationProcessDefinitionAssignmentAction({
      collaborators,
      processCode,
    })).then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit,
  } = useHandleForm(data, processCode, create, openValidationError);

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={DialogTransition}
        PaperComponent={PaperDraggable}
        maxWidth={"sm"}
        fullWidth={true}
      >
        <div className={"dialog-container"}>
          <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
            {`Incorporar colaboradores al proceso: ${processName} (${processStatusString})`}
            <DialogTitleDivider />
          </DialogTitle>
          <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
          <DialogContent style={{ paddingTop: 0 }}>
            <Loader open={isLoading} />
            <Grid container item xs={12}>
              <Grid container item alignItems='center' xs={12}>
                <Grid container item xs={12} justify='center' alignItems='center' style={{ height: 52 }}>
                  <Button
                    variant='contained'
                    className='whiteText'
                    onClick={() => setUserSelectionDialogIsOpen(true)}
                    startIcon={<PersonIcon htmlColor={color} />}
                  >
                    Seleccionar colaboradores
                  </Button>
                </Grid>
                <Grid container item xs={12} justify='center' alignItems='center' style={{ height: 52 }}>
                  <Button
                    variant='contained'
                    className='whiteText'
                    onClick={() => setGridSelectionDialogIsOpen(true)}
                    startIcon={<AddIcon htmlColor={color} />}
                  >
                    Lista de colaboradores
                  </Button>
                </Grid>
                <Grid container xs={12} item style={{ maxHeight: 200 }}>
                  {
                    formData['collaborators']?.map((employeeCode, index) => {
                      const employeeSelected = employees?.find(item => item.key === employeeCode);
                      const userImage = employeeSelected?.img;
                      return (
                        <Grid key={index} container xs={12} item style={{ margin: 10 }}>
                          <Grid item xs={2}>
                            <ProfileImg
                              image={userImage !== '-' ? userImage : ''}
                              size={40}
                            />
                          </Grid>
                          <Grid container item xs={8} alignItems='center'>
                            <Typography variant="h6" className="whiteText" style={{ fontWeight: 100, fontSize: 16 }}>
                              {employeeSelected?.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    })
                  }
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
            <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
          </DialogActions>
        </div>
      </Dialog>
      {
        userSelectionDialogIsOpen &&
        <UserSelectionDialog
          open={userSelectionDialogIsOpen}
          initialData={formData['collaborators']} //array de strings con key del primero seleccionado, si no hay, [] o [""]
          users={employees}
          title={'Seleccionar colaboradores'}
          selectedUsersTitle={'Seleccionados'}
          handleClose={() => setUserSelectionDialogIsOpen(false)}
          handleSubmit={(data) => setFormValue(data, 'collaborators')}
        />
      }
      {
        gridSelectionDialogIsOpen &&
        <GridSelectionDialog
          open={gridSelectionDialogIsOpen}
          preselected={formData['collaborators']} //array de strings con key del primero seleccionado, si no hay, [] o [""]
          data={colaborators}
          handleClose={() => setGridSelectionDialogIsOpen(false)}
          handleConfirm={(data) => setFormValue(data, 'collaborators')}
        />
      }
    </>
  );
}

export default CreateDialog;