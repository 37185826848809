import * as actionTypes from "./actionTypes";
import {
  getEntryExitTypesSettingsAPI,
  deleteEntryExitTypesSettingsAPI,
  createEntryExitTypesSettingsAPI,
  updateEntryExitTypesSettingsAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch, type) => {
  let errorString = "";

  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = `No puede existir más de un tipo de ${type === "I" ? "ingreso" : "egreso"} que comparta el mismo código o nombre`;
      break;
    case "IN_USE":
      errorString = `No es posible eliminar el tipo de ${type === "I" ? "ingreso" : "egreso"} porque está en uso`;
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getEntryExitTypesAction = (type) => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getEntryExitTypesSettingsAPI(`?type=${type}`);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: { data },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch, type);
  }
};

export const deleteEntryExitTypeAction = (dataToSend, type) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteEntryExitTypesSettingsAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({
      msg: `El tipo de ${type === "I" ? "ingreso" : "egreso"} se ha eliminado correctamente`,
      severity: "success"
    }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch, type);
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    return e.response;    
  }
};

export const createEntryExitTypeAction = (dataToSend, type) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createEntryExitTypesSettingsAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({
      msg: `El tipo de ${type === "I" ? "ingreso" : "egreso"} se ha creado correctamente`,
      severity: "success"
    }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch, type);
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
  }
};

export const modifyEntryExitTypeAction = (dataToSend, type) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateEntryExitTypesSettingsAPI(dataToSend);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({
      msg: `El tipo de ${type === "I" ? "ingreso" : "egreso"} se ha actualizado correctamente`,
      severity: "success"
    }));
    return response;
  } catch (e) {
    errorHandler(e, dispatch, type);
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
  }
};