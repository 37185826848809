import React from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { CloseIcon } from "@icarius-icons";
import GridDialogContent from "./gridDialogContent";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const StyledDialog = withStyles({
  paper: {
    minWidth: "90%",
  },
})(Dialog);

const GridDialog = (props) => {

  const {
    open,
    calcProcessName,
    data,
    handleClose,
    currencyLocalization,
    gridPath
  } = props;

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {`Nombre del proceso de cálculo: ${calcProcessName}`}
      </DialogTitle>
      <GridDialogContent
        data={data}
        currencyLocalization={currencyLocalization}
        gridPath={gridPath}
        calcProcessName={calcProcessName}
      />
    </StyledDialog>
  );
}

export default GridDialog;