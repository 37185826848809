import React, { useCallback, useState, useRef, useEffect } from 'react';
import OrgChart from '@balkangraph/orgchart.js';
import { openSnackbarAction } from "@icarius-common/snackbar/actions";

const OwnOrgChart = React.memo((props) => {
    const {
        setCurrentNode,
        dispatch,
        nodes,
        setChartRef,
        setClickedNodeAndOpenDialog,
    } = props;

    const chartRef = useRef();
    const divRef = useRef();
    const [chart, setChart] = useState(null);

    const detailsIcon = '<svg width="24" height="24" viewBox="0 0 512 512"><path fill="#7A7A7A" d="M447.933,103.629c-0.034-3.076-1.224-6.09-3.485-8.352L352.683,3.511c-0.004-0.004-0.007-0.005-0.011-0.008 C350.505,1.338,347.511,0,344.206,0H89.278C75.361,0,64.04,11.32,64.04,25.237v461.525c0,13.916,11.32,25.237,25.237,25.237 h333.444c13.916,0,25.237-11.32,25.237-25.237V103.753C447.96,103.709,447.937,103.672,447.933,103.629z M356.194,40.931 l50.834,50.834h-49.572c-0.695,0-1.262-0.567-1.262-1.262V40.931z M423.983,486.763c0,0.695-0.566,1.261-1.261,1.261H89.278 c-0.695,0-1.261-0.566-1.261-1.261V25.237c0-0.695,0.566-1.261,1.261-1.261h242.94v66.527c0,13.916,11.322,25.239,25.239,25.239 h66.527V486.763z"></path><path fill="#7A7A7A" d="M362.088,164.014H149.912c-6.62,0-11.988,5.367-11.988,11.988c0,6.62,5.368,11.988,11.988,11.988h212.175 c6.62,0,11.988-5.368,11.988-11.988C374.076,169.381,368.707,164.014,362.088,164.014z"></path><path fill="#7A7A7A" d="M362.088,236.353H149.912c-6.62,0-11.988,5.368-11.988,11.988c0,6.62,5.368,11.988,11.988,11.988h212.175 c6.62,0,11.988-5.368,11.988-11.988C374.076,241.721,368.707,236.353,362.088,236.353z"></path><path fill="#7A7A7A" d="M362.088,308.691H149.912c-6.62,0-11.988,5.368-11.988,11.988c0,6.621,5.368,11.988,11.988,11.988h212.175 c6.62,0,11.988-5.367,11.988-11.988C374.076,314.06,368.707,308.691,362.088,308.691z"></path><path fill="#7A7A7A" d="M256,381.031H149.912c-6.62,0-11.988,5.368-11.988,11.988c0,6.621,5.368,11.988,11.988,11.988H256 c6.62,0,11.988-5.367,11.988-11.988C267.988,386.398,262.62,381.031,256,381.031z"></path></svg>'

    const showSnackbarError = useCallback((msg) => {
        dispatch(openSnackbarAction({ msg: msg, severity: "error" }));
    }, [dispatch])


    useEffect(() => {
        chartRef.current = chart;
        setChartRef(chart)
    }, [chart, setChartRef])

    const detailsHandler = useCallback((nodeId) => {
        const node = chartRef.current.get(nodeId);
        setClickedNodeAndOpenDialog(node);
    }, [setClickedNodeAndOpenDialog])

    useEffect(() => {
        OrgChart.SEARCH_PLACEHOLDER = "Buscar...";
        OrgChart.RES.IT_IS_LONELY_HERE_LINK = "Comienza a diagramar tu estructura!";
        OrgChart.IT_IS_LONELY_HERE = '<g transform="translate(-95, 0)" style="cursor:pointer;"  control-add="control-add"><text fill="#039be5" style="font-size: smaller;">Comienza a diagramar tu estructura!</text></g>'

        OrgChart.templates.invisibleGroup.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />';
        OrgChart.templates.group.link = '<path stroke-linejoin="round" stroke="#aeaeae" stroke-width="1px" fill="none" d="M{xa},{ya} {xb},{yb} {xc},{yc} L{xd},{yd}" />';
        OrgChart.templates.group.nodeMenuButton = '';
        OrgChart.templates.group.min = Object.assign({}, OrgChart.templates.group);
        OrgChart.templates.group.min.imgs = "{val}";
        OrgChart.templates.group.min.img_0 = "";
        //OrgChart.templates.group.min.description = '<text width="230" text-overflow="multiline" style="font-size: 14px;" fill="#000000" x="125" y="100" text-anchor="middle">{val}</text>';
        // para que salga el circulo con la cantidad
        OrgChart.templates.ula.plus = '<circle cx="15" cy="15" r="15" fill="#ffffff" stroke="#aeaeae" stroke-width="1"></circle>'
            + '<text text-anchor="middle" style="font-size: 18px;cursor:pointer;" fill="#757575" x="15" y="22">{collapsed-children-count}</text>';
        OrgChart.templates.myTemplate = Object.assign({}, OrgChart.templates.ula);
        OrgChart.templates.myTemplate.field_1 = '<text data-width="230" data-text-overflow="multiline" style="font-size: 18px;" fill="#FFFFFF" x="125" y="30" text-anchor="middle">{val}</text>';
        OrgChart.templates.myTemplate.field_0 = '<text data-width="230" data-text-overflow="multiline" style="font-size: 18px;" fill="#FFFFFF" x="125" y="55" text-anchor="middle">{val}</text>';
        OrgChart.templates.myTemplate.field_2 = '<text data-width="230" data-text-overflow="multiline" style="font-size: 18px;" fill="#FFFFFF" x="125" y="100" text-anchor="middle">{val}</text>';
        OrgChart.templates.noMenuTemplate = Object.assign({}, OrgChart.templates.myTemplate);
        OrgChart.templates.noMenuTemplate.nodeMenuButton = '';

        if (!chart) {

            const newChart = new OrgChart(divRef.current, {
                nodes: nodes, //carga de datos
                template: "myTemplate",
                align: OrgChart.ORIENTATION,
                nodeMouseClick: OrgChart.action.none,
                layout: OrgChart.treeRightOffset,
                scaleInitial: OrgChart.match.boundary,
                //el menu
                nodeMenu: {
                    detalles: {
                        icon: detailsIcon,
                        text: "Detalles",
                        onClick: detailsHandler
                    },
                },

                toolbar: {
                    zoom: true,
                    fit: true,
                    expandAll: true
                },

                collapse: {
                    level: 2,
                    allChildren: true
                },

                nodeBinding: {
                    field_0: "name",
                    field_1: "concept",
                    field_2: "value",
                },

                tags: {
                    "noMenu": {
                        template: "noMenuTemplate"
                    }
                }
            });
            setChart(newChart)

        }
    }, [chart, setCurrentNode, nodes, detailsHandler])

    useEffect(() => {
        if (chart) {
            chart.on('click', function (sender, args) {
                if (args.node.tags.indexOf("group") !== -1) {
                    if (args.node.min) {
                        sender.maximize(args.node.id);
                    }
                    else {
                        sender.minimize(args.node.id);
                    }
                    chart.draw(OrgChart.action.update, null, function () {
                        chart.fit();
                    });
                    return false;
                }
                return true;
            });


            chart.nodeMenuUI.on('show', function (sender, args) {
                const node = chartRef.current.get(args.firstNodeId);

                // Si es un nodo nivel 2, tengo que ponerle el menu con los detalles
                if (Number.isInteger(node.id)) {
                    delete args.menu.detalles;
                }
            });

            if (nodes && nodes.length) {
                chart.load(nodes)
            }

            chart.draw(OrgChart.action.update, null, function () {
                chart.fit();
            });
        }
    }, [chart, nodes, setCurrentNode, showSnackbarError, setChart])

    return (
        <>
            <div id="tree" ref={divRef} style={{ maxHeight: "calc(100vh - 200px)" }}></div>
        </>
    );
})

export default OwnOrgChart;