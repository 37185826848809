import React from "react";
import {
  dateComparator,
  dateClassRule,
  dateFormatter,
  dateValueGetter,
  dateFilterParams,
} from "@icarius-table/date";
import {
  numberComparatorMax2,
  numberFormatterMin2,
  numberFilterParams,
} from "@icarius-table/number";
import { DialogTitle } from "@material-ui/core";
import CommonPage from "@icarius-common/commonPage";
import GridMenu from "@icarius-common/gridMenu";

const StatisticsGridDialogContent = (props) => {

  const {
    processName,
    conceptName,
    data,
    dateFormat,
    subtitle,
  } = props;

  const isNumeric = (str) => {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
      !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

  const getColumnDef = () => {
    const columnDefs = [];
    if (data.length > 0) {
      Object.keys(data[0]).forEach(key => {
        if (!key.includes("[key]")) {
          let item = {
            headerName: key,
            field: key,
            filter: "agTextColumnFilter",
          };

          if (Object.keys(data[0]).length) {
            if (["Tipo de funcionario",
              "Tipo de contrato",
              "Gerencia",
              "Departamento",
              "Sección",
              "Referencia",
              "Obra o Faena",
              "Familia del concepto",
            ].includes(key)) {
              item = {
                headerName: key,
                field: key,
                filter: "agSetColumnFilter",
              };
            } else if (key === "Fecha de contratación" || key === "Fecha de egreso" || key === "Fecha de descuento") {
              item = {
                ...item,
                comparator: dateComparator,
                filterParams: dateFilterParams,
                cellClassRules: dateClassRule,
                valueFormatter: dateFormatter,
                valueGetter: dateValueGetter,
                cellClass: "dateFormat",
              }
            } else if (isNumeric(data[0][key])) {
              item = {
                ...item,
                comparator: numberComparatorMax2,
                filterParams: numberFilterParams,
                chartDataType: "series",
                cellClass: "currencyValue",
                valueFormatter: numberFormatterMin2,
                enableValue: true,
                allowedAggFuncs: ["sum", "min", "max", "avg"],
              }
            }
          }

          columnDefs.push(item);
        }
      });
    }

    return columnDefs;
  }

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between', gap: 10, marginTop: 10, marginBottom: 10 }}>
        {
          subtitle &&
          <DialogTitle style={{ padding: 0 }}>
            {subtitle}
          </DialogTitle>
        }
        <div style={{ display: 'flex' }}>
          <GridMenu
            hasHelp
          />
        </div>
      </div>
      <CommonPage
        gridTitle={`Análisis concepto ${processName} - ${conceptName}`}
        ownColumnDef={getColumnDef()}
        rowData={data}
        dateFormat={dateFormat}
        gridHeight={'70vh'}
        ignoreProcessesbyPath
      />
    </>
  );
}

export default StatisticsGridDialogContent;
