import React, { memo } from "react";
import DashboardChart from "../widgets";
import { Grid, useMediaQuery } from "@material-ui/core";

const Analytics = ({ data, setGridDataAndOpenDialog, gridTheme }) => {
    const matchesLessThan1280 = useMediaQuery(`(max-width:1280px)`);

    return (
        <Grid container justify="space-between" item xs={12} >
            <Grid item lg={6} xs={12} style={matchesLessThan1280 ? { paddingTop: 20 } : { padding: "0px 30px 0px 0px" }}>
                <DashboardChart
                    path="currentProcessesDashboardAnalytics"
                    gridTheme={gridTheme}
                    setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                    type={"GV"}
                    data={data[0]} />
            </Grid>
            <Grid item lg={6} xs={12} style={matchesLessThan1280 ? { paddingTop: 20 } : { padding: "0px 0px 0px 30px" }}>
                <DashboardChart
                    path="currentProcessesDashboardAnalytics"
                    setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                    type={"GD"}
                    data={data[1]}
                />
            </Grid>
        </Grid>
    )
}

export default memo(Analytics);
