import NAME from "./constants";

export const GET_ROWS = NAME + "/GET_ROWS";
export const GET_ROWS_FULFILLED = NAME + "/GET_ROWS_FULFILLED";
export const GET_ROWS_REJECTED = NAME + "/GET_ROWS_REJECTED";

export const DELETE_COLAB_PROCESS = NAME + "/DELETE_COLAB_PROCESS";
export const DELETE_COLAB_PROCESS_FULFILLED = NAME + "/DELETE_COLAB_PROCESS_FULFILLED";
export const DELETE_COLAB_PROCESS_REJECTED = NAME + "/DELETE_COLAB_PROCESS_REJECTED";

export const GET_EXTRA_ROWS = NAME + "/GET_EXTRA_ROWS";
export const GET_EXTRA_ROWS_FULFILLED = NAME + "/GET_EXTRA_ROWS_FULFILLED";
export const GET_EXTRA_ROWS_REJECTED = NAME + "/GET_EXTRA_ROWS_REJECTED";

export const CLEAR_EXTRA_ROWS = NAME + "/CLEAR_EXTRA_ROWS";
export const CLEAR_STATE = NAME + "/CLEAR_STATE";

export const PUBLISH_PROCESS = NAME + "/PUBLISH_PROCESS";
export const PUBLISH_PROCESS_FULFILLED = NAME + "/PUBLISH_PROCESS_FULFILLED";
export const PUBLISH_PROCESS_REJECTED = NAME + "/PUBLISH_PROCESS_REJECTED";

export const GET_DASHBOARD = NAME + "/GET_DASHBOARD";
export const GET_DASHBOARD_FULFILLED = NAME + "/GET_DASHBOARD_FULFILLED";
export const GET_DASHBOARD_REJECTED = NAME + "/GET_DASHBOARD_REJECTED";

export const DOWNLOAD_GENERATED_DOCUMENTS = NAME + "/DOWNLOAD_GENERATED_DOCUMENTS";
export const DOWNLOAD_GENERATED_DOCUMENTS_FULFILLED = NAME + "/DOWNLOAD_GENERATED_DOCUMENTS_FULFILLED";
export const DOWNLOAD_GENERATED_DOCUMENTS_REJECTED = NAME + "/DOWNLOAD_GENERATED_DOCUMENTS_REJECTED";

export const GET_ANALYTICS = NAME + "/GET_ANALYTICS";
export const GET_ANALYTICS_FULFILLED = NAME + "/GET_ANALYTICS_FULFILLED";
export const GET_ANALYTICS_REJECTED = NAME + "/GET_ANALYTICS_REJECTED";

export const RESET_STATE_DASHBOARD = NAME + "/RESET_STATE_DASHBOARD";
