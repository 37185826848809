import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getCalculationProcessColaboratorsAPI,
  deleteCalculationProcessColaboratorAPI,
  getColaboratorCalculationProcessResultsAPI,
  publishProcessAPI,
  getProcessDashboardFromServerAPI,
  downloadMultipleReceiptApi,
  getAnalyticsConceptsPerStructureFromServerAPI,
} from "@icarius-connection/api";
import { getLocalizedString, getLocalizedErrorString } from "@icarius-localization/strings";
import { downloadBlob } from "@icarius-utils/download";
import { getFileExtension, getFileName } from "@icarius-utils/fileUpload";

export const getCalculationProcessColaboratorsFromServer = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ROWS });
  try {
    let response = await getCalculationProcessColaboratorsAPI(`?calculation_process=${code}`);
    let rows = response.data && response.data.result;

    if (response.data.partial) {
      dispatch(openSnackbarAction({
        msg: 'VISTA PARCIAL - Se visualiza a los colaboradores que pertenecen a sus grupos de acceso',
        severity: "info",
        duration: null
      }));
    }

    dispatch({
      type: actionTypes.GET_ROWS_FULFILLED,
      payload: { rows }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getProcessDashboardFromServer = (option, code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_DASHBOARD, payload: { option: option, } });
  try {
    let response = await getProcessDashboardFromServerAPI(`/${option}?calculation_process=${code}`);
    dispatch({
      type: actionTypes.GET_DASHBOARD_FULFILLED,
      payload: { option: option, data: response.data }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_DASHBOARD_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};
export const getAnalyticsConceptsPerStructureFromServer = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_ANALYTICS });
  try {
    let response = await getAnalyticsConceptsPerStructureFromServerAPI(`?calculation_process=${code}`);
    dispatch({
      type: actionTypes.GET_ANALYTICS_FULFILLED,
      payload: response.data
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_ANALYTICS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const deleteCalculationProcessColaboratorFromServer = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_COLAB_PROCESS });
  try {
    let response = await deleteCalculationProcessColaboratorAPI(data);

    dispatch({ type: actionTypes.DELETE_COLAB_PROCESS_FULFILLED });
    dispatch(openSnackbarAction({ msg: getLocalizedString("employeesProcessDeletedOK"), severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_COLAB_PROCESS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getColaboratorCalculationProcessResultsFromServer = (processCode, employeeCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_EXTRA_ROWS });
  try {
    let response = await getColaboratorCalculationProcessResultsAPI(`?calculation_process=${processCode}&employee_code=${employeeCode}`);

    let rows = response.data && response.data.result;

    let details = rows.details;
    let others = rows.others;
    let references = rows.references;
    let results = rows.results;
    let totalResult = rows.total_result;
    let haberesConAportes = rows.total_haberes_con_aportes;
    let descuentosLegales = rows.total_descuentos_legales;
    let haberesSinAportes = rows.total_haberes_sin_aporte;
    let descuentosVarios = rows.total_descuentos_varios;

    let decimalSeparator = response.data && response.data.decimal_separator;
    let currencyLocalization = response.data && response.data.currency_localization;
    let dateFormat = response.data && response.data.date_format;

    dispatch({
      type: actionTypes.GET_EXTRA_ROWS_FULFILLED,
      payload: {
        details,
        others,
        references,
        results,
        totalResult,
        haberesConAportes,
        descuentosLegales,
        haberesSinAportes,
        descuentosVarios,
        decimalSeparator,
        currencyLocalization,
        dateFormat
      }
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_EXTRA_ROWS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const publishProcessAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.PUBLISH_PROCESS });
  try {
    let response = await publishProcessAPI(data);

    dispatch({ type: actionTypes.PUBLISH_PROCESS_FULFILLED });
    const successString = data.publicate ? "El proceso de cálculo se ha publicado con éxito" : "El proceso de cálculo ha sido eliminado de los proceso publicados con éxito"
    dispatch(openSnackbarAction({ msg: successString, severity: "success" }));
    return response;
  } catch (e) {
    if (e.response.data.error === "DOCUMENT_ALREADY_PUBLICATED") {
      dispatch(openSnackbarAction({ msg: "Este proceso ya ha sido publicado", severity: "error" }));
    } else {
      if (e.response.data.error === "DOCUMENT_PUBLICATED_UNEXISTS") {
        dispatch(openSnackbarAction({ msg: "El proceso de cálculo no se encuentra publicado actualmente", severity: "error" }));
      } else {
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
      }
    }
    dispatch({ type: actionTypes.PUBLISH_PROCESS_REJECTED, payload: e });
    return e;
  }
};

export const downloadDocumentsGeneratedInServer = (code, colaborator_codes, reference) => async (dispatch) => {
  dispatch({ type: actionTypes.DOWNLOAD_GENERATED_DOCUMENTS });
  try {
    let response = await downloadMultipleReceiptApi({ code, colaborator_codes, reference });
    let documents = response.data;
    let title = response.headers["x-content-namefile"];

    if (title === "TEMPLATE_NOT_FOUND" || response.status !== 200) {
      dispatch({ type: actionTypes.DOWNLOAD_GENERATED_DOCUMENTS_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DOWNLOAD_GENERATED_DOCUMENTS_FULFILLED,
      payload: { documents }
    });

    downloadBlob(response.data, getFileExtension(title), getFileName(title));

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DOWNLOAD_GENERATED_DOCUMENTS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};