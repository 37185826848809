import React from "react";
import { DialogTitle, Dialog, DialogContent } from "@material-ui/core";
import { CloseIcon } from "@icarius-icons";
import { withStyles } from "@material-ui/core/styles";
import PaperDraggable from "@icarius-common/paperDraggable";
import AnalyticsGridDialogContent from "./analyticsGridDialogContent";
import DialogTransition from "@icarius-common/dialogTransition";

const StyledDialog = withStyles({
  paper: {
    maxHeight: "95%",
    minHeight: "85%",
    maxWidth: "95%",
    minWidth: "95%",
  },
})(Dialog);

const verifyValidRow = (value) => {
  if (value !== undefined && value !== null && value !== "0") {
    return parseFloat(value);
  }

  return false;
}

const GridDialog = (props) => {

  const {
    open,
    color,
    theme,
    dateFormat,
    data,
    handleClose,
    clickedNode,
    processName
  } = props;

  let title = "Análisis de conceptos por estructura"
  let subtitle = "";

  let filteredData = JSON.parse(JSON.stringify(data));

  if (clickedNode) {
    // Primer filtrado, solo traigo los resultados asociados al concepto
    filteredData = filteredData.filter(row => row["Nombre del concepto"] === clickedNode.name);
    subtitle = `${clickedNode["concept"]} - ${clickedNode["name"]}`

    // Filtro especificamente por cada familia de conceptos
    if (clickedNode.id.includes("HABSAP")) {
      filteredData = filteredData.filter(row => verifyValidRow(row["Haberes sin aportes"]))
    } else if (clickedNode.id.includes("HABAP")) {
      filteredData = filteredData.filter(row => verifyValidRow(row["Haberes con aportes"]))
    } else if (clickedNode.id.includes("DESLEG")) {
      filteredData = filteredData.filter(row => verifyValidRow(row["Descuentos legales"]))
    } else if (clickedNode.id.includes("DESVAR")) {
      filteredData = filteredData.filter(row => verifyValidRow(row["Descuentos varios"]))
    } else if (clickedNode.id.includes("OTR")) {
      filteredData = filteredData.filter(row => (
        row["Haberes con aportes"] === undefined &&
        row["Descuentos legales"] === undefined &&
        row["Haberes sin aportes"] === undefined &&
        row["Descuentos varios"] === undefined
      ) && verifyValidRow(row["Valor"]));
    }
    filteredData.forEach(row => {
      delete row["Haberes con aportes"];
      delete row["Haberes sin aportes"];
      delete row["Descuentos legales"];
      delete row["Descuentos varios"];
      delete row["Acumuladores"];
      delete row["Conjunto de conceptos"];
      delete row["Estructuras de ingreso"];
      delete row["Grupo 01"];
      delete row["Grupo 02"];
      delete row["Grupo 03"];
      delete row["Grupo 04"];
      delete row["Grupo 05"];
      delete row["Grupo 06"];
      delete row["Grupo 07"];
      delete row["Grupo 08"];
      delete row["Grupo 09"];
      delete row["Grupo 10"];
      delete row["Grupo 11"];
      delete row["U_seccal"];
      delete row["Grupo 12"];
    })
  }

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth={true}
      maxWidth={"md"}>
      <DialogTitle style={{ cursor: 'move', paddingBottom: 0 }} id="draggable-dialog-title">
        {title}
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <AnalyticsGridDialogContent
          subtitle={subtitle}
          color={color}
          data={filteredData}
          theme={theme}
          dateFormat={dateFormat}
          isGeneralGrid
          title={title}
          conceptName={clickedNode && clickedNode["concept"]}
          processName={processName}
        />
      </DialogContent>
    </StyledDialog>
  );
}

export default GridDialog;
