import React from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import DialogTransition from "@icarius-common/dialogTransition";
import PaperDraggable from "@icarius-common/paperDraggable";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import Loader from "@icarius-common/loader";
import { duplicateEvaluationProcessDefinitionAction } from "../../actions";
import useHandleFormDuplicate from "./useHandleFormDuplicate";

const gridStyle = { height: 75, padding: "10px" };

const DuplicateDialog = (props) => {

  const {
    open,
    data,
    evaluationTypeList,
    isLoading,
    handleClose,
  } = props;

  const dispatch = useDispatch();

  const duplicate = (dataToSend) => {
    dispatch(duplicateEvaluationProcessDefinitionAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit
  } = useHandleFormDuplicate(data, duplicate, openValidationError);

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      fullWidth
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`Duplicar proceso de evaluación: ${data.processName}`}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent style={{ paddingTop: 0 }}>
        <Loader open={isLoading} />
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <TextField
              required
              fullWidth
              margin={"none"}
              label={'Código'}
              value={formData.processCode}
              inputProps={{ maxLength: 20 }}
              onChange={(e) => setFormValue(e.target.value, "processCode")}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <TextField
              required
              fullWidth
              margin={"none"}
              label={'Nombre'}
              value={formData.processName}
              inputProps={{ maxLength: 80 }}
              onChange={(e) => setFormValue(e.target.value, "processName")}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={'label-evaluationType'}>{'Tipo de evaluación'}</InputLabel>
              <Select
                value={formData.evaluationType}
                labelId={'label-evaluationType'}
                id={'select-evaluationType'}
                onChange={(e) => setFormValue(e.target.value, "evaluationType")}
                margin={"none"}
              >
                {
                  evaluationTypeList?.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
        <ButtonDialogAction onClick={submit} isAccept text={getLocalizedString("accept")} />
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateDialog;