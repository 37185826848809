import React, { useState } from "react";
import { Grid, Card, Tooltip, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import CommonPage from "@icarius-common/commonPage";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import { createAggridColumn } from "@icarius-table/utils";

const DataItem = ({ data, processName }) => {

  const conceptSetName = data.name;
  const color = useSelector(getAppColor);
  const isCreate = !Boolean(data);
  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto

  const handleClosePublication = () => {
    !isCreate && setIsOpen(prev => !prev);
  }

  const getGroupsText = (value) => {
    return `${value?.concepts} concepto/s en el conjunto`
  }

  const generateColumns = () => {
    let columnDefinitions = getColumnDefByPage("currentProcessesDashboardConceptGroups");
    if (data.dataGrid.length > 0) {
      Object.keys(data.dataGrid[0]).forEach((key) => {
        let item = {
          headerName: key,
          field: key,
          filter: "agTextColumnFilter",
          cellClass: "stringType",
        };

        if (key.toLowerCase().includes("fecha")) {
          item = {
            ...item,
            ...createAggridColumn(key, 'Fecha'),
          }
        } else if (key.toLowerCase().includes("valor") || key.toLowerCase().includes("cantidad") || key.toLowerCase().includes("porcentaje")) {
          item = {
            ...item,
            ...createAggridColumn(key, 'Mon'),
          }
        }

        columnDefinitions.push(item);
      });

      //Remove duplicate columns
      columnDefinitions = columnDefinitions.filter((column, index, self) => index === self.findIndex(colAtIndex => colAtIndex.field === column.field));
    }
    return columnDefinitions;
  }

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={11} style={{ cursor: !isCreate ? "pointer" : "" }} onClick={handleClosePublication}>
            <Grid container item sm={12} md={6} lg={7} justify="flex-start" alignItems="center">
              <Tooltip title={data.name}>
                <Typography noWrap variant="subtitle1" className="whiteText" style={{ fontWeight: 600 }}>
                  {data.name}
                </Typography>
              </Tooltip>
            </Grid>
            {
              data.concepts !== null &&
              <Grid container item sm={12} md={6} lg={5} justify="flex-end" alignItems="center">
                <Tooltip title={getGroupsText(data)}>
                  <Typography noWrap variant="subtitle1" className="whiteText" style={{ fontWeight: 600 }}>
                    {getGroupsText(data)}
                  </Typography>
                </Tooltip>
              </Grid>
            }
          </Grid>
        </Grid>
      </Card>
      <Grid container item xs={12} style={{ marginTop: 10 }}>
        {
          isOpen &&
          <CommonPage
            rowData={data.dataGrid}
            ownColumnDef={generateColumns()}
            gridTitle={`Conjunto de conceptos_${conceptSetName}_${processName}`}
          />
        }
      </Grid>
    </>
  );
}

export default DataItem;
