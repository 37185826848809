import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getCalculationProcessColaboratorsFromServer,
  deleteCalculationProcessColaboratorFromServer,
  getColaboratorCalculationProcessResultsFromServer,
  publishProcessAction,
  getProcessDashboardFromServer,
  downloadDocumentsGeneratedInServer,
  getAnalyticsConceptsPerStructureFromServer,
} from "../actions";
import {
  getRows,
  getLoading,
  getDashboardData,
  getLoadingGeneratedDocuments,
  getAnalyticsData,
  getAnalyticsFilters,
} from "../selectors";
import { CLEAR_STATE, RESET_STATE_DASHBOARD } from "../actionTypes";
import { getIsExportingGrid, getAppColor } from "src/app/selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { FormControlLabel, Switch } from "@material-ui/core";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import ConfirmationDialog from "./dialogs/confirmationDialog";
import SecondGridDialog from "./dialogs/secondGridDialog";
import PickTemplateDialog from "./dialogs/pickTemplateDialog";
import { CLEAR_EXTRA_ROWS } from "@icarius-pages/employeesProcesses/actionTypes";
import PlayButton from "@icarius-common/audioPlayer/components/playButton";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import Dashboard from "./dashboard";
import AnalyticsOrgChart from "./analytics";
import { getTheme } from "@icarius-pages/login/selectors";
import { PrintIcon } from "@icarius-icons";
import AnalyticsGridDialog from "./analytics/analyticsGridDialog";
import FiltersDialog from "./analytics/filtersDialog";
import OrgChart from '@balkangraph/orgchart.js';
import { formatNumberOrReturnUndefined } from "@icarius-utils/format";

const DASHBOARD_ENDPOINTS = [
  "quantity",
  "analytic",
  "global",
  "companyCost",
  "conceptsValues",
  "conceptGroups",
  "topBottom",
  "bankDistribution",
  "thirdPartyPayment",
  "conceptsGrid",
];

const EmployeesProcesses = ({ process, templates, handleGoBack, hideDelete }) => {
  const filtersDummyData = {
    "Departamento": "",
    "Cargo": "",
    "Gerencia": "",
    "Lugar de trabajo": "",
    "Obra o Faena": "",
    "Acumuladores": "",
    "Grupos para conceptos": "",
    "Conjunto de conceptos": "",
    "Estructura de ingreso": "",
  };

  const dispatch = useDispatch();
  const data = useSelector(getRows);
  const dashboardData = useSelector(getDashboardData);
  const dashboardDesc = {
    "Descripción del proceso": process["Descripción"],
    "Proceso publicado": process["Proceso publicado"],
    "Período del proceso": process["PeríodoString"]
  };

  const originalData = useSelector(getAnalyticsData);
  const color = useSelector(getAppColor);
  const gridTheme = useSelector(getTheme);
  const filters = useSelector(getAnalyticsFilters);

  const isLoading = useSelector(getLoading);
  const isExportinGrid = useSelector(getIsExportingGrid);
  const isGeneratingDocuments = useSelector(getLoadingGeneratedDocuments);
  const [subtitle, setSubtitle] = useState();
  const [analyticsData, setAnalyticsData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employeeDialogIsOpen, setEmployeeDialogIsOpen] = useState(false);
  const [filtersDialogIsOpen, setFiltersDialogIsOpen] = useState(false);
  const [filterData, setFilterData] = useState(filtersDummyData);
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false);
  const [pickTemplateDialogIsOpen, setPickTemplateDialogIsOpen] = useState(false);
  const [codesForDocumentGeneration, setCodesForDocumentGeneration] = useState(null);
  const [codesToDelete, setCodesToDelete] = useState(null);
  const [showDashboard, setShowDashboard] = useState(false);
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [analyticsGridDialogIsOpen, setAnalyticsGridDialogIsOpen] = useState(false);
  const [isPublished, setIsPublished] = useState(Boolean(process["Proceso publicado"] === "SI"))
  const [nodesToUse, setNodesToUse] = useState([]);
  const [clickedNode, setClickedNode] = useState(null);
  const chartRef = useRef();

  const handleeTreeLayout = () => {
    if (chartRef.current) {
      chartRef.current._layoutConfigs['base'].layout = OrgChart.tree;
      chartRef.current.draw();
    }

  }

  const handleRightOffsetTreeLayout = () => {
    if (chartRef.current) {
      chartRef.current._layoutConfigs['base'].layout = OrgChart.treeRightOffset;
      chartRef.current.draw();
    }
  }

  const onClickGetDashboardData = () => {
    setShowDashboard(true);
    dispatch(getProcessDashboardFromServer(DASHBOARD_ENDPOINTS[0], process["Código de proceso"])).then(resp =>
      resp.status && dispatch(getProcessDashboardFromServer(DASHBOARD_ENDPOINTS[1], process["Código de proceso"])).then(resp =>
        resp.status && dispatch(getProcessDashboardFromServer(DASHBOARD_ENDPOINTS[2], process["Código de proceso"])).then(resp =>
          resp.status && dispatch(getProcessDashboardFromServer(DASHBOARD_ENDPOINTS[3], process["Código de proceso"])).then(resp =>
            resp.status && dispatch(getProcessDashboardFromServer(DASHBOARD_ENDPOINTS[4], process["Código de proceso"])).then(resp =>
              resp.status && dispatch(getProcessDashboardFromServer(DASHBOARD_ENDPOINTS[5], process["Código de proceso"])).then(resp =>
                resp.status && dispatch(getProcessDashboardFromServer(DASHBOARD_ENDPOINTS[6], process["Código de proceso"])).then(resp =>
                  resp.status && dispatch(getProcessDashboardFromServer(DASHBOARD_ENDPOINTS[7], process["Código de proceso"])).then(resp =>
                    resp.status && dispatch(getProcessDashboardFromServer(DASHBOARD_ENDPOINTS[8], process["Código de proceso"])).then(resp =>
                      resp.status && dispatch(getProcessDashboardFromServer(DASHBOARD_ENDPOINTS[9], process["Código de proceso"]))
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  }

  useEffect(() => {
    const getSubtitleText = (type) => {
      const filterName = filters[type].find(item => `${item.key}` === filterData[type])?.value;
      return `Filtro aplicado: ${type} - ${filterName}`;
    }
    if (
      filterData["Departamento"] ||
      filterData["Gerencia"] ||
      filterData["Cargo"] ||
      filterData["Lugar de trabajo"] ||
      filterData["Obra o Faena"]) {
      let filtrosAplicados = 0;
      let data = originalData;

      if (filterData["Lugar de trabajo"]) {
        setSubtitle(getSubtitleText('Lugar de trabajo'));
        filtrosAplicados++;
        data = data.filter(row => row["[key]Lugar de trabajo"] === filterData["Lugar de trabajo"]);
      }

      if (filterData["Departamento"]) {
        setSubtitle(getSubtitleText('Departamento'));
        filtrosAplicados++;
        data = data.filter(row => row["[key]Departamento"] === filterData["Departamento"]);
      }

      if (filterData["Cargo"]) {
        setSubtitle(getSubtitleText('Cargo'));
        filtrosAplicados++;
        data = data.filter(row => row["[key]Cargo"] === filterData["Cargo"]);
      }

      if (filterData["Gerencia"]) {
        setSubtitle(getSubtitleText('Gerencia'));
        filtrosAplicados++;
        data = data.filter(row => row["[key]Gerencia"] === filterData["Gerencia"]);
      }

      if (filterData["Obra o Faena"]) {
        setSubtitle(getSubtitleText('Obra o Faena'));
        filtrosAplicados++;
        data = data.filter(row => row["[key]Obra o Faena"] === filterData["Obra o Faena"]);
      }

      if (filtrosAplicados > 1) {
        setSubtitle("Multiples filtros aplicados");
      }

      setAnalyticsData(data);
    } else if (filterData["Estructura de ingreso"]) {
      setSubtitle(getSubtitleText('Estructura de ingreso'));
      setAnalyticsData(originalData.filter(row => row["Estructuras de ingreso"] === filterData["Estructura de ingreso"]));
    } else if (filterData["Grupos para conceptos"]) {
      let group = filterData["Grupos para conceptos"];
      group = `Grupo ${group.padStart(2, "0")}`;
      setSubtitle(getSubtitleText('Grupos para conceptos'));
      setAnalyticsData(originalData.filter(row => row[group] === "Y"));
    } else if (filterData["Conjunto de conceptos"]) {
      setSubtitle(getSubtitleText('Conjunto de conceptos'));
      setAnalyticsData(originalData.filter(row => row["Conjunto de conceptos'"] === filterData["Conjunto de conceptos"]));
    } else if (filterData["Acumuladores"]) {
      setSubtitle(getSubtitleText('Acumuladores'));
      setAnalyticsData(originalData.filter(row => row["Acumuladores"] === filterData["Acumuladores"]));
    } else {
      setAnalyticsData(originalData);
      setSubtitle(null)
    }
  }, [filterData, originalData, filters])

  useEffect(() => {
    dispatch(getCalculationProcessColaboratorsFromServer(process["Código de proceso"]));

    return () => {
      dispatch({ type: CLEAR_STATE });
    }
  }, [dispatch, process])

  const onClickGetAnalyticsConceptsPerStructureData = () => {
    dispatch(getAnalyticsConceptsPerStructureFromServer(process["Código de proceso"])).then(resp => resp.data.status === "OK" &&
      setShowAnalytics(true));
  }


  const closeDashboard = () => {
    setShowDashboard(false);
    dispatch({ type: RESET_STATE_DASHBOARD });
  }

  const handleOpenDeleteDialog = (gridRef) => {
    //Obtener los seleccionados y armar array de codes
    let selectedRows = gridRef.api.getSelectedRows().map(item => item["Código de empleado"]);

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    setDeleteDialogIsOpen(true);
    setCodesToDelete(selectedRows);
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogIsOpen(false);
    setCodesToDelete(null);
  }

  const handleDelete = () => {
    dispatch(deleteCalculationProcessColaboratorFromServer({ employee_codes: codesToDelete, calculation_process: process["Código de proceso"] }))
      .then(() => {
        handleCloseDeleteDialog();
        dispatch(getCalculationProcessColaboratorsFromServer(process["Código de proceso"]));
      })
  }

  const handleRowClick = (gridRef) => {
    dispatch(getColaboratorCalculationProcessResultsFromServer(process["Código de proceso"], gridRef.data["Código de empleado"]))
      .then((res) => {
        if (res?.status === 200) {
          setSelectedEmployee(gridRef.data);
          setEmployeeDialogIsOpen(true);
        }
      });
  }

  const handleCloseEmployeeDialog = () => {
    setEmployeeDialogIsOpen(false);
    setSelectedEmployee(null);
    dispatch({ type: CLEAR_EXTRA_ROWS });
  }

  const handleClosePickTemplateDialog = () => {
    setPickTemplateDialogIsOpen(false);
  }

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleGoBack}
      type={"goBack"}
    />

  const deleteItem = (gridRef) => {
    if (hideDelete) return null;
    return (
      <CustomIconButton
        title={getLocalizedString("delete")}
        onClick={() => handleOpenDeleteDialog(gridRef)}
        type={"delete"}
      />
    )
  }

  const handleSwitchChange = () => {
    if (process.existsDocsSigned) {
      if (process["Proceso publicado"] === "SI") {
        dispatch(openSnackbarAction({
          msg: "No es posible quitar la publicación del proceso de cálculo porque ya se han generado documentos con firma digital para dicho proceso",
          severity: "warning"
        }));
      }
      return;
    }

    if (!process.canPublicate) {
      dispatch(openSnackbarAction({
        msg: "Este tipo de proceso no es posible publicarlo porque es un proceso especial o de simulación",
        severity: "warning"
      }));
      return;
    }

    const dataToSend = {
      calculation_process: process["Código de proceso"],
      publicate: !isPublished,
    }

    dispatch(publishProcessAction(dataToSend))
      .then((resp) => {
        if (resp.status === 200) {
          setIsPublished(prev => !prev);
        }
      })
  }

  const publishItem = () => (
    <div style={{ margin: 5, marginRight: 15, display: "flex", alignItems: 'center', gap: 10 }}>
      <FormControlLabel
        control={
          <Switch
            checked={isPublished}
            onChange={handleSwitchChange}
            value={isPublished}
            color="primary"
          />
        }
        label={"Publicado"}
        labelPlacement="start"
        style={{ marginLeft: -20 }}
      />
      <PlayButton audioName="Publicación de procesos de cálculo" title="Publicación de procesos de cálculo" />
    </div>
  )

  const showDashboardItem = () => {
    return (
      <CustomIconButton
        title={"Tablero de control"}
        onClick={() => onClickGetDashboardData()}
        type={"graphics"}
      />
    )
  }
  const analyticsConceptsPerStructureItem = () => {
    return (
      <CustomIconButton
        title={"Análisis de conceptos por estructura"}
        onClick={() => onClickGetAnalyticsConceptsPerStructureData()}
        type={"orgchart"}
      />
    )
  }

  const handleGenerateDocuments = (gridRef) => {
    //Obtener los seleccionados y armar array de codes
    let selectedRows = gridRef.api.getSelectedRows().map(item => item["Código de empleado"]);

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length === 0) {
      dispatch(openSnackbarAction({ msg: getLocalizedString("youNeedAtLeastOneRow"), severity: "warning" }));
      return;
    }

    setCodesForDocumentGeneration(selectedRows);

    if (templates.length === 1) {
      handleGenerateDocumentsGivenTemplateAndCode(templates[0].code, selectedRows)
    } else {
      // Tengo que elegir un template
      setPickTemplateDialogIsOpen(true);
    }
  }

  const handleGenerateDocumentsGivenTemplateAndCode = (template, codes) => {
    dispatch(downloadDocumentsGeneratedInServer(template, codes, process["Código de proceso"]));
  }

  const handleGenerateDocumentsGivenTemplate = (template) => {
    const codes = selectedEmployee ? [selectedEmployee["Código de empleado"]] : codesForDocumentGeneration;
    dispatch(downloadDocumentsGeneratedInServer(template, codes, process["Código de proceso"]));
  }

  const showGenerateDocumentsItem = (gridRef) => {
    if (templates.length === 0) return null;

    return (
      <CustomIconButton
        title={"Generar documentos"}
        onClick={() => handleGenerateDocuments(gridRef)}
      >
        <PrintIcon />
      </CustomIconButton>
    )
  }
  const setChartRef = (chart) => {
    chartRef.current = chart;
  }


  useEffect(() => {
    let nodes = [];

    const sumElements = (key) => {
      return analyticsData.reduce((a, b) => a + (parseFloat(b[key]) || 0), 0);
    }

    const removeDuplicatesSumProperty = array => {
      return array.reduce(function (accumulator, cur) {
        let name = cur.name, found = accumulator.find(function (elem) {
          return elem.name === name
        });
        if (found) found.value += cur.value;
        else accumulator.push(cur);
        return accumulator;
      }, []);
    }

    const getPreliminaryNodes = (array, property, nodeKey, nodePID) => {
      let tags = ['process-sub-concept-family', 'subtree'];

      // Tratamiento especial cuando se usa Varios
      if (nodeKey === "OTR") {
        // Voy a usar los nodos que no tengan las otras 4 propiedades
        return array.filter(row => (
          row["Haberes con aportes"] === undefined &&
          row["Descuentos legales"] === undefined &&
          row["Haberes sin aportes"] === undefined &&
          row["Descuentos varios"] === undefined
        ) && row["Valor"] !== undefined && row["Valor"] !== null && row["Valor"] !== "0" && parseFloat(row["Valor"])).map(
          (node, i) => {
            let nodeTags = [...tags];

            return {
              id: `${i}${nodeKey}`,
              pid: nodePID,
              name: node["Nombre del concepto"],
              concept: node["Concepto"],
              value: parseFloat(node["Valor"]),
              tags: nodeTags,
            }
          }
        )
      }

      return array.filter(row => row[property] !== undefined && row[property] !== null && row[property] !== "0" && parseFloat(row[property])).map(
        (node, i) => {
          let nodeTags = [...tags];

          return {
            id: `${i}${nodeKey}`,
            pid: nodePID,
            concept: node["Concepto"],
            name: node["Nombre del concepto"],
            value: parseFloat(node[property]),
            tags: nodeTags,
          }
        }
      )
    }

    const formatCurrency = array => {
      return array.map(el => {
        el.value = `${formatNumberOrReturnUndefined(el.value, 2, 2)}`
        return el;
      })
    }

    // El nodo padre tiene el nombre del proceso de calculo
    nodes.push(
      {
        id: 1,
        pid: null,
        name: process["Descripción"],
        tags: ['process-name', 'noMenu'],
      }
    );

    // Haberes con aportes
    nodes.push(
      {
        id: 2,
        pid: 1,
        name: "Haberes con aportes",
        value: `${formatNumberOrReturnUndefined(sumElements("Haberes con aportes"), 2, 2)}`,
        tags: ['process-concept-family', 'noMenu'],
      }
    );
    const habapNodes = formatCurrency(removeDuplicatesSumProperty(getPreliminaryNodes(analyticsData, "Haberes con aportes", "HABAP", 2)));
    nodes = [...nodes, ...habapNodes];

    // Descuentos legales
    nodes.push(
      {
        id: 3,
        pid: 1,
        name: "Descuentos legales",
        value: `${formatNumberOrReturnUndefined(sumElements("Descuentos legales"), 2, 2)}`,
        tags: ['process-concept-family', 'noMenu'],
      }
    );
    const deslegNodes = formatCurrency(removeDuplicatesSumProperty(getPreliminaryNodes(analyticsData, "Descuentos legales", "DESLEG", 3)));
    nodes = [...nodes, ...deslegNodes];

    // Haberes sin aportes
    nodes.push(
      {
        id: 4,
        pid: 1,
        name: "Haberes sin aportes",
        value: `${formatNumberOrReturnUndefined(sumElements("Haberes sin aportes"), 2, 2)}`,
        tags: ['process-concept-family', 'noMenu'],
      }
    );
    const habsapNodes = formatCurrency(removeDuplicatesSumProperty(getPreliminaryNodes(analyticsData, "Haberes sin aportes", "HABSAP", 4)));
    nodes = [...nodes, ...habsapNodes];

    // Descuentos varios
    nodes.push(
      {
        id: 5,
        pid: 1,
        name: "Descuentos varios",
        value: `${formatNumberOrReturnUndefined(sumElements("Descuentos varios"), 2, 2)}`,
        tags: ['process-concept-family', 'noMenu'],
      }
    );
    const desvarNodes = formatCurrency(removeDuplicatesSumProperty(getPreliminaryNodes(analyticsData, "Descuentos varios", "DESVAR", 5)));
    nodes = [...nodes, ...desvarNodes];

    // Otros
    nodes.push(
      {
        id: 6,
        pid: 1,
        name: "Otros",
        tags: ['process-concept-family', 'assistant', 'noMenu'],
      }
    );
    const otherNodes = formatCurrency(removeDuplicatesSumProperty(getPreliminaryNodes(analyticsData, "Otros", "OTR", 6)));
    nodes = [...nodes, ...otherNodes];

    setNodesToUse(nodes);
  }, [analyticsData, process, filterData])


  const handleCloseAnalyticsGridDialog = () => {
    setAnalyticsGridDialogIsOpen(false);
    setClickedNode(null);
  }

  const setClickedNodeAndOpenDialog = (node) => {
    setClickedNode(node);
    setAnalyticsGridDialogIsOpen(true);
  }

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={() => setShowAnalytics(false)}
      type={"goBack"}
    />
  )

  const hasFilterData = Object.keys(filterData).some(item => filterData[item] !== "");

  const filterButton = () => (
    <CustomIconButton
      title={"Filtrar"}
      onClick={() => setFiltersDialogIsOpen(true)}
      type={"filter"}
      isSelected={hasFilterData}
    />
  )

  const treeLayoutButton = () => (
    <CustomIconButton
      title={"Estructura en forma de árbol"}
      onClick={handleeTreeLayout}
      type={"treeOrgChart"}
    />
  )

  const rightOffsetTreeLayoutButton = () => (
    <CustomIconButton
      title={"Estructura en forma de árbol con desplazamiento a la derecha"}
      onClick={handleRightOffsetTreeLayout}
      type={"orgchart"}
    />
  )


  if (showAnalytics && analyticsData && !isLoading) {
    return (
      <>
        <CommonPage
          title={"Análisis de conceptos por estructura"}
          subtitle={subtitle}
          menuItems={[goBackButton, rightOffsetTreeLayoutButton, treeLayoutButton, filterButton]}
          isNotGridPage
        >
          <div style={{ height: '100%' }}>
            <AnalyticsOrgChart
              data={analyticsData}
              setChartRef={setChartRef}
              nodes={nodesToUse}
              gridTheme={gridTheme.theme}
              filterData={filterData}
              setClickedNodeAndOpenDialog={setClickedNodeAndOpenDialog}
            />
          </div>
          <AnalyticsGridDialog
            open={analyticsGridDialogIsOpen}
            data={analyticsData}
            clickedNode={clickedNode}
            handleClose={handleCloseAnalyticsGridDialog}
            color={color}
            chart={chartRef.current}
            theme={gridTheme}
            dateFormat={"dd/mm/yyyy"}
            processName={process["Código de proceso"]}
          />
          {
            filtersDialogIsOpen &&
            <FiltersDialog
              open={filtersDialogIsOpen}
              data={filterData}
              setFilterData={setFilterData}
              handleClose={() => setFiltersDialogIsOpen(false)}
            />
          }
        </CommonPage>
      </>
    )
  }

  if (showDashboard) {
    return (
      <Dashboard
        title={`${getLocalizedString("employeesProcessesTitle")} - ${process["Descripción"]}`}
        closeDashboard={closeDashboard}
        data={dashboardData}
        desc={dashboardDesc}
        companyColor={color}
        gridTheme={gridTheme.theme}
      />
    )
  }

  return (
    <CommonPage
      columnDefPage={paths.employeesProcesses}
      title={`${getLocalizedString("employeesProcessesTitle")} - ${process["Descripción"]}`}
      gridTitle={getLocalizedString("currentProcessesTitle")}
      menuItems={[goBackItem, publishItem, showDashboardItem, analyticsConceptsPerStructureItem, showGenerateDocumentsItem, deleteItem]}
      rowData={data}
      isLoading={isExportinGrid || isLoading || isGeneratingDocuments}
      handleRowClick={handleRowClick}
      hasExpand
      hasHelp
      codeFieldName={'Código de empleado'}
    >
      {
        deleteDialogIsOpen && !isLoading && !isExportinGrid &&
        <ConfirmationDialog
          open={deleteDialogIsOpen}
          title={getLocalizedString("atention")}
          text={getLocalizedString("employeesProcessDeleteConfirm")}
          handleConfirm={handleDelete}
          handleClose={handleCloseDeleteDialog}
        />
      }
      {
        employeeDialogIsOpen && !isLoading && !isExportinGrid &&
        <SecondGridDialog
          open={employeeDialogIsOpen}
          process={process}
          employee={selectedEmployee}
          templates={templates}
          handleClose={handleCloseEmployeeDialog}
          handleOpenPickTemplateDialog={() => setPickTemplateDialogIsOpen(true)}
          handleGenerateDocumentsGivenTemplate={handleGenerateDocumentsGivenTemplate}
          isLoading={isGeneratingDocuments}
        />
      }
      {
        templates.length > 0 && pickTemplateDialogIsOpen && !isLoading && !isExportinGrid &&
        <PickTemplateDialog
          open={pickTemplateDialogIsOpen}
          templates={templates}
          handleConfirm={handleGenerateDocumentsGivenTemplate}
          handleClose={handleClosePickTemplateDialog}
        />
      }
    </CommonPage>
  );
}

export default EmployeesProcesses;
