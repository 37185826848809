import React from "react";
import ConceptGroupItem from "../widgets/conceptGroupItem";
import { Grid } from "@material-ui/core";

const ConceptGroups = ({ data, processName }) => {
    return (
        <Grid container justify="space-between" item xs={12} >
            <Grid container item xs={12}>
                {
                    data.map((el, index) => <ConceptGroupItem key={index} processName={processName} data={el} />)
                }
            </Grid>
        </Grid>
    )
}

export default ConceptGroups;
