import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEntryExitTypesAction } from "../actions";
import { getLoading, getData } from "../selectors";
import { getAppColor, getCountry } from "src/app/selectors";
import { Grid } from "@material-ui/core";
import DataItem from "./data/dataItem";
import { RESET_STATE } from "../actionTypes";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import paths from "@icarius-localization/paths";
import { useLocation } from 'react-router-dom'

export const TypeContext = React.createContext("");

const EntryExitTypes = ({ history }) => {

  const [createIsOpen, setCreateIsOpen] = useState(false);
  const location = useLocation();
  const country = useSelector(getCountry);

  const getTypeName = () => {
    return location.pathname === paths.entryTypes ? "ingreso" : "egreso";
  }

  const type = location.pathname === paths.entryTypes ? "I" : "E";

  const dispatch = useDispatch();

  const color = useSelector(getAppColor);
  const data = useSelector(getData);
  const isLoading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getEntryExitTypesAction(type));
    return () => { dispatch({ type: RESET_STATE }) }
  }, [dispatch, type])

  const getDataIsEmpty = () => {
    return !data || data.length === 0;
  }

  return (
    <TypeContext.Provider value={{ type, getTypeName }}>
      <ABMLayout
        title={`Definición de tipos de ${getTypeName()}`}
        isLoading={isLoading}
        showCreateButton={!createIsOpen}
        createButtonTitle={`Nuevo tipo de ${getTypeName()}`}
        createButtonOnClick={() => setCreateIsOpen(true)}
        showGoBack
        handleGoBack={() => history.push(paths.entryExitTypes)}
        showNoData={getDataIsEmpty()}
        noDataTitle={`No se encontraron tipos de ${getTypeName()}`}
        noDataCreateTitle={`Nuevo tipo de ${getTypeName()}`}
        noDataOnClick={() => setCreateIsOpen(true)}
      >
        <Grid container item xs={12} direction="row">
          {
            createIsOpen &&
            <Grid container item xs={12} style={{ margin: "10px 0px" }}>
              <DataItem
                country={country}
                color={color}
                data={null}
                closeNew={() => setCreateIsOpen(false)}
              />
            </Grid>
          }
          {
            data && data.map((item) => {
              return (
                <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
                  <DataItem country={country} data={item} color={color} />
                </Grid>
              )
            })
          }
        </Grid>
      </ABMLayout>
    </TypeContext.Provider>
  )
}

export default EntryExitTypes;