import React from "react";
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  Typography,
  InputLabel,
  Slider,
} from "@material-ui/core";
import StepContainer from "./stepContainer";

const gridStyle = { height: 75, padding: "0px 10px" };

const StepTwo = (props) => {

  const {
    formList,
    scoreTypeList,
    objectivePeriodList,
    formData,
    setFormValue,
    selectedForm,
  } = props;

  return (
    <StepContainer title={'Indique el tipo de formulario y sus ponderados'}>
      <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
        <FormControl style={{ width: "100%" }}>
          <InputLabel required id={'label-formCode'}>{'Código de formulario'}</InputLabel>
          <Select
            value={formData.formCode}
            labelId={'label-formCode'}
            id={'select-formCode'}
            onChange={(e) => setFormValue(e.target.value, "formCode")}
            margin={"none"}
          >
            {
              formList[formData.evaluationType]?.map(item => (
                <MenuItem
                  className={"whiteText"}
                  key={item.key}
                  value={item.key}>
                  {item.value}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
        <FormControl style={{ width: "100%" }}>
          <InputLabel required id={'label-performanceScoreType'}>{'Tipo de nota final desempeño'}</InputLabel>
          <Select
            value={formData.performanceScoreType}
            labelId={'label-performanceScoreType'}
            id={'select-performanceScoreType'}
            onChange={(e) => setFormValue(e.target.value, "performanceScoreType")}
            margin={"none"}
          >
            {
              scoreTypeList?.map(item => (
                <MenuItem
                  className={"whiteText"}
                  key={item.key}
                  value={item.key}>
                  {item.value}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
      {
        formData.evaluationType === 'P' &&
        <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel required id={'label-potentialScoreType'}>{'Tipo de nota final potencial'}</InputLabel>
            <Select
              value={formData.potentialScoreType}
              labelId={'label-potentialScoreType'}
              id={'select-potentialScoreType'}
              onChange={(e) => setFormValue(e.target.value, "potentialScoreType")}
              margin={"none"}
            >
              {
                scoreTypeList?.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
      }
      <Grid container item alignItems="center" xs={12} sm={6} style={gridStyle}>
        <FormControl style={{ width: "100%" }}>
          <InputLabel shrink={Boolean(!selectedForm?.reviewsObjectives || formData.objectivePeriod)} required id={'label-objectivePeriod'}>{'Período para objetivos'}</InputLabel>
          <Select
            disabled={!selectedForm?.reviewsObjectives}
            value={formData.objectivePeriod}
            labelId={'label-objectivePeriod'}
            id={'select-objectivePeriod'}
            onChange={(e) => setFormValue(e.target.value, "objectivePeriod")}
            margin={"none"}
            displayEmpty
          >
            {
              !selectedForm?.reviewsObjectives &&
              <MenuItem className={"whiteText"} value={''}>
                {'Ninguno'}
              </MenuItem>
            }
            {
              objectivePeriodList?.map(item => (
                <MenuItem
                  className={"whiteText"}
                  key={item.key}
                  value={item.key}>
                  {item.value}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Grid>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} style={{ ...gridStyle, height: '' }}>
          <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
            {'Ponderado evaluación descendente'}
          </Typography>
          <Slider
            onChange={(_, value) => setFormValue(value, 'descendantWeight')}
            min={0}
            max={100}
            step={1}
            defaultValue={0}
            value={parseInt(formData.descendantWeight)}
          />
          <Typography className="whiteText" variant="subtitle1" align="center" style={{ width: '100%' }}>
            {parseInt(formData.descendantWeight)}%
          </Typography>
        </Grid>
        {
          selectedForm?.type?.includes('ASC') &&
          <Grid container item alignItems="center" xs={12} sm={6} style={{ ...gridStyle, height: '' }}>
            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
              {'Ponderado evaluación ascendente'}
            </Typography>
            <Slider
              onChange={(_, value) => setFormValue(value, 'ascendantWeight')}
              min={0}
              max={100}
              step={1}
              defaultValue={0}
              value={parseInt(formData.ascendantWeight)}
            />
            <Typography className="whiteText" variant="subtitle1" align="center" style={{ width: '100%' }}>
              {parseInt(formData.ascendantWeight)}%
            </Typography>
          </Grid>
        }
        {
          selectedForm?.type?.includes('AUT') &&
          <Grid container item alignItems="center" xs={12} sm={6} style={{ ...gridStyle, height: '' }}>
            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
              {'Ponderado autoevaluación'}
            </Typography>
            <Slider
              onChange={(_, value) => setFormValue(value, 'selfEvaluationWeight')}
              min={0}
              max={100}
              step={1}
              defaultValue={0}
              value={parseInt(formData.selfEvaluationWeight)}
            />
            <Typography className="whiteText" variant="subtitle1" align="center" style={{ width: '100%' }}>
              {parseInt(formData.selfEvaluationWeight)}%
            </Typography>
          </Grid>
        }
        {
          selectedForm?.type?.includes('PAR') &&
          <Grid container item alignItems="center" xs={12} sm={6} style={{ ...gridStyle, height: '' }}>
            <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
              {'Ponderado evaluación de pares'}
            </Typography>
            <Slider
              onChange={(_, value) => setFormValue(value, 'pairWeight')}
              min={0}
              max={100}
              step={1}
              defaultValue={0}
              value={parseInt(formData.pairWeight)}
            />
            <Typography className="whiteText" variant="subtitle1" align="center" style={{ width: '100%' }}>
              {parseInt(formData.pairWeight)}%
            </Typography>
          </Grid>
        }
      </Grid>
    </StepContainer>
  );
}

export default StepTwo;