import React, { memo } from "react";
import { Typography } from "@material-ui/core";

const Description = ({ desc, companyColor }) => {
    return (
        <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: 30 }}>
                <Typography className="whiteText" variant="h6" style={{ fontWeight: 100, marginRight: 5, fontSize: '1.1rem' }}>
                    {"Descripción del proceso"}:
                </Typography>
                <Typography style={{ color: companyColor, fontWeight: 600, fontSize: '1.1rem' }} variant="h6">
                    {desc["Descripción del proceso"] || "-"}
                </Typography>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: 30 }}>
                <Typography className="whiteText" variant="h6" style={{ fontWeight: 100, marginRight: 5, fontSize: '1.1rem' }}>
                    {"Período del proceso"}:
                </Typography>
                <Typography style={{ color: companyColor, fontWeight: 600, fontSize: '1.1rem' }} variant="h6">
                    {desc["Período del proceso"] || "-"}
                </Typography>
            </div>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Typography className="whiteText" variant="h6" style={{ fontWeight: 100, marginRight: 5, fontSize: '1.1rem' }}>
                    {"Proceso publicado"}:
                </Typography>
                <Typography style={{ color: companyColor, fontWeight: 600, fontSize: '1.1rem' }} variant="h6">
                    {desc["Proceso publicado"] || "-"}
                </Typography>
            </div>
        </div>
    )
}

export default memo(Description);
