import { useState } from "react";
import { checkIfEmail } from "@icarius-utils/types";

const useHandleDocumentPublicationForm = (data, createCallback, modifyCallback, invalidDataCallback, presetSignableValue) => {

  const isCreate = !Boolean(data);

  const [code, setCode] = useState(isCreate ? "" : data.code);
  const [name, setName] = useState(isCreate ? "" : data.name);
  const [type, setType] = useState(isCreate ? "" : data.type);
  const [colaborator, setColaborator] = useState(isCreate ? false : data.colaborator);
  const [manager, setManager] = useState(isCreate ? false : data.manager);
  const [employer, setEmployer] = useState(isCreate ? false : data.employer);
  const [active, setActive] = useState(isCreate ? false : data.active);
  const [inFolder, setInFolder] = useState(isCreate ? false : data.inFolder);
  const [signable, setSignable] = useState(isCreate ? presetSignableValue : data.signable);
  const [multipleApprovation, setMultipleApprovation] = useState(isCreate ? false : data.multipleApprovation);
  const [requiresReception, setRequiresReception] = useState(isCreate ? false : data.requiresReception);
  const [docToReq, setDocToReq] = useState(isCreate ? false : data.docToReq);
  const [cc, setCC] = useState(isCreate ? "" : data.cc.replace(',', ';'));
  const [workflow, setWorkflow] = useState(isCreate ? "" : data.workflow);

  const ccIsValid = () => { //valida que cada cosa escrita entre, sea un mail
    if (!cc || cc === "") return false;
    return cc.split(";").every(email => checkIfEmail(email));
  }

  const stepOneIsValid = () => {
    if (!type) return false;
    if (!name) return false;

    return true;
  }

  const stepTwoIsValid = () => {
    if (!code && !(signable && type === 'EXT')) return false;

    //validaciones de solicitudes
    if (type === "WFD") {
      if (!ccIsValid()) return false;
      if (!workflow || workflow === "") return false;
    }

    return true;
  }

  const dataIsValid = () => {
    if (!colaborator && !manager && !employer) return false;

    return true;
  }

  const submit = () => {
    if (dataIsValid()) {
      let dataToSend = {
        code,
        name,
        type,
        active,
        inFolder,
        signable,
        requiresReception,
        docToReq,
        workflow,
        cc,
        colaborator,
        manager,
        employer,
        multipleApprovation,
      }

      if (!isCreate) {
        dataToSend.internalCode = data.internalCode;
      }

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    switch (fieldName) {
      case "code":
        setCode(value);
        break;
      case "name":
        setName(value);
        break;
      case "type":
        setType(value);

        // limpio valores dinamicos
        setCode('');
        setWorkflow('');
        setCC('');
        setColaborator(false);
        setManager(false);
        setEmployer(false);

        // asigno valores dinamicos
        if (signable) {
          if (value === 'REC') setColaborator(true);
          if (value === 'EXT') {
            setRequiresReception(true);
            setCode('')
          }
        } else {
          if (value === 'AUT') setRequiresReception(false);
          if (value === 'WFD') setRequiresReception(false);
        }

        if (value === 'AUT') setInFolder(true);
        if (value === 'ASI') setColaborator(true);

        setDocToReq(value === 'PED'); //solo es true si es type PED
        break;
      case "colaborator":
        if (type === 'REC' && !signable) {
          setManager(false);
          setEmployer(false);
        }
        setColaborator(value);
        break;
      case "manager":
        if (type === 'REC' && !signable) {
          setColaborator(false);
        }
        setManager(value);
        break;
      case "employer":
        if (type === 'REC' && !signable) {
          setColaborator(false);
        }
        setEmployer(value);
        break;
      case "active":
        setActive(value);
        break;
      case "inFolder":
        if (type !== 'AUT') setInFolder(value);
        break;
      case "signable":
        setSignable(value);
        setType('');
        break;
      case "requiresReception":
        setRequiresReception(value);
        if (!value) {
          setMultipleApprovation(false);
        }
        break;
      case "cc":
        setCC(value ? value.replace(/ /g, '') : "");
        break;
      case "workflow":
        setWorkflow(value);
        switch (value) {
          case "ANT":
          case "VAC":
          case "PER":
            setColaborator(true);
            setManager(false);
            setEmployer(false);
            break;
          case "SUE":
          case "DEV":
            setColaborator(false);
            setManager(true);
            setEmployer(false);
            break;
          default: break;
        }
        break;
      case "multipleApprovation":
        setMultipleApprovation(value);
        break;
      default: break;
    }
  }

  const formData = {
    name,
    type,
    code,
    colaborator,
    manager,
    employer,
    active,
    inFolder,
    signable,
    requiresReception,
    docToReq,
    cc,
    workflow,
    multipleApprovation,
  };

  return {
    isCreate,
    formData,
    setFormValue,
    submit,
    stepOneIsValid,
    stepTwoIsValid,
  };
}

export default useHandleDocumentPublicationForm;
