import React from "react";
import { useSelector } from "react-redux";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  ListSubheader,
  Typography
} from "@material-ui/core";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import useHandleVariableForm from "./useHandleVariableForm";
import { getFunctions, getTemplateListsVars } from "@icarius-pages/documentTemplates/selectors";
import { FileCopyIcon } from "@icarius-icons";

const gridStyle = { height: 75, padding: "0px 10px" };

const VariableForm = (props) => {

  const { typeSelected, typeString } = props;

  const functions = useSelector(getFunctions);
  const templateListsVars = useSelector(getTemplateListsVars);

  const {
    formData,
    resultado,
    setFormValue,
    setExtraValue,
    submit
  } = useHandleVariableForm(typeSelected);

  const getExtraFieldInputChild = (name, extraVariable, index) => {

    const value = formData[name][index].value;
    const onChange = (e) => setExtraValue(e.target.value, name, index);
    const isTypeText = extraVariable.key === '#T#';

    const Container = ({ children }) => (
      <Grid container item alignItems="center" xs={12} sm={6} md={isTypeText ? 8 : 4} style={gridStyle}>
        {children}
      </Grid>
    )

    if (extraVariable.key === '#N#') {
      return (
        <Container key={index}>
          <TextField
            fullWidth
            type="number"
            margin={"none"}
            label={'Número'}
            value={value}
            onChange={onChange}
          />
        </Container>
      )
    }

    if (extraVariable.key === '#T#') {
      return (
        <Container key={index}>
          <TextField
            fullWidth
            margin={"none"}
            label={'Texto'}
            value={value}
            onChange={onChange}
          />
        </Container>
      )
    }

    if (extraVariable.key === '#C#') {
      return (
        <Container key={index}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-Extra variable field select${index}`}>{'Concepto'}</InputLabel>
            <Select
              value={value}
              onChange={onChange}
              labelId={`label-Extra variable field select${index}`}
              id={`label-Extra variable field select${index}`}
              margin={"none"}
            >
              <MenuItem
                className={"whiteText"}
                key={''}
                value={''}>
                {''}
              </MenuItem>
              {
                templateListsVars['Options']['Conceptos'] && templateListsVars['Options']['Conceptos'].map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Container>
      )
    }

    if (extraVariable.key === '#OC#') {
      return (
        <Container key={index}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-Extra variable field select${index}`}>{'Otro concepto'}</InputLabel>
            <Select
              value={value}
              onChange={onChange}
              labelId={`label-Extra variable field select${index}`}
              id={`label-Extra variable field select${index}`}
              margin={"none"}
            >
              <MenuItem
                className={"whiteText"}
                key={''}
                value={''}>
                {''}
              </MenuItem>
              {
                templateListsVars['Options']['Otros Conceptos'] && templateListsVars['Options']['Otros Conceptos'].map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Container>
      )
    }

    if (extraVariable.key === '#CD#') {
      return (
        <Container key={index}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-Extra variable field select${index}`}>{'Complemento descriptivo'}</InputLabel>
            <Select
              value={value}
              onChange={onChange}
              labelId={`label-Extra variable field select${index}`}
              id={`label-Extra variable field select${index}`}
              margin={"none"}
            >
              <MenuItem
                className={"whiteText"}
                key={''}
                value={''}>
                {''}
              </MenuItem>
              {
                templateListsVars['Options']['Complementos Descriptivos'] && templateListsVars['Options']['Complementos Descriptivos'].map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Container>
      )
    }

    return null;
  }

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12}>
          <Typography className={"whiteText"} variant='h6' style={{ padding: "10px 10px 0px 10px" }}>
            {'Variables'}
          </Typography>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Variables precargadas */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-Variables precargadas`}>{'Variables precargadas'}</InputLabel>
            <Select
              value={formData['Variables precargadas']}
              onChange={(e) => setFormValue(e.target.value, "Variables precargadas")}
              labelId={`label-Variables precargadas`}
              id={`select-Variables precargadas`}
              margin={"none"}
            >
              <MenuItem
                className={"whiteText"}
                key={''}
                value={''}>
                {''}
              </MenuItem>
              {
                templateListsVars['COMMON']['Variables Precargadas'] && templateListsVars['COMMON']['Variables Precargadas'].map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Valores Generales */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-Valores generales`}>{'Valores generales'}</InputLabel>
            <Select
              value={formData['Valores generales']}
              onChange={(e) => setFormValue(e.target.value, "Valores generales")}
              labelId={`label-Valores generales`}
              id={`select-Valores generales`}
              margin={"none"}
            >
              <MenuItem
                className={"whiteText"}
                key={''}
                value={''}>
                {''}
              </MenuItem>
              {
                templateListsVars['COMMON']['Valores Generales'] && templateListsVars['COMMON']['Valores Generales'].map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Maestro de empleados */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-Maestro de Empleados`}>{'Maestro de Empleados'}</InputLabel>
            <Select
              value={formData['Maestro de empleados']}
              onChange={(e) => setFormValue(e.target.value, "Maestro de empleados")}
              labelId={`label-Maestro de Empleados`}
              id={`select-Maestro de Empleados`}
              margin={"none"}
            >
              <MenuItem
                className={"whiteText"}
                key={''}
                value={''}>
                {''}
              </MenuItem>
              {
                templateListsVars['COMMON']['Maestro de Empleados'] && templateListsVars['COMMON']['Maestro de Empleados'].map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>
        {
          // Variable segun type: Desplegable de variable segun el type seleccionado
          Boolean(typeSelected) && Boolean(Object.keys(templateListsVars[typeSelected]).length) &&
          <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel id={`label-${typeString}`}>{typeString}</InputLabel>
              <Select
                value={formData["Variable segun type"]}
                onChange={(e) => setFormValue(e.target.value, "Variable segun type")}
                labelId={`label-${typeString}`}
                id={`select-${typeString}`}
                margin={"none"}
              >
                <MenuItem
                  className={"whiteText"}
                  key={''}
                  value={''}>
                  {''}
                </MenuItem>
                {
                  Object.keys(templateListsVars[typeSelected]).map(varKey => {
                    const items = templateListsVars[typeSelected][varKey].map(item => (
                      <MenuItem
                        className={"whiteText"}
                        key={item.key}
                        value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))

                    if (Object.keys(templateListsVars[typeSelected]).length > 1) {
                      return [<ListSubheader disableSticky className="whiteText">{varKey}</ListSubheader>, items];
                    }
                    return items;
                  })
                }
              </Select>
            </FormControl>
          </Grid>
        }

        {/* extra variable field */}
        {
          Boolean(formData['Extra variable field'].length) &&
          <>
            {
              formData['Extra variable field'].map((extraVariable, index) => {
                return getExtraFieldInputChild('Extra variable field', extraVariable, index);
              })
            }
          </>
        }

        <Grid container item alignItems="center" xs={12}>
          <Typography className={"whiteText"} variant='h6' style={{ padding: "20px 10px 0px 10px" }}>
            {'Funciones'}
          </Typography>
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={4} style={gridStyle}> {/* Funcion */}
          <FormControl style={{ width: "100%" }}>
            <InputLabel id={`label-Funcion`}>{'Función'}</InputLabel>
            <Select
              value={formData['Funcion']}
              onChange={(e) => setFormValue(e.target.value, "Funcion")}
              labelId={`label-Funcion`}
              id={`select-Funcion`}
              margin={"none"}
            >
              <MenuItem
                className={"whiteText"}
                key={''}
                value={''}>
                {''}
              </MenuItem>
              {
                functions && functions.map(item => (
                  <MenuItem
                    className={"whiteText"}
                    key={item.key}
                    value={item.key}>
                    {item.value}
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Grid>

        {/* extra funcion: campo dinamico segun contenido de Funcion */}
        {
          Boolean(formData['Extra funcion'].length) &&
          <>
            {
              formData['Extra funcion'].map((extraVariable, index) => {
                return getExtraFieldInputChild('Extra funcion', extraVariable, index);
              })
            }
          </>
        }

        <Grid container item alignItems="center" xs={12} style={{ padding: "0px 10px" }}> {/* Resultado */}
          <Grid container item xs={12} sm={Boolean(navigator.clipboard) ? 11 : 12} style={{ paddingBottom: 10 }}>
            <TextField
              fullWidth
              margin={"none"}
              label={'Resultado'}
              value={resultado}
              onClick={(e) => e.target.select()}
            />
          </Grid>
          {
            Boolean(navigator?.clipboard) &&
            <Grid container item xs={12} sm={1} alignItems="center" style={{ paddingBottom: 20 }}>
              <ButtonDialogAction isAccept onClick={submit} text={'Copiar'} startIcon={<FileCopyIcon />} />
            </Grid>
          }
        </Grid>
      </Grid>
    </Grid>
  );
}

export default VariableForm;
