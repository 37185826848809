import React, { memo } from "react";
import DashboardChart from "../widgets";
import { Grid } from "@material-ui/core";

const Global = ({ data, companyColor, setGridDataAndOpenDialog, gridTheme }) => {
    return (
        <Grid container justify="space-between" item xs={12} >
            {
                data.map((item, i) => (
                    <Grid key={i} item xs={12}>
                        <DashboardChart path="currentProcessesDashboardGlobalValues" setGridDataAndOpenDialog={setGridDataAndOpenDialog} type={"GV"} gridTheme={gridTheme} data={item} customChartColor={companyColor} />
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default memo(Global);
