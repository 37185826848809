import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Radio,
    DialogActions,
    Checkbox,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import { CloseIcon } from "@icarius-icons";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import UserSelectionColumn from "@icarius-common/userSelectionDialog/components/userSelectionColumn";
import SelectedUsersColumn from "@icarius-common/userSelectionDialog/components/selectedUsersColumn";
import useHandleUserSelection from "@icarius-common/userSelectionDialog/useHandleUserSelection";
import DialogTransition from "@icarius-common/dialogTransition";
import { getEvaluatorsForEvaluationProcessDefinitionAssignmentAction } from "../actions";

const EVALUATION_TYPES = [
    {
        key: 'DES',
        name: 'Evaluador descendente (Jefe directo)',
        description: 'Se asignarán los jefes directos (Managers) en forma automática.',
    },
    {
        key: 'ASC',
        name: 'Evaluador ascendente',
        description: 'Colaboradores a cargo del evaluado (Cargos dependientes del 1er nivel de dependencia).',
    },
    {
        key: 'PAR',
        name: 'Evaluador par',
        description: 'Compañeros de trabajo (mismos grupos, misma gerencia, misma función o que compartan el mismo cargo).',
    },
    {
        key: 'FREE',
        name: 'Elección de evaluadores con selección libre',
        description: 'Podrás seleccionar libremente al evaluador.',
    },
];

const INITIAL_FILTERS = {
    matchPosition: false,
    matchFunction: false,
    matchManagment: false,
    matchGroups: false,
};

const EvaluatorDialog = (props) => {

    const {
        open,
        data,
        people,
        handleChange,
        handleClose,
        isReplace,
    } = props;

    const dispatch = useDispatch();

    const [step, setStep] = useState(0);
    const [typeSelected, setTypeSelected] = useState(!isReplace ? data.type : "FREE");
    const [filtersSelected, setFiltersSelected] = useState(INITIAL_FILTERS);

    const filteredPeople = useMemo(() => {
        if (typeSelected !== 'PAR') return people;
        return people.filter((item) => Object.keys(INITIAL_FILTERS).some((filter) => item[filter] && filtersSelected[filter]));
    }, [typeSelected, people, filtersSelected])

    const {
        keysSelected,
        checkedList,
        handleSelection,
        resetUsersSelected,
    } = useHandleUserSelection(filteredPeople, [], true);

    const handleSubmit = () => {
        if (!typeSelected) {
            dispatch(openSnackbarAction({ msg: 'Debe seleccionar una opción', severity: "error", duration: 10000 }));
            return;
        }

        if (step === 1 && typeSelected !== 'DES' && !keysSelected?.length) {
            dispatch(openSnackbarAction({ msg: 'Debe seleccionar una opción', severity: "error", duration: 10000 }));
            return;
        }

        if (step === 0) {
            // si es descendiente, mando vacio
            if (typeSelected === 'DES') {
                handleChange(keysSelected, typeSelected);
                handleClose();
                return;
            }

            dispatch(getEvaluatorsForEvaluationProcessDefinitionAssignmentAction({
                collaborators: data.collaborators,
                type: typeSelected === 'FREE' ? '' : typeSelected,
            }))
                .then((resp) => {
                    if (resp.status === 200) {
                        setStep(1);
                    }
                });
            return;
        }

        handleChange(keysSelected, typeSelected);
        handleClose();
    }

    const handleCancel = () => {
        if (step === 0) {
            handleClose();
            return;
        }

        setStep(0);
    }

    const handleChangeType = (option) => {
        if (option !== typeSelected) {
            setTypeSelected(option);
            resetUsersSelected();
            setFiltersSelected(INITIAL_FILTERS);
        }
    }

    const handleSetFilter = (filterKey) => {
        setFiltersSelected((prev) => {
            return (
                {
                    ...prev,
                    [filterKey]: !prev[filterKey],
                }
            )
        });
        resetUsersSelected();
    }

    return (
        <Dialog
            TransitionComponent={DialogTransition}
            open={open}
            PaperComponent={PaperDraggable}
            maxWidth={'md'}
            fullWidth
        >
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                {isReplace ? "Selección del nuevo evaluador" : `Selección de evaluador para el tipo "${data.typeString}" (${data.collaborators.length} colaboradores)`}
                <DialogTitleDivider />
            </DialogTitle>
            <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
            <DialogContent>
                <Typography className="whiteText" style={{ marginBottom: 20, fontSize: 18 }}>
                    {'Determine para los colaboradores seleccionados quien será su evaluador'}
                </Typography>
                {
                    step === 1 && typeSelected === 'PAR' &&
                    <div style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: 10 }}>
                        {
                            [
                                {
                                    key: 'matchPosition',
                                    name: 'Cargo'
                                },
                                {
                                    key: 'matchFunction',
                                    name: 'Función'
                                },
                                {
                                    key: 'matchManagment',
                                    name: 'Gerencia'
                                },
                                {
                                    key: 'matchGroups',
                                    name: 'Grupo de trabajo'
                                },
                            ].map((item) => {
                                return (
                                    <div
                                        key={item.key}
                                        style={{ display: "flex", alignItems: "center", cursor: "pointer", paddingRight: 20 }}
                                        onClick={() => handleSetFilter(item.key)}
                                    >
                                        <Typography className="whiteText" variant="subtitle1" style={{ display: "inline-block" }}>
                                            {item.name}
                                        </Typography>
                                        <Checkbox checked={filtersSelected[item.key]} />
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                <div style={{ maxHeight: 390, overflow: 'auto' }}>
                    {
                        step === 0 && EVALUATION_TYPES
                            .filter((item) => (!isReplace && (item.key === data.type || item.key === 'FREE')) || (isReplace && item.key === 'FREE'))
                            .map((item) => {
                                return (
                                    <div
                                        key={item.key}
                                        style={{
                                            cursor: 'pointer',
                                            display: 'flex',
                                            margin: '20px 0px'
                                        }}
                                        onClick={() => handleChangeType(item.key)}
                                    >
                                        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <Typography className="whiteText" style={{ fontSize: 20 }}>
                                                    {item.name}
                                                </Typography>
                                                <Typography className="whiteText">
                                                    {item.description}
                                                </Typography>
                                            </div>
                                        </div>
                                        <Radio checked={item.key === typeSelected} />
                                    </div>
                                )
                            })
                    }
                    {
                        step === 1 && ['ASC', 'PAR', 'FREE'].includes(typeSelected) &&
                        <div style={{ display: 'flex', gap: 10 }}>
                            {/* Columna 1 - seleccionados */}
                            <SelectedUsersColumn
                                showAmount
                                title={'Seleccionados'}
                                employees={checkedList}
                                handleClick={handleSelection}
                            />
                            {/* Columna 2 - todos los usuarios */}
                            <UserSelectionColumn
                                employees={checkedList}
                                handleClick={handleSelection}
                            />
                        </div>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <ButtonDialogAction onClick={handleCancel} text={step === 0 ? 'Cancelar' : 'Volver'} />
                <ButtonDialogAction onClick={handleSubmit} isAccept text={'Aceptar'} />
            </DialogActions>
        </Dialog>
    )
}

export default EvaluatorDialog;