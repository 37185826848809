import * as actionTypes from "./actionTypes";

const initialState = {
  rows: [],
  details: [],
  others: [],
  references: [],
  results: [],
  decimalSeparator: null,
  dateFormat: null,
  totalResult: null,
  haberesConAportes: null,
  descuentosLegales: null,
  haberesSinAportes: null,
  descuentosVarios: null,
  isLoading: false,
  isLoadingDashboard: false,//Va uno extra porque tarda una barbaridad
  dashboardData: null,
  dashboardDesc: null,
  currencyLocalization: "da",
  documents: null,
  isLoadingGeneratedDocuments: false,
  analyticsData: [],
  isLoadingAnalytics: false,
  filters: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CLEAR_STATE:
      return initialState;
    case actionTypes.RESET_STATE_DASHBOARD:
      return { ...state, dashboardData: null, dashboardDesc: null }
    case actionTypes.GET_ROWS:
      return { ...state, isLoading: true };
    case actionTypes.GET_ROWS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        rows: action.payload.rows,
      };
    case actionTypes.GET_ROWS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_DASHBOARD:
      return { ...state, isLoadingDashboard: action.payload.option === "quantity" };
    case actionTypes.GET_DASHBOARD_FULFILLED:
      return {
        ...state,
        isLoadingDashboard: false,
        dashboardData: {
          ...state.dashboardData,
          [`${action.payload.option}`]: action.payload.data.data
        },
        currencyLocalization: action.payload.currency_localization,
        dashboardDesc: action.payload.desc,
      };
    case actionTypes.GET_DASHBOARD_REJECTED:
      return { ...state, isLoadingDashboard: false };

    case actionTypes.GET_ANALYTICS:
      return { ...state, isLoadingAnalytics: true };
    case actionTypes.GET_ANALYTICS_FULFILLED:
      return {
        ...state,
        isLoadingAnalytics: false,
        analyticsData: action.payload.result,
        filters: action.payload.selects,
      };
    case actionTypes.GET_ANALYTICS_REJECTED:
      return { ...state, isLoadingAnalytics: false };

    case actionTypes.DELETE_COLAB_PROCESS:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_COLAB_PROCESS_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.DELETE_COLAB_PROCESS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_EXTRA_ROWS:
      return { ...state, isLoading: true };
    case actionTypes.GET_EXTRA_ROWS_FULFILLED:
      return {
        ...state,
        isLoading: false,
        details: action.payload.details,
        others: action.payload.others,
        references: action.payload.references,
        results: action.payload.results,
        decimalSeparator: action.payload.decimalSeparator,
        currencyLocalization: action.payload.currencyLocalization,
        dateFormat: action.payload.dateFormat,
        totalResult: action.payload.totalResult,
        haberesConAportes: action.payload.haberesConAportes,
        descuentosLegales: action.payload.descuentosLegales,
        haberesSinAportes: action.payload.haberesSinAportes,
        descuentosVarios: action.payload.descuentosVarios,
      };
    case actionTypes.GET_EXTRA_ROWS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CLEAR_EXTRA_ROWS:
      return {
        ...state,
        totalResult: null,
        haberesConAportes: null,
        descuentosLegales: null,
        haberesSinAportes: null,
        descuentosVarios: null,
        details: null,
        others: null,
        references: null,
        results: null,
        decimalSeparator: null,
        currencyLocalization: null,
        dateFormat: null,
      };

    case actionTypes.PUBLISH_PROCESS:
      return { ...state, isLoading: true };
    case actionTypes.PUBLISH_PROCESS_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.PUBLISH_PROCESS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DOWNLOAD_GENERATED_DOCUMENTS:
      return { ...state, isLoadingGeneratedDocuments: true };
    case actionTypes.DOWNLOAD_GENERATED_DOCUMENTS_FULFILLED:
      return { ...state, isLoadingGeneratedDocuments: false };
    case actionTypes.DOWNLOAD_GENERATED_DOCUMENTS_REJECTED:
      return { ...state, isLoadingGeneratedDocuments: false };

    default:
      return state;
  }
}
