import * as actionTypes from "./actionTypes";
import {
  getEvaluationProcessDefinitionAPI,
  createEvaluationProcessDefinitionAPI,
  updateEvaluationProcessDefinitionAPI,
  deleteEvaluationProcessDefinitionAPI,
  duplicateEvaluationProcessDefinitionAPI,
  getEvaluationProcessDefinitionCheckCollaboratorsAPI,
  sendMailEvaluationProcessDefinitionAPI,
} from "@icarius-connection/api";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import { getLocalizedErrorString, getLocalizedString } from "@icarius-localization/strings";

const errorHandler = (e, dispatch) => {
  let errorString = "";
  switch (e.response?.data?.status) {
    case "INVALID_DATA":
    case "VALIDATION_ERROR":
      errorString = getLocalizedString("validationError");
      break;
    case "DUPLICATED_DATA":
      errorString = "Ya existe un proceso con el mismo código o nombre";
      break;
    default:
      errorString = getErrorStringFromError(e);
      break;
  }
  dispatch(openSnackbarAction({ msg: errorString, severity: "error" }));
}

export const getEvaluationProcessDefinitionAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getEvaluationProcessDefinitionAPI();

    let data = response.data && response.data.data;
    let evaluationTypeList = response.data && response.data.evaluationTypeList;
    let formList = response.data && response.data.formList;
    let scoreTypeList = response.data && response.data.scoreTypeList;
    let objectivePeriodList = response.data && response.data.objectivePeriodList;
    let statusList = response.data && response.data.statusList;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        data,
        evaluationTypeList,
        formList,
        scoreTypeList,
        objectivePeriodList,
        statusList,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const createEvaluationProcessDefinitionAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createEvaluationProcessDefinitionAPI(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.CREATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Proceso de evaluación creado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const updateEvaluationProcessDefinitionAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY });
  try {
    let response = await updateEvaluationProcessDefinitionAPI(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.MODIFY_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.MODIFY_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Proceso de evaluación actualizado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.MODIFY_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const deleteEvaluationProcessDefinitionAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteEvaluationProcessDefinitionAPI(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DELETE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Proceso de evaluación eliminado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const duplicateEvaluationProcessDefinitionAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE });
  try {
    let response = await duplicateEvaluationProcessDefinitionAPI(dataToSend);
    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.DUPLICATE_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.DUPLICATE_FULFILLED,
      payload: { data },
    });

    dispatch(openSnackbarAction({ msg: 'Proceso de evaluación duplicado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.DUPLICATE_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};

export const getEvaluationProcessDefinitionCheckCollaboratorsAction = (processCode) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_CHECK_COLLABORATORS_DATA });
  try {
    let response = await getEvaluationProcessDefinitionCheckCollaboratorsAPI(`?processCode=${processCode}`);

    let data = response.data && response.data.data;
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.GET_CHECK_COLLABORATORS_DATA_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.GET_CHECK_COLLABORATORS_DATA_FULFILLED,
      payload: {
        data,
      },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_CHECK_COLLABORATORS_DATA_REJECTED, payload: e });
    errorHandler(e, dispatch);
    return e.response;
  }
};

export const sendMailEvaluationProcessDefinitionAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.SEND_MAIL });
  try {
    let response = await sendMailEvaluationProcessDefinitionAPI(dataToSend);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.SEND_MAIL_REJECTED });
      dispatch(openSnackbarAction({ msg: getLocalizedErrorString("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.SEND_MAIL_FULFILLED,
    });

    dispatch(openSnackbarAction({ msg: 'Mensaje enviado con éxito', severity: "success" }));
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.SEND_MAIL_REJECTED, payload: e });
    errorHandler(e, dispatch);
  }
};