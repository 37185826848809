import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import {
  CatalogsIcon,
  RelatedTablesDefinitionIcon,
  UserTableDefinitionIcon,
  SystemTableDefinitionIcon,
  UserMenusIcon,
  SpecialProcessDefinitionIcon,
} from "@icarius-icons/index";

const ExtensibilityScreenSelector = ({ history }) => {

  const data = [
    {
      name: "Catálogo de base de datos",
      icon: <CatalogsIcon />,
      path: paths.catalogs,
    },
    {
      name: "Definición de tablas relacionadas",
      icon: <RelatedTablesDefinitionIcon />,
      path: paths.relatedTablesDefinition,
    },
    {
      name: "Definición de procesos especiales",
      icon: <SpecialProcessDefinitionIcon />,
      path: paths.specialProcessDefinition,
      onClick: () => {
        history.push({
          pathname: paths.specialProcessDefinition,
          state: {
            from: paths.extensibilityScreenSelector,
          },
        });
      }
    },
    {
      name: "Editores de tablas de usuario",
      icon: <UserTableDefinitionIcon />,
      path: paths.userTablesDefinition,
    },
    {
      name: "Editores de tablas del sistema",
      icon: <SystemTableDefinitionIcon />,
      path: paths.systemTablesDefinition,
    },
    {
      name: "Definición de menús de usuario",
      icon: <UserMenusIcon />,
      path: paths.userMenus,
    },
  ];

  return (
    <ScreenSelector
      title={'Modelamiento'}
      data={data}
    />
  );
}

export default ExtensibilityScreenSelector;
