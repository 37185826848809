import React, { useState } from "react";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import { getLocalizedString } from "@icarius-localization/strings";
import CreateEditForm from "./createEditForm";
import useEvaluationProcessDefinition from "./useEvaluationProcessDefinition";
import DuplicateDialog from "./duplicateDialog/duplicateDialog";
import GridDialog from "./checkCollaboratorsDialog";
import StyledMenu from "@icarius-common/styledMenu";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import MailDialog from "./mailDialog";

const EvaluationProcessDefinition = () => {

  const {
    data,
    evaluationTypeList,
    formList,
    scoreTypeList,
    objectivePeriodList,
    statusList,
    isLoading,
    state,
    handlers,
  } = useEvaluationProcessDefinition();

  const [anchorEl, setAnchorEl] = useState(null);

  const checkCollaboratorsButton = (gridRef) => (
    <CustomIconButton
      title={'Consultar colaboradores que no participan en el proceso'}
      onClick={() => handlers.handleOpenCheckCollaboratorsDialog(gridRef)}
      type={"search"}
    />
  )

  const createButton = () => (
    <CustomIconButton
      title={getLocalizedString("create")}
      onClick={() => handlers.handleOpenCreateDialog()}
      type={"add"}
    />
  )

  const modifyButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("edit")}
      onClick={() => handlers.handleOpenEditDialog(gridRef)}
      type={"edit"}
    />
  )

  const deleteButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handlers.handleOpenDeleteDialog(gridRef)}
      type={"delete"}
    />
  )

  const duplicateButton = (gridRef) => (
    <CustomIconButton
      title={getLocalizedString("duplicate")}
      onClick={() => handlers.handleOpenDuplicateDialog(gridRef)}
      type={"duplicate"}
    />
  )

  const assignButton = (gridRef) => (
    <CustomIconButton
      title={'Asignar colaboradores al proceso'}
      onClick={() => handlers.handleOpenAssign(gridRef)}
      type={"addPerson"}
    />
  )

  const mailButton = (gridRef) => (
    <>
      <CustomIconButton
        title={'Enviar mensaje'}
        onClick={(e) => setAnchorEl(e.currentTarget)}
        type={"mail"}
      />
      <StyledMenu
        anchorEl={anchorEl}
        isOpen={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItemWithIcon
          text={'Inicio del proceso de calificación del desempeño'}
          onClick={() => {
            setAnchorEl(null);
            handlers.handleOpenMailDialog(gridRef, 'E');
          }}
        />
        <MenuItemWithIcon
          text={'Inicio del proceso de feedback'}
          onClick={() => {
            setAnchorEl(null);
            handlers.handleOpenMailDialog(gridRef, 'K');
          }}
        />
      </StyledMenu>
    </>
  )

  return (
    <>
      {
        state.formIsOpen &&
        <CreateEditForm
          isLoading={isLoading}
          data={state.selectedData}
          evaluationTypeList={evaluationTypeList}
          formList={formList}
          scoreTypeList={scoreTypeList}
          objectivePeriodList={objectivePeriodList}
          statusList={statusList}
          handleClose={handlers.handleCloseCreateEditDialog}
        />
      }
      {
        state.duplicateDialogIsOpen &&
        <DuplicateDialog
          open={state.duplicateDialogIsOpen}
          isLoading={isLoading}
          data={state.selectedData}
          evaluationTypeList={evaluationTypeList}
          handleClose={handlers.handleCloseDuplicateDialog}
        />
      }
      {
        state.mailDialogIsOpen &&
        <MailDialog
          open={state.mailDialogIsOpen}
          isLoading={isLoading}
          data={state.selectedData}
          handleClose={handlers.handleCloseMailDialog}
        />
      }
      {
        !state.formIsOpen &&
        <CommonPage
          isLoading={isLoading}
          title={'Definición de procesos de evaluación'}
          gridTitle={'Definición de procesos de evaluación'}
          columnDefPage={paths.evaluationProcessDefinition}
          rowData={data}
          menuItems={[
            createButton,
            modifyButton,
            deleteButton,
            duplicateButton,
            checkCollaboratorsButton,
            assignButton,
            mailButton,
          ]}
          hasExpand
          hasHelp
        >
          {
            state.checkCollaboratorsDialogIsOpen &&
            <GridDialog
              open={state.checkCollaboratorsDialogIsOpen}
              processName={state.selectedData?.processName}
              handleClose={handlers.handleCloseCheckCollaboratorsDialog}
            />
          }
        </CommonPage>
      }
    </>
  )
}

export default EvaluationProcessDefinition;