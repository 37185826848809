import React from "react";
import {
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import NumberFormatCustom from '@icarius-pages/myRequestsManager/components/modalRequests/numberFormatCustom';
import StepContainer from "./stepContainer";

const gridStyle = { height: 75, padding: "0px 10px" };

const StepThree = (props) => {

  const {
    selectedPerformanceScore,
    selectedPotentialScore,
    reviewsPotential,
    formData,
    setFormValue,
  } = props;

  const fieldsData = [
    {
      title: 'Para el resultado final de la evaluación del desempeño',
      subtitle: 'Eje X para 9-boxes',
      footer: `Notas finales posibles de la evaluación del desempeño: Desde ${selectedPerformanceScore.min} - Hasta ${selectedPerformanceScore.max}`,
      fields: [
        {
          title: '1 Desempeño pobre',
          fieldFrom: 'performanceFrom1',
          fieldTo: 'performanceTo1',
        },
        {
          title: '2 Desempeño bueno',
          fieldFrom: 'performanceFrom2',
          fieldTo: 'performanceTo2',
        },
        {
          title: '3 Desempeño sobresaliente',
          fieldFrom: 'performanceFrom3',
          fieldTo: 'performanceTo3',
        },
      ]
    },
    {
      title: 'Para el resultado final de la evaluación de potencial',
      subtitle: 'Eje Y para 9-boxes',
      footer: `Notas finales posibles de la evaluación del potencial: Desde ${selectedPotentialScore?.min} - Hasta ${selectedPotentialScore?.max}`,
      fields: [
        {
          title: '1 Potencial limitado',
          fieldFrom: 'potentialFrom1',
          fieldTo: 'potentialTo1',
        },
        {
          title: '2 Potencial medio',
          fieldFrom: 'potentialFrom2',
          fieldTo: 'potentialTo2',
        },
        {
          title: '3 Potencial alto',
          fieldFrom: 'potentialFrom3',
          fieldTo: 'potentialTo3',
        },
      ]
    }
  ];

  return (
    <StepContainer title={'Indique los rangos de la nota final que utilizará 9-Boxes'}>
      <Grid container item alignItems="flex-start" xs={12}>
        {
          fieldsData.map((section, sectionIndex) => {
            if (sectionIndex === 1 && !reviewsPotential) return null;

            return (
              <Grid container item alignItems="center" xs={12} sm={reviewsPotential ? 6 : 12} style={{ padding: "0px 10px" }} key={sectionIndex}>
                <Grid container item direction="column" justify="center" xs={12} style={{ ...gridStyle, padding: 0 }}>
                  <Typography className="whiteText" style={{ fontWeight: 600 }}>{section.title}</Typography>
                  <Typography className="whiteText">{section.subtitle}</Typography>
                </Grid>
                {
                  section.fields.map((item, index) => {
                    return (
                      <Grid
                        key={index}
                        container item alignItems="center" xs={12}
                        style={{ marginTop: 10, marginBottom: 10, padding: '10px 15px', background: 'var(--secondaryBackgroundColor)', borderRadius: 4 }}
                        className='myDesktopShadow'
                      >
                        <Grid container item alignItems="center" sm={12} md={6}>
                          <Typography className="whiteText">{item.title}</Typography>
                        </Grid>
                        <Grid container item alignItems="center" sm={12} md={6}>
                          <Grid container item alignItems="center" xs={6} style={{ paddingRight: 10 }}>
                            <TextField
                              required
                              label={'Desde'}
                              value={formData[item.fieldFrom]}
                              fullWidth
                              margin={"none"}
                              onChange={(e) => setFormValue(e.target.value, item.fieldFrom)}
                              InputProps={{ inputComponent: NumberFormatCustom, min: "0", step: "0.01" }}
                            />
                          </Grid>
                          <Grid container item alignItems="center" xs={6}>
                            <TextField
                              required
                              label={'Hasta'}
                              value={formData[item.fieldTo]}
                              fullWidth
                              margin={"none"}
                              onChange={(e) => setFormValue(e.target.value, item.fieldTo)}
                              InputProps={{ inputComponent: NumberFormatCustom, min: "0", step: "0.01" }}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    )
                  })
                }
                <Typography className="whiteText">{section.footer}</Typography>
              </Grid>
            )
          })
        }
      </Grid>
    </StepContainer>
  );
}

export default StepThree;