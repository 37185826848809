import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  data: [],
  evaluationTypeList: [],
  formList: [],
  scoreTypeList: [],
  objectivePeriodList: [],
  checkCollaboratorsData: [],
  statusList: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        evaluationTypeList: action.payload.evaluationTypeList,
        formList: action.payload.formList,
        scoreTypeList: action.payload.scoreTypeList,
        objectivePeriodList: action.payload.objectivePeriodList,
        statusList: action.payload.statusList,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.MODIFY:
      return { ...state, isLoading: true };
    case actionTypes.MODIFY_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.MODIFY_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DUPLICATE:
      return { ...state, isLoading: true };
    case actionTypes.DUPLICATE_FULFILLED:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    case actionTypes.DUPLICATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_CHECK_COLLABORATORS_DATA:
      return { ...state, isLoading: true };
    case actionTypes.GET_CHECK_COLLABORATORS_DATA_FULFILLED:
      return {
        ...state,
        isLoading: false,
        checkCollaboratorsData: action.payload.data,
      };
    case actionTypes.GET_CHECK_COLLABORATORS_DATA_REJECTED:
      return { ...state, isLoading: false };

      case actionTypes.SEND_MAIL:
      return { ...state, isLoading: true };
    case actionTypes.SEND_MAIL_FULFILLED:
      return { ...state, isLoading: false };
    case actionTypes.SEND_MAIL_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}
