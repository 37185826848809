import React, { memo, useRef } from "react";
import DashboardChart from "../widgets";
import { Grid, useMediaQuery } from "@material-ui/core";
import useVisible from "@icarius-utils/hooks/useIsVisible";

const Quantity = ({ setGridDataAndOpenDialog, data, gridTheme, userKPIs, userGraphics, userClouds }) => {

    const getGridSize = (type) => {
        switch (type) {
            case "GA":
                return 6;
            case "GV":
                return 12;
            case "GD":
                return 6;
            case "GP":
                return 6;
            case "BXP":
                return 2;
            case "BX":
                return 6;
            case "GN":
                return 4;
            case "GHD":
                return 6;
            case "BXX":
                return 2;
            case "GL":
                return 6;
            default: return 12;
        }
    }

    const calculatePadding = (matchesLessThan1280, index, accumulator, gridSize) => {
        if (matchesLessThan1280) {
            return "0px";
        }

        let padding = "";
        if (index === 0 && gridSize !== 12) {
            // Si el gridSize distinto de 12, tengo que dejar 15 por derecha
            padding = "0px 15px 0px 0px";
        } else {
            if (gridSize === 12) {
                padding = "0px";
            } else if (accumulator + gridSize > 12) {
                // Me fui a la proxima fila
                padding = "0px 15px 0px 0px";
            } else if (accumulator !== 0 && (gridSize === 2 || gridSize === 4)) {
                padding = "0px 15px 0px 15px";

            } else if (accumulator === 0) {
                padding = "0px 15px 0px 0px";

            } else {
                padding = "0px 0px 0px 15px";
            }
        }

        return padding;
    }

    let acumuladorGrid = 0;
    let acumuladorGraphics = 0;
    const matchesLessThan1280 = useMediaQuery(`(max-width:1280px)`);
    const elemRef = useRef();
    const isVisible = useVisible(elemRef);

    return (
        <Grid container justify="space-between" item xs={12} ref={elemRef}>
            <Grid container justify="space-between" item xs={12}>
                <Grid className="easyAccessItem" item lg={2} xs={12} style={matchesLessThan1280 ? { paddingTop: 20 } : { padding: "0px 15px 0px 0px" }}>
                    <DashboardChart
                        path="currentProcessesDashboardQuantity"
                        type={"BXP"}
                        data={data[0]}
                        gridTheme={gridTheme}
                        customChartColor={"#F5A506"}
                        setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                    />
                </Grid>
                <Grid className="easyAccessItem" item lg={2} xs={12} style={matchesLessThan1280 ? { paddingTop: 20 } : { padding: "0px 15px 0px 15px" }}>
                    <DashboardChart
                        path="currentProcessesDashboardQuantity"
                        type={"BXP"}
                        data={data[1]}
                        gridTheme={gridTheme}
                        customChartColor={"#00993D"}
                        setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                    />
                </Grid>
                <Grid className="easyAccessItem" item lg={2} xs={12} style={matchesLessThan1280 ? { paddingTop: 20 } : { padding: "0px 15px 0px 15px" }}>
                    <DashboardChart
                        path="currentProcessesDashboardQuantity"
                        type={"BXP"}
                        data={data[2]}
                        gridTheme={gridTheme}
                        customChartColor={"#FF4040"}
                        setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                    />
                </Grid>
                <Grid className="easyAccessItem" item lg={6} xs={12} style={matchesLessThan1280 ? { paddingTop: 20 } : { padding: "0px 0px 0px 15px" }}>
                    <DashboardChart
                        path="currentProcessesDashboardQuantityGHD"
                        type={"GHD"}
                        data={data[3]}
                        gridTheme={gridTheme}
                        setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                    />
                </Grid>
            </Grid>
            {
                userKPIs &&
                <Grid container justify="flex-start" item xs={12}>
                    {
                        userKPIs.map((kpi, index) => {
                            // Tengo que calcular el tamaño del grid
                            const gridSize = getGridSize(kpi.type === "BX" ? "BXX" : kpi.type);
                            const padding = calculatePadding(matchesLessThan1280, index, acumuladorGrid, gridSize);
                            acumuladorGrid += gridSize;

                            if (acumuladorGrid === 12) {
                                // Lo reseteo
                                acumuladorGrid = 0;
                            }

                            return (
                                <Grid className="easyAccessItem" key={`kpi-${index}`} item lg={gridSize} xs={12} style={{ padding: `${padding}`, marginTop: 20 }}>
                                    <DashboardChart
                                        path="currentProcessesDashboardUserKPIs"
                                        setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                                        type={kpi.type}
                                        data={kpi}
                                        gridTheme={gridTheme}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }
            {
                userGraphics &&
                <Grid container justify="space-between" item xs={12}>
                    {
                        userGraphics.map((graphic, index) => {
                            // Tengo que calcular el tamaño del grid
                            const gridSize = getGridSize(graphic.type);
                            const padding = calculatePadding(matchesLessThan1280, index, acumuladorGraphics, gridSize);
                            acumuladorGraphics += gridSize;

                            if (acumuladorGraphics === 12) {
                                // Lo reseteo
                                acumuladorGraphics = 0;
                            }

                            return (
                                <Grid key={`graphics-${index}`} item lg={gridSize} xs={12} style={{ padding: `${padding}`, marginTop: 20 }}>
                                    <DashboardChart
                                        path="currentProcessesDashboardUserGraphs"
                                        setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                                        type={graphic.type}
                                        data={graphic}
                                        gridTheme={gridTheme}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }
            {
                isVisible && userClouds &&
                <Grid container justify="space-between" item xs={12} >
                    {
                        userClouds.map((cloud, index) => {
                            return (
                                <Grid item key={`cloud-${index}`} xs={12} style={{ marginTop: 20 }}>
                                    <DashboardChart
                                        setGridDataAndOpenDialog={setGridDataAndOpenDialog}
                                        path="currentProcessesDashboardCloud"
                                        type={cloud.type}
                                        data={cloud}
                                        gridTheme={gridTheme}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            }
        </Grid>
    )
}

export default memo(Quantity);
